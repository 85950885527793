import React, { Component, Fragment,  } from 'react';
import _SystemsPopup  from './_SystemsPopup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck,faExclamationCircle, faExclamationTriangle, faEye, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { process } from "@progress/kendo-data-query";
import { searchSystems, getItems, updateItem, deleteItem, formatDate } from '../Services/SystemService';
import { Breadcrumb, Col } from 'react-bootstrap';
import Loader from '../Common/Loader';
import DataTable from 'react-data-table-component';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { isCurrentUserCustomer, isCurrentUserPROJECTADMIN, isCurrentUserSuperAdmin } from '../../common/roleManager';
import SystemView from './SystemView';
import { formatJSDate, getTodayInBillingFormat, isDateLessThanXDaysFromToday } from '../Services/DateService';
import { Row } from 'reactstrap';
import { GET } from '../Services/ApiServices/ApiInterceptor';
import { invalidateSystems } from '../Services/cacheService';





export class Systems extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showHide: false,
            skip: 0,
            take: 5,
            Systems: [], loading: true, showModal: false,
            updateData: {
                isUpdate: false,
                systemId: '',
                name: '',
                customerName: '',
                orderNumber: '',
                comments: '',
                orders: []
            },
            systemId: '',
            systemName: '',
            customerName: '',
            orderNumber: '',
            comments: '',
            sort: [
                {
                    field: "name",
                    dir: "asc",
                },
            ],
            availableProducts: [],
            systemStatus: []
        };
        this.populateSystemsData = this.populateSystemsData.bind(this);
        this._systemsViewRef = React.createRef();
        this._systemsPopRef = React.createRef();
    }

    showHideModal = (system) => {
        system.isEdit = true;
        this._systemsPopRef.current.handleModalShowHide();
        this._systemsPopRef.current.syncItemsWithProps(system);
    }
    showHideViewModal = (system) => {
        system.isEdit = false;
        this._systemsViewRef.current.handleViewModalShowHide();
        this._systemsViewRef.current.syncViewWithSystem(system);
    }
    pageChange = (event) => {
        this.setState({
            skip: event.page.skip,
            take: event.page.take,
        });
    }
    componentDidMount() {
        this.populateSystemsData();
    }
    createDataState = (dataState) => {
        return {
            result: process(this.Systems.slice(0), dataState),
            dataState: dataState,
        };
    };

    remove = async (row) => {
        if (window.confirm("Confirm deleting: " + row.name)) {
            await deleteItem(row);
            this.populateSystemsData();
        }        
    };
    update = (dataItem) => {
        dataItem.inEdit = false;
        const data = updateItem(dataItem);
        this.setState({
            Systems: data,
        });
    };

    discard = () => {
        const data = [...this.state.Systems];
        data.splice(0, 1);
        this.setState({
            Systems: data,
        });
    };
    cancel = (dataItem) => {
        const originalItem = getItems().find(
            (p) => p.systemId === dataItem.systemId
        );
        const data = this.state.Systems.map((item) =>
            item.systemId === originalItem.systemId ? originalItem : item
        );
        this.setState({
            Systems: data,
        });
    };
    enterView = (dataItem) => {
        this.showHideViewModal(dataItem);
        this.setState({
            updateData: dataItem,
        });
    }
    enterEdit = (row) => {
        row.isUpdate = true;
        //filter active data plans to be edited
        row.orders = row.orders.filter(x => x.isActive);
        row.isEdit = true;
        this.setState({
            updateData: row,
        });
        this.showHideModal(row);
    };

    itemChange = (event) => {
        const data = this.state.Systems.map((item) =>
            item.systemId === event.dataItem.systemId
                ? { ...item, [event.field || ""]: event.value }
                : item
        );
        this.setState({
            Systems: data,
        });
    };
    async handleSearch(value) {
        if (value.length > 3) {
            var data = await searchSystems(value);
            this.setState({
                Systems: data
            });
        }
        if (value.length == 0) {
            this.populateSystemsData();
        }
    }
    checkDataPlans(row) {
        const expiredPlans = row.orders.filter(x => x.isActive == true && formatJSDate(x.expDate) < formatJSDate(new Date()))?.length;
        let aboutToExpire = false;
        row.orders.forEach((x) => {
            if (x.isActive) {
                //Please remove hardcoded value and add to app settings
                if (isDateLessThanXDaysFromToday(x.expDate, 15)) {
                    aboutToExpire = true;
                }
            }
        });
        if (expiredPlans && expiredPlans > 0)
            return <><FontAwesomeIcon icon={faExclamationCircle} color="red" />&nbsp;&nbsp;Plan Expired</>
        if (aboutToExpire)
            return <><FontAwesomeIcon icon={faExclamationTriangle} color="orange" />&nbsp;&nbsp;Plan Expires Soon</>
        if (expiredPlans == 0)
            return <><FontAwesomeIcon icon={faCheck} color="green" />&nbsp;&nbsp;Plan Active</>
    }
    createDefs() {
        //define general columns
        var columns = [
            {
                name: 'System Name',
                selector: row => row.name,
                sortable: true,
                width: "180px",
                wrap: true,
            },
            {
                name: 'Customer Name',
                selector: row => row.customerName,
                sortable: true,
                width: "180px",
                wrap: true,
            },
            {
                name: 'Status',
                width: "155px",
                cell: (row) => {
                    return this.checkDataPlans(row)
                },
                sortable: true,
                center: false
            },
            {
                name: 'System ID',
                selector: row => row.systemId,
                sortable: true,
                width: "230px",
                wrap: true,
            },
            {
                name: 'Created By',
                selector: row => row.createdBy,
                sortable: true,
                width: "180px",
                warp: true,
            },
            {
                name: 'Date Created',
                selector: row => formatDate(row.createdDate),
                sortable: true,
                width: "130px",
            },
            {
                name: 'Modified By',
                selector: row =>row.modifiedBy,
                sortable: true,
                width: "130px",
                warp: true,
            },
            {
                name: 'Date Modified',
                selector: row => row.modifiedDate != null ? formatDate(row.modifiedDate) : '',
                sortable: true,
                width: "150px",
            },
            {
                name: "Actions",
                width: "100px",
                cell: (row) => {
                    return (<>
                        {
                            isCurrentUserSuperAdmin() || isCurrentUserPROJECTADMIN() ?
                                <>
                                    <button className="btn btn-sm btn-info" onClick={() => this.enterView(row)}>
                                        <FontAwesomeIcon icon={faEye} />
                                    </button> <span>&nbsp;</span>
                                    <button className="btn btn-sm btn-warning" onClick={() => this.enterEdit(row)}>
                                        <FontAwesomeIcon icon={faPen} />
                                    </button> <span>&nbsp;</span>
                                    <button className="btn btn-sm btn-danger" onClick={() => this.remove(row)}>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </button>
                                </> :
                                <>
                                    {isCurrentUserCustomer() ?
                                        <>
                                            <button className="btn btn-sm btn-info" onClick={() => this.enterView(row)}>
                                                <FontAwesomeIcon icon={faEye} />
                                            </button> <span>&nbsp;</span>
                                        </> :
                                        <>{ 
                                        row.isPushed ?
                                            <span>Item Already Pushed</span>
                                            :
                                            <>
                                                <button className="btn btn-sm btn-info" onClick={() => this.enterView(row)}>
                                                    <FontAwesomeIcon icon={faEye} />
                                                </button> <span>&nbsp;</span>
                                                <button className="btn btn-sm btn-warning" onClick={() => this.enterEdit(row)}>
                                                    <FontAwesomeIcon icon={faPen} />
                                                </button> <span>&nbsp;</span>
                                                <button className="btn btn-sm btn-danger" onClick={() => this.remove(row)}>
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </button>
                                                </>
                                        }</>
                                    }
                                </>
                        }
                    </>
            );
                }
            }
        ];
        //add role based defs to override general
        if (isCurrentUserCustomer()) {
            var columns = [
                {
                    name: 'System Name',
                    selector: row => row.name,
                    sortable: true,
                    width: "200px",
                    wrap: true,
                },
                {
                    name: 'Customer Name',
                    selector: row => row.customerName,
                    sortable: true,
                    width: "200px",
                    wrap: true,
                },
                {
                    name: 'Date Created',
                    selector: row => formatDate(row.createdDate),
                    sortable: true,
                    width: "150px",
                },
                {
                    name: 'Date Modified',
                    selector: row => row.modifiedDate != null ? formatDate(row.modifiedDate) : '',
                    sortable: true,
                    width: "150px",
                },
                {
                    name: "Actions",
                    width: "100px",
                    cell: (row) => {
                        return (<>
                            {
                                isCurrentUserSuperAdmin() ?
                                    <>
                                        <button className="btn btn-sm btn-info" onClick={() => this.enterView(row)}>
                                            <FontAwesomeIcon icon={faEye} />
                                        </button> <span>&nbsp;</span>
                                        <button className="btn btn-sm btn-warning" onClick={() => this.enterEdit(row)}>
                                            <FontAwesomeIcon icon={faPen} />
                                        </button> <span>&nbsp;</span>
                                        <button className="btn btn-sm btn-danger" onClick={() => this.remove(row)}>
                                            <FontAwesomeIcon icon={faTrash} />
                                        </button>
                                    </> :
                                    <>
                                        {isCurrentUserCustomer() ?
                                            <>
                                                <button className="btn btn-sm btn-info" onClick={() => this.enterView(row)}>
                                                    <FontAwesomeIcon icon={faEye} />
                                                </button> <span>&nbsp;</span>
                                            </> :
                                            <>{
                                                row.isPushed ?
                                                    <span>Item Already Pushed</span>
                                                    :
                                                    <>
                                                        <button className="btn btn-sm btn-info" onClick={() => this.enterView(row)}>
                                                            <FontAwesomeIcon icon={faEye} />
                                                        </button> <span>&nbsp;</span>
                                                        <button className="btn btn-sm btn-warning" onClick={() => this.enterEdit(row)}>
                                                            <FontAwesomeIcon icon={faPen} />
                                                        </button> <span>&nbsp;</span>
                                                        <button className="btn btn-sm btn-danger" onClick={() => this.remove(row)}>
                                                            <FontAwesomeIcon icon={faTrash} />
                                                        </button>
                                                    </>
                                            }</>
                                        }
                                    </>
                            }
                        </>
                        );
                    }
                }
            ];
        }
        return columns;
    }
    async refreshSystems() {
        this.setState({
            loading: true
        })
        await invalidateSystems();
        await this.populateSystemsData();
    }
    renderDevicesTable() {
        let Systems = this.state.Systems;
        if (false) {
            return (
                <table className='table table-striped' aria-labelledby="tabelLabel">
                    <thead>
                        <tr>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>No Systems Found</td>
                        </tr>
                    </tbody>
                </table>
            )
        } else {
            return (
                <>
                    <div style={{ width: "100%", left: "5%", position: "absolute" }}>
                        <_SystemsPopup populateSystemsData={this.populateSystemsData} updateData={this.state.updateData} ref={this._systemsPopRef} isEdit={true} />
                        <DataTable
                            columns={this.createDefs()}
                            data={this.state.Systems}
                            pagination
                        />
                    </div>
                    <SystemView ref={this._systemsViewRef} />
                </>
            );
        }
    }
    render() {
        let contents = this.state.loading
            ? <Loader />
            : this.renderDevicesTable(this.state.Systems, this.state.page);
        let searchBar = (
            <Fragment>
                <div className="col-12">
                    <div className="form-group">
                        <input type="text" className="form-control" onChange={e => this.handleSearch(e.target.value)} placeholder="Enter system name, order number, or system Id..." />
                    </div>
                </div>
                </Fragment>
            );
        return (
            <>
            <div>
                <Breadcrumb>
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Systems</Breadcrumb.Item>
                    </Breadcrumb>
                    <Row>
                        <Col md={1}>
                            <button className="btn btn-primary btn-lg " onClick={() => this.refreshSystems()}>
                                <FontAwesomeIcon icon={faSyncAlt} />
                            </button>
                        </Col>
                        <Col md={11}>
                            {searchBar}
                        </Col>
                    </Row>
                    {contents}
            </div>
        </>);
    }
    async populateSystemsData() {
        const data = await GET('api/Systems?email=' + localStorage.getItem("userEmail"))
        this.setState({
            Systems: data, loading: false, updateData: {
                isUpdate: false,
                systemId: '',
                name: '',
                customerName: '',
                orderNumber: '',
                comments: '',
                orders: []
            } });
    }
}
