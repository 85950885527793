import React from "react";
import { Fragment } from "react";
import { Button, Modal, Spinner, Table } from "react-bootstrap";
import { isCurrentUserCustomer } from "../../common/roleManager";
import { getDataPlans } from "../Services/DataPlanService";
import { spliceTimeFromCSharpDate } from "../Services/DateService";
import {
  getAllDevicesWithAllData,
  getAllProducts,
  getDeviceVariableWait,
  getParticleToken,
  populateParticleToken,
} from "../Services/DeviceServices";
import { formatDate } from "../Services/SystemService";
import authService from "./../api-authorization/AuthorizeService";

class SystemView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      systemId: "",
      systemName: "",
      customerName: "",
      orderNumber: "",
      comments: "",
      products: [],
      showHideView: false,
      createdBy: "",
      createdDate: "",
      modifiedBy: "",
      modifiedDate: "",
      systemDevices: [],
      systemOrders: [],
      dataPlans: [],
    };
    this.syncViewWithSystem = this.syncViewWithSystem.bind(this);
  }
  syncViewWithSystem(system) {
    this.setState({
      systemId: system.systemId,
      systemName: system.name,
      customerName: system.customerName,
      orderNumber: system.orderNumber,
      comments: system.comments,
      createdBy: system.createdBy,
      createdDate: system.createdDate,
      modifiedBy: system.modifiedBy,
      modifiedDate: system.modifiedDate == null ? "" : system.modifiedDate,
      systemDevices: [],
      products: system.systemProducts == null ? [] : system.systemProducts,
      systemOrders: system.orders ?? [],
    });
    this.loadDevices(system.name);
  }
  async loadDevices(systemName) {
    let devices = await getAllDevicesWithAllData();
    let dataPlans = await getDataPlans();
    let temp = [];
    for (var device of devices) {
      if (device.groups) {
        if (
          device.groups.find((x) => x.includes(systemName.toLowerCase())) !=
          undefined
        ) {
          temp.push(device);
        }
      }
    }
    this.setState({ systemDevices: temp, dataPlans });
  }
  handleViewModalShowHide() {
    this.state.showHideView = !this.state.showHideView;
  }
  render() {
    return (
      <Modal
        show={this.state.showHideView}
        fullscreen="yes"
        onHide={() => this.setState({ showHideView: false })}
      >
        <Modal.Header closeButton>
          <Modal.Title>System</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table
            striped
            bordered
            hover
            className="deviceViewTable"
            style={{ width: "30%" }}
          >
            <thead>
              <tr>
                <th>System ID</th>
                <td>{this.state.systemId}</td>
              </tr>
              <tr>
                <th>System Name</th>
                <td>{this.state.systemName}</td>
              </tr>
              <tr>
                <th>Customer Name</th>
                <td>{this.state.customerName}</td>
              </tr>
              <tr>
                <th>Notes/Comments</th>
                <td>{this.state.comments}</td>
              </tr>
              <tr>
                <th>Created By</th>
                <td>{this.state.createdBy}</td>
              </tr>
              <tr>
                <th>Created Date</th>
                <td>{formatDate(this.state.createdDate)}</td>
              </tr>
              <tr>
                <th>Modified By</th>
                <td>{this.state.modifiedBy}</td>
              </tr>
              <tr>
                <th>Modified Date</th>
                <td>{formatDate(this.state.modifiedDate)}</td>
              </tr>
            </thead>
          </Table>
          {this.state.products.length == 0 ? (
            <h5>Nothing to show</h5>
          ) : (
            <>
              <h5>Products</h5>
              <Table
                bordered
                className="deviceViewTable"
                style={{ width: "30%" }}
              >
                <thead>
                  <tr>
                    <th>Product Name</th>
                    <th>Product Slug Id</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.products.map((e, index) => (
                    <tr key={index}>
                      <td>{e.productName}</td>
                      <td>{e.apiProductId}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          )}
          <h5>Data Plans</h5>
          <Table
            triped
            bordered
            hover
            className="deviceViewTable"
            style={{ width: "70%" }}
          >
            <thead>
              <tr>
                <th>Name</th>
                <th>Order No.</th>
                <th>Qty</th>
                <th>Start Date</th>
                <th>Exp. Date</th>
                <th width="15%">Subscription Status</th>
                <th>Order History</th>
              </tr>
            </thead>
            {this.state.systemOrders.map((x, key) => (
              <tr key={key}>
                <td>
                  {x.dataPlan
                    ? x.dataPlan.name.name
                    : this.state.dataPlans.find(
                        (y) => y.dataPlanId == x.dataPlanId
                      )?.name}
                </td>
                <td>{x.orderNumber}</td>
                <td>{x.qty}</td>
                <td>{spliceTimeFromCSharpDate(x.startDate)}</td>
                <td>{spliceTimeFromCSharpDate(x.expDate)}</td>
                <td>
                  {new Date("" + x.expDate) > new Date(Date.now()) &&
                  x.isActive ? (
                    <>Active</>
                  ) : (
                    <>Expired</>
                  )}
                </td>
                <td>{x.isActive ? <>Current</> : <>Inactive</>}</td>
              </tr>
            ))}
          </Table>
          {isCurrentUserCustomer() && this.state.systemDevices.length == 0 ? (
            <h5>Nothing to show.</h5>
          ) : (
            <></>
          )}
          {isCurrentUserCustomer() && this.state.systemDevices.length > 0 ? (
            <>
              <h5>Devices</h5>
              <Table
                bordered
                className="deviceViewTable"
                style={{ width: "70%" }}
              >
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Online</th>
                    <th>Last Heard</th>
                    <th>Last IP</th>
                    <th>Serial Number</th>
                    <th>Sys</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.systemDevices.map((e, index) => (
                    <Fragment key={index}>
                      <tr>
                        <td>{e.name}</td>
                        <td>{e.online ? "Yes" : "No"}</td>
                        <td>{e.last_heard}</td>
                        <td>{e.last_ip_address}</td>
                        <td>{e.serial_number}</td>
                        <td>{e.system_firmware_version}</td>
                      </tr>
                    </Fragment>
                  ))}
                </tbody>
              </Table>
            </>
          ) : (
            <></>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => this.setState({ showHideView: false })}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
export default SystemView;
