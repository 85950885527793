import React, { Component, Fragment } from 'react';
import { Modal, Button, Card, Col, Container, InputGroup, Row, Table, Alert, Accordion, AccordionCollapse } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';
import { Label } from 'reactstrap';
import Loader from '../Common/Loader';
import { createUpdateUser, getRoles, getUserSystemGroups } from '../Services/userServices';
import CountryCodes from './../Common/CountryCodes'

export class UserCreateEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            id: 0,
            email: '',
            userName: '',
            role: '',
            validated: false,
            show: false,
            isEdit: this.props.isEdit,
            eventKey: false,
            userGroups: [],
            selectedGroups: [],
            countryCode: '',
            phone: '',
        }
    }
    syncItemsWithParent(userToEdit, userRole, isEdit) {
        if(isEdit)
            var tempSelectedGroups = userToEdit.groups.map(x => x.name);
        else
            var tempSelectedGroups = []
        this.setState({ loading: true });
        this.setState({
            id: userToEdit.id,
            email: userToEdit.email,
            userName: userToEdit.userName,
            role: userRole,
            isEdit: isEdit,
            selectedGroups: tempSelectedGroups,
            countryCode: userToEdit.countryCode,
            phone: userToEdit.phoneNumber,
        })
        this.setState({loading: false})
    }
    handleSubmit = (event) => {
        this.setState({ validated: true });
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if (form.checkValidity()) {
            if (this.state.isEdit) {
                this.editUser()
            }
            else {
                this.createUser()
            }
        }
    };
    constructUser() {
        let usrObject = {
            id: !this.state.isEdit ? 0 : this.state.id,
            email: this.state.email,
            userName: this.state.userName,
            role: this.state.role,
            groups: this.state.selectedGroups,
            countryCode: this.state.countryCode,
            phone: this.state.phone
        }
        return usrObject;
    }
    async editUser() {
        let user = this.constructUser();
        let result = await createUpdateUser(user, 'PUT');
        if (result.success) {
            toast.success("User updated successfully")
            this.handleClose();
            this.props.getUsersData();
        }
        else {
            toast.error("Something went wrong.");
        }
    }
    async createUser() {
        let user = this.constructUser();
        let result = await createUpdateUser(user, 'POST');
        if (result.success) {
            toast.success("User created successfully")
            this.handleClose();
            this.props.getUsersData();
        }
        else {
            toast.error("Something went wrong.");
        }
    }
    componentDidMount() {
        this.getData();
    }
    async getData() {
        let roles = await getRoles();
        let groups = await getUserSystemGroups();
        if (roles.success) {
            this.setState({ roles: roles.data })
        }
        if (groups.success) {
            this.setState({ groups: groups.data })
        }
        this.setState({loading: false})
    }
    handleClose = () => this.setState({ show: false });
    handleShow = () => this.setState({ show: true })
    addRemoveUserGroup(value) {
        if (value == '') {
            this.setState({ selectedGroups: []})
            return;
        }
        if (this.state.selectedGroups.indexOf(value) > -1) {
            this.setState({ selectedGroups: this.state.selectedGroups.filter(x => x != value) })
        }
        else {
            this.state.selectedGroups.push(value)
        }
    }
    render() {
        return (<>
            <Modal show={this.state.show} onHide={this.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{ this.props.isEdit ? "Edit User" : "Create User"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {

                        this.state.loading ? <Loader /> :
                            <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="Email"
                                            value={this.state.email}
                                            onChange={(e) => this.setState({ email: e.target.value, userName: e.target.value })}
                                        />
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="12" controlId="validationCustom02">
                                        <Form.Label>Username</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="Username"
                                            value={this.state.userName}
                                            onChange={(e) => this.setState({ userName: e.target.value })}
                                            disabled
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCustom02">
                                        <div className="form-group">
                                            <label>Country Code</label>
                                            <select value={this.state.countryCode} className="form-control"
                                                onChange={(e) => this.setState({ countryCode: e.target.value })}
                                                >
                                                <option value="">Select Country Code</option>
                                                {
                                                    CountryCodes.map((i) => {
                                                        return <option key={i.name}
                                                            name={i.code}
                                                            value={i.dial_code}>
                                                            {i.code + " " + i.dial_code}
                                                        </option>;
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </Form.Group>
                                    <Form.Group as={Col} md="8" controlId="validationCustom01">
                                        <Form.Label>Phone Number</Form.Label>
                                        <Form.Control                                            
                                            type="text"
                                            placeholder="Phone number"
                                            value={this.state.phone}
                                            onChange={(e) => this.setState({ phone: e.target.value })}
                                        />
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                    <Row>
                                        <Form.Group as={Col} md="10" controlId="validationCustom02">
                                            <div className="form-group">
                                                <label>User Role</label>
                                                <select value={this.state.role} className="form-control"
                                                    onChange={(e) => this.setState({ role: e.target.value })}
                                                    value={this.state.role}
                                                    required>
                                                    <option value="">Select User Role</option>
                                                {
                                                    this.state.roles.map((i) => {
                                                        return <option key={i.name}
                                                            name={i.name}
                                                            value={i.name}>
                                                            {i.name.toLowerCase() === 'operator' ? 'VIEWER' : i.name}
                                                        </option>;
                                                    })
                                                }
                                                </select>
                                            </div>
                                        </Form.Group>
                                        <Form.Group as={Col} md="2">
                                            <div className='form-group'>
                                                <Label>&nbsp;</Label>
                                                <Button variant='secondary' className="form-control" onClick={() => { this.setState({ eventKey: !this.state.eventKey }) }}>Roles?</Button>
                                            </div>
                                        </Form.Group>
                                </Row>
                                {this.state.role == "CUSTOMER" ?
                                    <Row>
                                        <Form.Group as={Col} md="10" controlId="validationCustom02">
                                            <div className="form-group">
                                                <label>User Groups</label>
                                                <select multiple className="form-control"
                                                    onChange={(e) => this.addRemoveUserGroup(e.target.value)}
                                                    value={this.state.selectedGroups}
                                                    >
                                                    <option value="">Clear User Group</option>
                                                    {
                                                        this.state.groups.map((i, index) => {
                                                            return <option key={index}
                                                                name={i.name}
                                                                value={i.name}>
                                                                {i.name}
                                                            </option>;
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </Form.Group>
                                    </Row>
                                    : <></>}
                                {this.props.isEdit ?
                                    <Button variant="primary" type="submit">
                                        Update User
                                    </Button>
                                    : <Button variant="primary" type="submit">
                                        Create User
                                    </Button>}
                            </Form>
                    }
                    <Accordion defaultActiveKey={true} className='m-1'>
                        <AccordionCollapse eventKey={this.state.eventKey }>
                            <Alert variant='secondary'>
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>Role</th>
                                                <th>System Manager</th>
                                                <th>Sync Manager</th>
                                                <th>Device Manager</th>
                                                <th>Data Plans</th>
                                                <th>Billing Manager</th>
                                                <th>User Manager</th>
                                            </tr>
                                        </thead>
                                    <tbody>
                                        <tr>
                                            <td>Super Admin</td>
                                            <td>Create, View, Edit, Delete (after push)</td>
                                            <td>Push, Pull, View, Delete Groups</td>
                                            <td>View, Edit</td>
                                            <td>Create, View, Edit, Delete</td>
                                            <td>Dashboard, System Use Details, Settings (View, Update)</td>
                                            <td>Create, View, Edit, Delete (Can create Super Admin)</td>
                                        </tr>
                                        <tr>
                                            <td>Admin</td>
                                            <td>Create, View, Edit, Delete (before push)</td>
                                            <td>Push, Pull, View, Delete Groups</td>
                                            <td>View, Edit</td>
                                            <td>Create, View, Edit, Delete</td>
                                            <td>Dashboard, System Use Details, Settings (View, Update)</td>
                                            <td>Create, View, Edit, Delete</td>
                                        </tr>
                                        <tr>
                                            <td>Project Admin</td>
                                            <td>Create, View, Edit, Delete (before push)</td>
                                            <td>Push, Pull, View</td>
                                            <td>No Access</td>
                                            <td>No Access</td>
                                            <td>Dashboard, System Use Details</td>
                                            <td>Create, View, Edit, Delete (Users less than Admin/SuperAdmin)</td>
                                        </tr>
                                        <tr>
                                            <td>Viewer</td>
                                            <td>Create, View, Edit, Delete (before push)</td>
                                            <td>View</td>
                                            <td>View</td>
                                            <td>No access</td>
                                            <td>System Use Details</td>
                                            <td>No Access</td>
                                        </tr>
                                        <tr>
                                            <td>Device Admin</td>
                                            <td>View</td>
                                            <td>View</td>
                                            <td>View, Edit</td>
                                            <td>No Access</td>
                                            <td>No Access</td>
                                            <td>No Access</td>
                                        </tr>
                                        <tr>
                                            <td>Customer</td>
                                            <td>View</td>
                                            <td>View</td>
                                            <td>No Access</td>
                                            <td>No Access</td>
                                            <td>System Use Details</td>
                                            <td>No Access</td>
                                        </tr>
                                        </tbody>
                                </Table>
                            </Alert>
                        </AccordionCollapse>
                    </Accordion>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>)
    }
}