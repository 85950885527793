import React, { Component } from 'react';
import { DeviceDataTable } from './DeviceDataTable';
export class DeviceList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            devices: this.props.devices,
            loadDevices: this.props.loadDevices
        }
    }

    render() {
        return (<>
            <DeviceDataTable devices={this.state.devices} products={this.props.products} loadData={this.props.loadData}/>
        </>)
    }
}