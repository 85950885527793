export const isCurrentUserAdmin = () => {
    return localStorage.getItem("userRole") === "ADMIN"
}
export const isCurrentUserViewer = () => {
    return localStorage.getItem("userRole") === "OPERATOR"
}
export const isCurrentUserSuperAdmin = () => {
    return localStorage.getItem("userRole") === "SUPERADMIN"
}
export const isCurrentUserCustomer = () => {
    return localStorage.getItem("userRole") === "CUSTOMER"
}
export const isCurrentUserPROJECTADMIN = () => {
    return localStorage.getItem("userRole") === "PROJECTADMIN"
}
export const isCurrentUserDEVICEADMIN = () => {
    return localStorage.getItem("userRole") === "DEVICEADMIN"
}

export const hasLocalStoragePopulated = () => {
    if (localStorage.getItem("userRole") == null) {
        return false;
    }
    return true;
}