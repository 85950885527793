import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService';
import { GET } from './Services/ApiServices/ApiInterceptor';

export class RegisteredDevices extends Component {
    constructor(props) {
        super(props);
        this.state = { registeredDevices: [], loading: true };
    }
    componentDidMount() {
        this.populateRegisteredDevicesData();
    }
    static renderDevicesTable(registeredDevices) {
        if (registeredDevices.length < 1) {
            return(
            <table className='table table-striped' aria-labelledby="tabelLabel">
                <thead>
                    <tr>
                        <th>Device Name</th>
                        <th>RTU</th>
                        <th>Comments</th>
                        <th>Address</th>
                    </tr>
                </thead>
                <tbody>
                        <tr>
                            <td></td>
                            <td>No Registered Devices Found</td>
                            <td></td>
                            <td></td>
                        </tr>
                </tbody>
            </table>
            )
        } else {
            return (
                <table className='table table-striped' aria-labelledby="tabelLabel">
                    <thead>
                        <tr>
                            <th>Device Name</th>
                            <th>RTU</th>
                            <th>Comments</th>
                            <th>Address</th>
                        </tr>
                    </thead>
                    <tbody>
                        {registeredDevices.map(forecast =>
                            <tr key={forecast.registeredDeviceId}>
                                <td>{forecast.name}</td>
                                <td>{forecast.rtu}</td>
                                <td>{forecast.comments}</td>
                                <td>{forecast.address}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            );
        }
    }
    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : RegisteredDevices.renderDevicesTable(this.state.registeredDevices);

        return (
            <div>
                <h1 id="tabelLabel" className="mb-3">Registered Devices</h1>
                <p><button className="btn btn-primary mt-3">Add Device</button></p>
                {contents}
            </div>
        );
    }
    async populateRegisteredDevicesData() {
        const data = await GET('api/RegisteredDevices?email=' + localStorage.getItem("userEmail"));
        this.setState({ registeredDevices: data, loading: false });
    }
}
