import { faCheck, faCheckCircle, faExclamation, faExclamationCircle, faExclamationTriangle, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tab } from 'bootstrap';
import React, { Component } from 'react';
import { Form, Col, Row, Modal, Button } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import { Label } from 'reactstrap';
import { UsageCard } from '../../common/UsageCard';
import Loader from '../Common/Loader';
import { getAllBillingPeriods, getAllUsage, getFilterBillingDetails, getFilterBillingDetailsByPeriod, getSettingsThreshold, getSingleSystemBillingDetails, getSingleSystemBillingDetailsByPeriod } from '../Services/BillingService';

export class BillingTab extends Component {

    constructor(props) {
        super(props);
        this.state = {
            systems: [],
            systemBillingData: [],
            loading: true,
            startDate: null,
            endDate: null,
            systemId: null,
            show: false,
            selectedSystem: null,
            selectedPeriod: '',
            billingPeriodList: [],
            threshold: ''
        };
        this.inputRef = React.createRef();
    }
    showHide() {
        this.setState({
            show: !this.state.show
        })
    }
    enterView(row) {
        this.setState({
            selectedSystem: row,
        })
        this.showHide();
    }
    loadData = async () => {
        let threshold = await getSettingsThreshold();
        let systems = await getAllUsage();
        let billingPeriodsList = await getAllBillingPeriods();
        this.setState({
            systems: systems,
            billingPeriodList: billingPeriodsList,
            loading: false,
            threshold: threshold
        });
    }
    componentDidMount() {
        this.loadData();
    }
    clearSearch() {
        this.loadData();
    }
    onlineSort(rowA, rowB) {
        let a = rowA.currentDataOpsUsagePct > 1 || rowA.currentNumberDevices > rowA.plannedNumberDevices;
        let b = rowB.currentDataOpsUsagePct > 1 || rowB.currentNumberDevices > rowB.plannedNumberDevices;
        return b - a;
    }
    deviceUseSort(rowA, rowB) {
        let a = rowA.currentNumberDevices
        let b = rowB.currentNumberDevices
        return b - a;
    }
    dataUseSort(rowA, rowB) {
        let a = rowA.currentDataOpsUsage
        let b = rowB.currentDataOpsUsage
        return b - a;
    }
    createSysDefs() {
        //define general columns
        var columns = [
            {
                name: 'System Name',
                selector: row => row.name,
                sortable: true,
                wrap: true,
                width: "200px",
            },
            {
                name: 'System ID',
                selector: row => row.systemId,
                sortable: true,
                wrap: true,
                width: "300px",
            },
            {
                name: 'Status',
                center: true,
                sortable: true,
                sortFunction: this.onlineSort,
                width: "110px",
                selector: (row) => {
                    if (row.currentDataOpsUsagePct > (this.state.threshold / 100) && row.currentDataOpsUsagePct <= 1)
                        return <FontAwesomeIcon icon={faExclamationTriangle} color="orange" />
                    if (row.currentNumberDevices == row.plannedNumberDevices)
                        return <FontAwesomeIcon icon={faCheckCircle} color="green" />
                    if (row.currentDataOpsUsagePct > 1 || row.currentNumberDevices > row.plannedNumberDevices)
                        return <FontAwesomeIcon icon={faExclamationCircle} color="red" />
                    return <FontAwesomeIcon icon={faCheckCircle} color="green" />
                },
            },
            {
                name: 'Device Use',
                selector: row => { return (<>{row.currentNumberDevices} <b>{' of '}</b> {row.plannedNumberDevices}</>) },
                sortable: true,
                sortFunction: this.deviceUseSort,
                wrap: true,
                width: "110px",
            },
            {
                name: 'Data Use',
                selector: row => { return (<>{row.currentDataOpsUsage} <b>{' of '}</b>  {row.plannedDataOpsUsage}</>) },
                sortable: true,
                sortFunction: this.dataUseSort,
                wrap: true,
                width: "115px",
            },
            {
                name: 'Pct Data Use',
                selector: row => Math.round((row.currentDataOpsUsagePct * 100), 2).toFixed(0) + '%',
                sortable: true,
                wrap: true,
            },
            {
                name: "Details",
                width: "110px",
                selector: (row) => {
                    return <button className="btn btn-sm btn-info" onClick={() => this.enterView(row)}>
                        <FontAwesomeIcon icon={faEye} />
                    </button>
                }
            }
        ]
        return columns;
    }
    async getBillingInfo() {
        if (this.state.selectedPeriod == '') {
            toast.error('Please select a billing period!')
            return;
        }
        let temp = [];
        if (this.state.systemId && this.state.systemId != '') {
            let data = await getSingleSystemBillingDetailsByPeriod(this.state.systemId, this.state.selectedPeriod);
            temp.push(data);
        }
        else {
            let data = await getFilterBillingDetailsByPeriod(this.state.selectedPeriod);
            temp = data;
        }
        this.setState({ systems: temp })
    }
     render() {
        return <>
            {
                this.state.loading ? <Loader /> :
                    <>
                        <Row>
                            <Col>
                        <Form.Group>
                            <Label>Please Select System</Label>
                            <Form.Control
                                as="select"
                                onChange={(e) => { this.setState({ systemId : e.target.value }) }}
                                required>
                                <option value="">Please Select System</option>
                                <option value="">All Systems</option>
                                {this.state.systems.map((system, index) => {
                                    return <option value={system.systemId} key={index}>{system.name}</option>
                                })}
                            </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col md={2}><label>Select Billing Period</label>
                                <select onChange={(e) => {
                                this.setState({
                                    selectedPeriod: e.target.value
                                })
                            }} className="form-control">
                                <option value="">Select Period</option>

                                {this.state.billingPeriodList.map((val, index) => {
                                    return <option value={val} key={index}>{val}</option> 
                                })
                                }
                            </select></Col>
                            <Col>
                                <label>&nbsp;&nbsp;</label><br/>
                                <button className="btn btn-primary" onClick={() => this.getBillingInfo()}>Search</button>&nbsp;&nbsp;
                                <button className="btn btn-secondary" onClick={() => this.clearSearch()}>Clear</button>
                            </Col>
                        </Row>

                        <DataTable
                            columns={this.createSysDefs()}
                            data={this.state.systems}
                            pagination
                        />
                        <Modal show={this.state.show} onHide={() => this.showHide()}>
                            <Modal.Header closeButton>
                                <Modal.Title>Usage Details</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="row">
                                    <div className="offset-3 col-md-6">
                                        <UsageCard item={this.state.selectedSystem} />
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => this.showHide()}>
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Modal>

                </>
            }
        </>
    }
}