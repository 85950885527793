import React, { Component, Fragment } from 'react';
import DataTable from 'react-data-table-component';
import { Card, Col, Row } from 'reactstrap';
import { faEye, faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from '../Common/Loader';
import { getOrders } from '../Services/OrderService';
import { Form, Modal, Button } from 'react-bootstrap';
import { getAllProducts } from '../Services/DeviceServices';
import { getDataPlans } from '../Services/DataPlanService';
export class OrdersTab extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            showView: false,
            showEditCreate: false,
            isEdit: false,
            orders: [],
            validated: false,
            orderNumber: '',
            systemId: props.systemId == undefined ? '' : props.systemId,
            dataPlans: props.dataPlans == undefined ? [] : props.dataPlans,
            products: props.systemProducts == undefined ? [] : props.systemProducts,
            availableDataPlans: [],
            availableProducts: [],
        };
    }
    componentDidMount() {
        this.loadData();
    }
    async loadData() {
        let orders = await getOrders();
        let products = await getAllProducts();
        let dataPlans = await getDataPlans();
        dataPlans.forEach(item => item.qty = 0);
        this.setState({
            orders: orders,
            availableDataPlans: dataPlans,
            availableProducts: products.products,
            isLoading: false
        })
    }
    filterItems(str) {

    }
    enterCreate() {
        this.setState({ showEditCreate: true})
    }
    enterView(row) {

    }
    enterEdit(row) {
        this.setState({isEdit: true})
    }
    handleDecrement(item) {
        item.qty = item.qty < 1 ? 0 : (item.qty - 1);
        let temp = this.state.availableDataPlans;
        temp.forEach((x) => {
            if (x.dataPlanId == item.dataPlanId) x.qty = item.qty
        });
        this.setState({ availableDataPlans: temp })
    }
    handleIncrement(item) {
        item.qty = item.qty + 1;
        let temp = this.state.availableDataPlans;
        temp.forEach((x) => {
            if (x.dataPlanId == item.dataPlanId) x.qty = item.qty
        });
        this.setState({ availableDataPlans: temp })
    }
    handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        this.setState({validated: true})
        if (form.checkValidity()) {
            //execute if valid
        }
    };
    render() {
        return <>{this.state.isLoading ? <Loader /> :
            <>
                <Row>
                    <Col>
                        <button className="btn btn-primary" onClick={() => this.enterCreate()}>Add Order</button>
                    </Col>
                    <Col>
                        <input type='text' placeholder="Type to filter..." className="form-control" onChange={(e) => this.filterItems(e.target.value)} />
                    </Col>
                </Row>
                <br />
                <DataTable
                    pagination
                    columns={[
                        {
                            name: 'Order NO.',
                            selector: row => row.orderNumber,
                            sortable: true,
                            width: "150px",
                            wrap: true,
                        },
                        {
                            name: 'System',
                            selector: row => row.systemId,
                            sortable: true,
                            width: "150px",
                            wrap: true,
                        },
                        {
                            name: "Data Plan",
                            selector: row => row.dataPlans.name,
                            sortable: true,
                            wrap: true,
                        },
                        {
                            name: "Actions",
                            width: "140px",
                            cell: (row) => {
                                return (
                                    <>
                                        <button className="btn btn-sm btn-primary" onClick={() => this.enterView(row)}>
                                            <FontAwesomeIcon icon={faEye} />
                                        </button>
                                        &nbsp;&nbsp;
                                        <button className="btn btn-sm btn-warning" onClick={() => this.enterEdit(row)}>
                                            {<FontAwesomeIcon icon={faPen} />}
                                        </button>
                                    </>
                                );
                            }
                        },]}
                    data={this.state.dataPlans} />

                { /* View  */}
                <Modal show={this.state.showView} fullscreen={"Yes"} onHide={() => this.setState({showView: false})} style={{ maxwidth: '100% important!' }}>
                    <Modal.Header closeButton>
                        <Modal.Title>View Order</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    </Modal.Body>
                </Modal>
                { /* Edit/Create  */}
                <Modal show={this.state.showEditCreate} fullscreen={"Yes"} onHide={() => this.setState({showEditCreate: false})} style={{ maxwidth: '100% important!' }}>
                    <Modal.Header closeButton>
                        <Modal.Title>{ this.state.isEdit ? "Ok" : "Create Order"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
                            <Row className="mb-12">
                                <Form.Group as={Col} md="12" controlId="validationCustom01">
                                    <Form.Label>Order Number</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Order Number"
                                        defaultValue={this.state.orderNumber}
                                        onChange={(e) => this.setState({ orderNumber: e.target.value })}
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row>
                                {
                                    this.state.availableProducts.map((item, index) => {
                                        return <Fragment key={index}>
                                            <a className={'btn ' + (this.state.products.find(x => x.name == item.name) != undefined ? 'btn-primary' : '')}
                                                onClick={(e) => { e.target.classList.toggle('btn-primary') }}>
                                                    {item.name}
                                                </a>&nbsp;
                                        </Fragment>
                                    })
                                }
                            </Row>
                            {
                                this.state.availableDataPlans.map((item, index) => {
                                    return <Row key={index}>
                                        <div className="form-group col-1 pt-2">
                                            <a  className="mt-4 form-control btn btn-secondary" onClick={() => this.handleDecrement(item)}>-</a>
                                        </div>
                                        <Form.Group as={Col} md="4" controlId="validationCustom01">
                                            <Form.Label>{item.name}</Form.Label>
                                        <Form.Control
                                            required
                                            type="number"
                                            placeholder="Order Number"
                                                value={item.qty}
                                        />
                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        </Form.Group>
                                        <div className="form-group col-1 pt-2">
                                            <a  className="mt-4 form-control btn btn-secondary" onClick={() => this.handleIncrement(item)}>+</a>
                                        </div>
                                    </Row>

                                })
                            }
                            <Button type="submit">{this.state.isEdit ? "Update" : "Create"}</Button>
                        </Form>
                    </Modal.Body>
                </Modal>
            </>
        }
        </>
    }
}