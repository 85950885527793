import { DELETE, GET, HEAD, POST, PUT } from "./ApiInterceptor";
const particleGetUri = 'api/ParticleApiService/GetGeneric';
const particlePostUri = 'api/ParticleApiService/PostGeneric';
const particlePutUri = 'api/ParticleApiService/PutGeneric';
const particleHeadUri = 'api/ParticleApiService/HeadGeneric';
const particleDeleteUri = 'api/ParticleApiService/DeleteGeneric';
export const GetAsGeneric = async (uri) => {
    return GET(particleGetUri + buildQueryArray(uri));
}
export const PostAsGeneric = async (uri, body = '') => {
    return POST(particlePostUri + buildQueryArray(uri) + '&stringBody='+ body);
}
export const PutAsGeneric = async (uri, body = '') => {
    return PUT(particlePutUri + buildQueryArray(uri) + '&stringBody=' + body);
}
export const HeadAsGeneric = async (uri, body = '') => {
    return HEAD(particleHeadUri + buildQueryArray(uri) + '&stringBody=' + body);
}
export const DeleteAsGeneric = async (uri) => {
    return DELETE(particleDeleteUri + buildQueryArray(uri));
}
const buildQueryArray = (arr) => {
    let str = '';
    let counter = 0;
    for (var item of arr) {
        if(counter == 0)
            str += '?q=' + item;
        else
            str += '&q=' + item;
        counter++;
    }
    return str;
}