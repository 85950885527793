import { faCheck, faExclamationCircle, faEye, faPen, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component, Fragment } from 'react';
import DataTable from 'react-data-table-component';
import { Col, Row } from 'reactstrap';
import Loader from '../Common/Loader';
import { createUpdateDataPlan, deleteDataPlan, getDataPlans } from '../Services/DataPlanService';
import { Form, Modal, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { isCurrentUserSuperAdmin } from '../../common/roleManager';
export class DataPlansTab extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            dataPlans: [],
            dataPlansCopy: [],
            showView: false,
            showEditCreate: false,
            isEdit: false,
            validated: false,
            dataPlanId:0,
            monthlyDataOperationsUnit: '',
            monthlyDataOperations: '',
            monthlyDataPoolUnit: '',
            monthlyDataPool: '',
            numberOfDevices: '',
            friendlyName: '',
            description: '',
            name: '',
            isActive: true,
        };
    }
    componentDidMount() {
        this.loadData();
    }
    async loadData() {
        let data = await getDataPlans();
        this.setState({
            dataPlans: data,
            dataPlansCopy: data
        });
        this.setState({ isLoading: false })
    }
    filterItems(str) {
        //repopulate for back space values
        var temp = this.state.dataPlansCopy
        //resset if no str is provided
        this.setState({
            dataPlans: this.state.dataPlansCopy
        })
        if (!str || str == '') {
            //always return on empty to reset full list
            return;
        }
        else {
            var temp = this.state.dataPlans.filter(x => x.name.toLowerCase().includes(str.toLowerCase()));
            this.setState({
                dataPlans: temp
            })
        }
    }
    enterCreate() {
        this.setState({showEditCreate: true})
    }
    enterEdit(row) {
        this.setDataPlan(row);
        this.setState({ isEdit: true, showEditCreate: true})
    }
    handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        this.setState({ validated: true })
        if (form.checkValidity()) {
            this.doCreateEditDataPlan();
        }
    };
    async doCreateEditDataPlan() {
        let plan = this.dataPlan();
        let res = await createUpdateDataPlan(plan, this.state.isEdit ? 'PUT' : 'POST');
        if (res.success) {
            toast.success(res.message);
            this.setState({ showEditCreate: false })
            this.loadData();
        }
        else {
            toast.error(res.message);
        }
    }
    clearDataPlan() {
        this.setState({
            dataPlanId:0,
            monthlyDataOperationsUnit: '',
            monthlyDataOperations: '',
            monthlyDataPoolUnit: '',
            monthlyDataPool: '',
            numberOfDevices: '',
            friendlyName: '',
            description: '',
            name: '',
            isActive: true
        })
    }
    setDataPlan(obj) {
        this.setState({
            dataPlanId: obj.dataPlanId,
            monthlyDataOperationsUnit: obj.monthlyDataOperationsUnit,
            monthlyDataOperations: obj.monthlyDataOperations,
            monthlyDataPoolUnit: obj.monthlyDataPoolUnit,
            monthlyDataPool: obj.monthlyDataPool,
            numberOfDevices: obj.numberOfDevices,
            friendlyName: obj.friendlyName,
            description: obj.description,
            name: obj.name,
            isActive: obj.isActive
        })
    }
    dataPlan() {
        let dataPlan = {
            dataPlanId: this.state.dataPlanId,
            monthlyDataOperationsUnit:this.state.monthlyDataOperationsUnit,
            monthlyDataOperations: this.state.monthlyDataOperations,
            monthlyDataPoolUnit: this.state.monthlyDataPoolUnit,
            monthlyDataPool: this.state.monthlyDataPool,
            numberOfDevices: this.state.numberOfDevices,
            friendlyName: this.state.friendlyName,
            description: this.state.description,
            name: this.state.name,
            isActive: this.state.isActive,
        }
        return dataPlan;
    }
    async deletePlan(row) {
        window.confirm('Are you sure you want to delete plan ' + row.name)
        const res = await deleteDataPlan(row.dataPlanId);
        await this.loadData();
        if (res.success) {
            toast.success(res.message)
        }
        else {
            toast.error(res.message);
        }
    }
    render() {
        return <>{this.state.isLoading ? <Loader /> : 
            <>
                <Row>
                    <Col>
                        <button className="btn btn-primary" onClick={() => this.enterCreate()}>Add Data Plan</button>
                    </Col>
                    <Col>
                        <input type='text' placeholder="Type to filter..." className="form-control" onChange={(e) => this.filterItems(e.target.value)} />
                    </Col>
                </Row>
                <br />
                <DataTable
                    pagination
                    columns={[
                        {
                            name: "Name",
                            selector: row => row.name,
                            sortable: true,
                            wrap: true,
                        },
                        {
                            name: "Friendly Name",
                            selector: row => row.friendlyName,
                            sortable: true,
                            wrap: true,
                        },
                        {
                            name: "No. Devices",
                            selector: row => row.numberOfDevices,
                            sortable: true,
                            wrap: true,
                        },
                        {
                            name: 'Monthly Data Operation',
                            selector: row => row.monthlyDataOperations,
                            sortable: true,
                            wrap: true,
                            width: "200px"
                        },
                        {
                            name: 'Monthly Data Pool',
                            selector: row => row.monthlyDataPool + ' ' + row.monthlyDataPoolUnit,
                            sortable: true,
                            wrap: true,
                        },
                        {
                            name: 'Active',
                            selector: row => {return row.isActive ? <FontAwesomeIcon color="green" icon={faCheck} /> : <FontAwesomeIcon color="red" icon={faExclamationCircle} />},
                            sortable: true,
                            center: true,
                            wrap: true,
                            width:"100px"
                        },
                        {
                            name: "Actions",
                            cell: (row) => {
                                return (
                                    <>
                                        <button className="btn btn-sm btn-warning" onClick={() => this.enterEdit(row)}>
                                            {<FontAwesomeIcon icon={faPen} />}
                                        </button>
                                        {
                                            isCurrentUserSuperAdmin() ?
                                                <>&nbsp;&nbsp;
                                                <button className="btn btn-sm btn-danger" onClick={() => this.deletePlan(row)}>
                                                    {<FontAwesomeIcon icon={faTrashAlt}/>}
                                                </button></> : <></>
                                        }
                                    </>
                                );
                            }
                        },]}
                    data={this.state.dataPlans} />
                { /* Edit/Create  */}
                <Modal show={this.state.showEditCreate} fullscreen={"Yes"} onHide={() => { this.setState({ showEditCreate: false }); this.clearDataPlan() }} style={{ maxwidth: '100% important!' }}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.isEdit ? "Edit Data Plan" : "Create Data Plan"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="12" controlId="validationCustom01">
                                    <Form.Label>Data Plan Name</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Data Plan Name"
                                        defaultValue={this.state.name}
                                        onChange={(e) => this.setState({ name: e.target.value })}
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="12" controlId="validationCustom01">
                                    <Form.Label>Friendly Name</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Data Plan Friendly Name"
                                        defaultValue={this.state.friendlyName}
                                        onChange={(e) => this.setState({ friendlyName: e.target.value })}
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="12" controlId="validationCustom01">
                                    <Form.Label>Number of Devices</Form.Label>
                                    <Form.Control
                                        required
                                        type="number"
                                        step="any"
                                        placeholder="Enter number of devices"
                                        defaultValue={this.state.numberOfDevices}
                                        onChange={(e) => this.setState({ numberOfDevices: e.target.value })}
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="9" controlId="validationCustom01">
                                    <Form.Label>Monthly Data Operations</Form.Label>
                                    <Form.Control
                                        required
                                        type="number"
                                        placeholder="Monthly Data Operations"
                                        defaultValue={this.state.monthlyDataOperations}
                                        onChange={(e) => this.setState({ monthlyDataOperations: e.target.value })}
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="3">
                                    <Form.Label>Select Unit</Form.Label>
                                    <Form.Control
                                        as="select"
                                        value={this.state.monthlyDataPoolUnit}
                                        onChange={(r) => this.setState({ monthlyDataPoolUnit: r.target.value })}
                                        required>
                                        <option value="">Select Unit</option>
                                        <option value="kb">KB</option>
                                        <option value="mb">MB</option>
                                        <option value="gb">GB</option>
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group as={Col} md="9" controlId="validationCustom01">
                                    <Form.Label>Monthly Data Pool</Form.Label>
                                    <Form.Control
                                        required
                                        type="number"
                                        step="any"
                                        placeholder="Monthly Data Pool"
                                        defaultValue={this.state.monthlyDataPool}
                                        onChange={(e) => this.setState({ monthlyDataPool: e.target.value })}
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Button type="submit">{this.state.isEdit ? "Update" : "Create"}</Button>
                        </Form>
                    </Modal.Body>
                </Modal>
            </>
            }
        </>
    }
}