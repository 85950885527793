import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {Form, Modal, Button, Card, Col, Container, InputGroup, Row, Table, Alert, Accordion, AccordionCollapse, Spinner } from 'react-bootstrap';
import DataTable from "react-data-table-component";
import { getLocalSystems } from "../Services/SystemService";
import { getUserSystemGroups } from "../Services/userServices";
import { getUsers } from '../Services/userServices';
import { createUpdateUserSystemGroup, deleteUserSystemGroup, getUserGroups } from "../Services/UserSystemGroupService";
import { toast } from 'react-toastify';
import Loader from "../Common/Loader";

class UserGroupManager extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            groups: [],
            showEditCreate: false,
            isEditMode: false,
            availableSystems: [],
            availableUsers: [],
            isLoading: true,
            selectedGroupName: '',
            selectedGroupId: 0,
            selectedSystems: [],
            selectedUsers: [],
            selectedAccountId: 0,
            validated: false,
            selectedDescription: '',
            checkedUsr: [],
            checkedSystem: [],
        }
    }
    handleShow() {
        this.setState({
            selectedAccountId: 0,
            selectedDescription: '',
            selectedGroupId:0,
            selectedGroupName: '',
            selectedSystems: [],
            selectedUsers: [],
            isEditMode: false,
            showEditCreate: true,
        })
    }
    enterEditModal(row) {
        //set all selectedGroup properties in state from row
        for (var usr of row.users) {
            var index = this.state.availableUsers.indexOf(this.state.availableUsers.find(x => x.user.id == usr));
            if (index > -1) {
                this.state.checkedUsr[index] = true;
                this.setState({ checkedUsr: this.state.checkedUsr });
            }
        }
        for (var system of row.systems) {
            var index = this.state.availableSystems.indexOf(this.state.availableSystems.find(x => x.systemId == system));
            if (index > -1) {
                this.state.checkedSystem[index] = true;
                this.setState({ checkedSystem: this.state.checkedSystem });
            }
        }
        this.setState({
            selectedAccountId: row.accountId,
            selectedDescription: row.description,
            selectedGroupId: row.id,
            selectedGroupName: row.name,
            selectedSystems: row.systems,
            selectedUsers: row.users,
            isEditMode: true,
            showEditCreate: true,
        })
    }
    handleUsrChange(userId, e) {
        if (this.state.selectedUsers.find(x => x == userId) != undefined) {
            let tempUsr = this.state.selectedUsers.filter(x => x != userId);
            this.state.selectedUsers = tempUsr;
        }
        else {
            this.state.selectedUsers.push(userId);
        }
        var temp = this.state.checkedUsr;
        temp[e] = !temp[e]
        this.setState({ checkedUsr: temp })
    }
    handleSystemChange(systemId, e) {
        if (this.state.selectedSystems.find(x => x == systemId) != undefined) {
            let tempSystem = this.state.selectedSystems.filter(x => x != systemId);
            this.state.selectedSystems = tempSystem;
        }
        else {
            this.state.selectedSystems.push(systemId);
        }
        var temp = this.state.checkedSystem;
        temp[e] = !temp[e]
        this.setState({ checkedSystem: temp })
    }
    componentDidMount() {
        this.getData()
    }
    async getData() {
        //let result = await getUserSystemGroups();
        let usrRes = await getUsers();
        let systems = await getLocalSystems();
        let groups = await getUserGroups();
        if (groups) {
            this.setState({groups: groups})
        }
        if (usrRes.success) {
            var temp = new Array(usrRes.users.length)
            temp.fill(false)
            this.setState({ availableUsers: usrRes.users, checkedUsr: temp });
        }
        if (systems) {
            var temp = new Array(systems.length)
            temp.fill(false)
            this.setState({ availableSystems: systems, checkedSystem: temp });
        }
        this.setState({ isLoading: false })
    }
    async removeGroup(row) {
        //delete by id
        if (window.confirm("Are you sure you want to delete group " + row.name + "?")) {
            let res = await deleteUserSystemGroup(row.id)
            if (res.success) {
                toast.success("Group was deleted successfully!");
                this.getData();
            }
            else {
                toast.error(res.message);
            }
        }
    }
    handleClose() {
        this.clearDataAndCloseModal();
    }
    handleSubmit = (event) => {
        this.setState({ validated: true });
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if (form.checkValidity()) {
            this.createEditGroup(this.state.isEditMode ? 'PUT' : 'POST')
        }
    };
    async createEditGroup(action) {
        let group = this.getGroupInfo();
        let res = await createUpdateUserSystemGroup(group, action);
        if (res.success) {
            this.getData();
            toast.success('Group was ' + (action === 'PUT' ? 'updated' : 'created') + ' successfully')
        } else {
            toast.error('Group was not ' + (action === 'PUT' ? 'updated' : 'created') + ' ' + res.message)
        }
        this.clearDataAndCloseModal();
    }
    clearDataAndCloseModal(openModal) {
        let tempUsr = [];
        let tempSystem = [];
        this.setState({
            checkedUsr:tempUsr,
            checkedSystem: tempSystem,
            selectedAccountId: '',
            selectedDescription: '',
            selectedGroupId: '',
            selectedGroupName: '',
            selectedSystems: [],
            selectedUsers: [],
            isEditMode: false,
            showEditCreate: openModal != undefined ? openModal : false,
        })
    }
    isChecked(value) {
        return (this.state.selectedSystems.find(x => x == value) != undefined)        
    }
    getGroupInfo() {
        let groupObj = {
            name: this.state.selectedGroupName,
            systems: this.state.selectedSystems,
            users: this.state.selectedUsers,
            id: this.state.selectedGroupId,
            accountId: this.state.selectedAccountId,
            description: this.state.selectedDescription,
        }
        return groupObj;
    }
    getColumns() {
        const columns = [
            {
                name: 'Group Name',
                selector: row => row.name,
                sortable: true,
                width: "250px",
            },
            {
                name: "Actions",
                width: "100px",
                cell: (row) => {
                    return (<>
                            <button className="btn btn-sm btn-warning" onClick={() => this.enterEditModal(row)}>
                                <FontAwesomeIcon icon={faPen} />
                            </button> <span>&nbsp;</span>
                            <button className="btn btn-sm btn-danger" onClick={() => this.removeGroup(row)}>
                                <FontAwesomeIcon icon={faTrash} />
                            </button>
                        </>
                    );
                }
            }
        ];
        return columns;
    }
    render() {
        return (<>
            {this.state.isLoading ?
                <Loader /> :<>
            <Row>
                <Col md={12}>
                    <button className="btn btn-primary col-2" onClick={() => this.handleShow()}>+ Add Group</button> &nbsp;&nbsp;
                </Col>
            </Row>
            <br />
            <Row>
                <Col md={12}>

                        <Card>
                            <DataTable
                                columns={this.getColumns()}
                                data={this.state.groups}
                                pagination
                            />
                        </Card>
                </Col>
            </Row></>
            }
            <Modal show={this.state.showEditCreate} onHide={() => this.handleClose()}>
                <Modal.Header closeButton>
                    <Modal.Title>User/System Groups</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.state.isLoading ? <>
                        <Spinner animation="border" variant="primary" /> Loading data...
                    </> :
                        <>
                        <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="12" controlId="validationCustom01">
                                    <Form.Label>Group Name</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Group Name"
                                        value={this.state.selectedGroupName}
                                        onChange={(e) => this.setState({ selectedGroupName: e.target.value })}
                                    />
                                </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                                        <Form.Label>Group Description</Form.Label>
                                        <Form.Control                                            
                                            type="text"
                                            placeholder="Group Description"
                                            value={this.state.selectedDescription == null ? '' : this.state.selectedDescription}
                                            onChange={(e) => this.setState({ selectedDescription: e.target.value })}
                                        />
                                    </Form.Group>
                                </Row>

                        <Row>
                            <Col md={6}>
                                <Card>
                                    <Card.Body>
                                        <Card.Title>Systems</Card.Title>
                                                {this.state.availableSystems ? this.state.availableSystems.map((system, index) =>
                                                {
                                                     return   <div key = { index } > <input type="checkbox" value={system.systemId} onChange={() => this.handleSystemChange(system.systemId, index)} checked={this.state.checkedSystem[index]} /> { system.name }</div>
                                                }
                                            ) : <></>}
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md={6}>
                                <Card>
                                    <Card.Body>
                                        <Card.Title>Users</Card.Title>
                                            {this.state.availableUsers ? this.state.availableUsers.map((usr, index) =>
                                                <div key={index}><input type="checkbox" value={usr.user.id} onChange={() => this.handleUsrChange(usr.user.id, index)} checked={this.state.checkedUsr[index]}/> {usr.user.userName}</div>
                                            ) : <></>}
                                    </Card.Body>
                                </Card>
                            </Col>
                                </Row>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={() => this.handleClose()}>
                                        Close
                                    </Button>
                                    <Button type="submit" variant="primary">
                                        {this.state.isEditMode ? "Update" : "Create"}
                                    </Button>
                                </Modal.Footer>
                            </Form>
                            </>
                    }
                </Modal.Body>
            </Modal>
        </>)
    }
}
export default UserGroupManager;