import { GET, GETAsString } from './ApiServices/ApiInterceptor';
import { DeleteAsGeneric, GetAsGeneric, HeadAsGeneric, PostAsGeneric, PutAsGeneric } from './ApiServices/ParticleInterceptor';

export var particleToken = '';

//this will be used to cancel requests in fetch
//we don't want any results to acceed 500ms 
//any long running tasks will be canceled

export const FsIotGenericUri = "FsIot";

export async function populateParticleBillingToken() {
    let fetchedData = await GETAsString('api/ParticleApiService/GetPartileBillingToken');
    return fetchedData;
}

export async function getLastKnownDeviceInfo(deviceId) {
    let fetchedData = await GET('api/Device/' + deviceId);
    return fetchedData;
}

export const getSingleDevice = async (deviceId) => {
    let response = await GetAsGeneric([FsIotGenericUri,"devices",deviceId]);
    return response;
}

export const getDeviceInfo = async (deviceId, productId) => {
    let result = await GetAsGeneric([FsIotGenericUri, "products",productId,"devices", deviceId])
    return result;
}

export const refreshDeviceVitals = async (deviceId) => {
    let res = await PostAsGeneric([FsIotGenericUri,'diagnostics', deviceId, 'update']);
    return res.ok;
}

export const getDeviceVariableWait = async (deviceId, variableName) => {
    const variable = await GetAsGeneric([FsIotGenericUri,"devices",deviceId, variableName]);
    return variable;
}

export const createGroup = async (groupName, productId) => {
    let response = await PostAsGeneric([FsIotGenericUri, "products",productId,"groups"], "name=" + groupName.replace(" ", "-") + "&" + "description=Auto_generated")
    updateSystemIsPushed(groupName.replace(" ", "-"));
    return response;
}

const updateSystemIsPushed = async (groupName) => {
    let response = await GET('api/Systems/setIsPushed?groupName=' + groupName + '&email=' + localStorage.getItem("userEmail"));
    return response;
}

export const callDeviceFunction = async (funcName, value, deviceId) => {
    let response = await PostAsGeneric([FsIotGenericUri, "devices", deviceId, encodeURIComponent(funcName)], "arg=" + value)
    return JSON.parse(response);
}

export const claimDevice = async (deviceId) => {
    let response = await PostAsGeneric([FsIotGenericUri,'devices'], 'id=' + deviceId)
    return response;
}

export const activateSim = async (iccid, productId) => {
    let response = await PostAsGeneric([FsIotGenericUri,"products",productId,"sims"], "sims[]=" + iccid)
    return response;
}

export const addExistingDeviceToProduct = async (deviceId, productId) => {
    let response = await PostAsGeneric([FsIotGenericUri,"products",productId,"devices"], `{ "ids":["${deviceId}"], "claim_user": "fsc_prod@icloud.com" }`);
    return response;
}

export const addDeviceToProduct = async (productId, deviceId) => {
    let response = await PostAsGeneric([FsIotGenericUri,"products",productId,"devices"], "id=" + deviceId);
    return response;
}

const getGroupsForSingleDevice = async (deviceId, productId) => {
    const fetchedDevice = await GetAsGeneric([FsIotGenericUri,"products",productId,"devices",deviceId]);
    return fetchedDevice.groups;
}

export const getDeviceVitals = async (deviceId) => {
    let response = await GetAsGeneric([FsIotGenericUri,'diagnostics',deviceId,'last']);
    return response.diagnostics;
}

export const getParticleDevices = async () => {
    var response = await GetAsGeneric([FsIotGenericUri,'devices']);
    return response;
}

export const getAllProducts = async () => {
    const response = await GetAsGeneric([FsIotGenericUri,"products"]);
    return response;
}
export async function forceOverTheAirUpdates(deviceId) {
    let response = await PutAsGeneric([FsIotGenericUri,"devices",deviceId], "firmware_updates_forced=true");
    return response;
}

export async function activateViaHead(iccid) {
    let response = await HeadAsGeneric([FsIotGenericUri,'sims',iccid])
    return response;
}

export const renameDevice = async (newName, deviceId) => {
    let response = await PutAsGeneric([FsIotGenericUri,"devices",deviceId], "name=" + newName);
    return response;
}

//delete product group
export const deleteProductGroup = async (groupName, productId) => {
    let response = await DeleteAsGeneric([FsIotGenericUri,"products",productId,"groups",groupName])
    return response;
}
export const addProductGroupsToDevice = async (productId, deviceId, token, groups) => {
    let groupsParam = '';
    for (var group of groups)
        groupsParam += groupsParam == '' ? `groups[]=${group}` : `&groups[]=${group}`;
    let response = await PutAsGeneric([FsIotGenericUri,"products",productId,"devices",deviceId], groupsParam)
    return response;
}

//Remove product association from Group
export const removeDeviceFromProduct = async (deviceId) => {
    let response = await DeleteAsGeneric([FsIotGenericUri,"devices",deviceId])
    return response;
}

export const getProductDevices = async (productId) => {
    let res = await GetAsGeneric([FsIotGenericUri, 'products', productId, 'devices?perPage=10000']);
    return res;
}

export const getAllDevicesWithAllData = async () => {
    //step 1: get all products this contains groups as well
    var allProducts = await getAllProducts();
    //Step 2: get all devices and link them to prodcuts and groups above
    //var allDevices = await getParticleDevices();
    var devices = [];
    for (var product of allProducts.products) {
        let ds = await getProductDevices(product.slug)
        devices = [...devices, ...ds.devices];
    }
    return devices;
        //for (var device of allDevices) {
        //    //find product by id
        //    var product = allProducts.products.find((tmp) => { return tmp.id == device.product_id });
        //    var deviceGroups = []
        //    if (product != undefined || product != null) {
        //        deviceGroups = await getGroupsForSingleDevice(device.id, product.id);
        //    }
        //    //check if group object within the device contains groups
        //    if (device.group && device.groups.legnth > 0) {
        //        for (var group of device.groups) {
        //            deviceGroups.push(group)
        //        }
        //    }
        //    //attach groups array to device
        //    device.groups = deviceGroups;
        //}
        //console.log('to real data', allDevices);
        //return allDevices;
}
function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const getAndRefreshDeviceVitals = async (deviceId) => {
    await refreshDeviceVitals(deviceId);
    await timeout(5000);
    let vitals = await getVitalsObject(deviceId)
    return vitals;
}


export const getVitalsObject = async (deviceId, token) => {
    var vitals = await getDeviceVitals(deviceId);
    var deviceVitals = {}
        deviceVitals.quality = 'Not available';

        deviceVitals.qualityV = 'Not available';

        deviceVitals.strength = 'Not available';

        deviceVitals.strengthV = 'Not available';

    if (vitals && vitals.deviceID) {
        var deviceId = vitals.deviceID;
        var lastUpdate = vitals.updated_at;
        var vitals = vitals.payload;
        if (vitals && vitals.device) {

            deviceVitals.roundTrip = vitals.device.cloud.coap.round_trip;

            deviceVitals.lastUpdate = lastUpdate;

            deviceVitals.deviceId = deviceId;

            //check if vitals came back with error object
            if (vitals.device.network.signal.quality.err) {
                return deviceVitals;
            } else {
                deviceVitals.quality = vitals.device.network.signal.quality +
                    vitals.device.network.signal.quality_units;

                deviceVitals.qualityV = vitals.device.network.signal.qualityv +
                    vitals.device.network.signal.qualityv_units

                deviceVitals.strength = vitals.device.network.signal.strength +
                    vitals.device.network.signal.strength_units

                deviceVitals.strengthV = vitals.device.network.signal.strengthv +
                    vitals.device.network.signal.strengthv_units
            }

        }
    }
    return deviceVitals;
}

//get all product groups: this is only needed to get color and descriptions
//otherwise the groups exists in the all products call
export const getGroups = async () => {
    let groups = [];
    let res = await getAllProducts();
    for (var product of res.products) {
        const fetchedData = await GetAsGeneric([FsIotGenericUri, "products", product.id, "groups"]);
        for (var group of fetchedData.groups) {
            groups.push(group.name);
        }
    }
    return groups;
}

//push current groups to server
//groupsSlugList: string array of slug ids aka apiProductId in db
export const pushLocalGroupstoRemote = async (groupsSlugList) => {
    var prods = await getAllProducts();
    for (var group of groupsSlugList) {
        //get corrsiponding product to groupname
        let baseProduct = prods.products.find((x) => x.slug == group.apiProductId);
        //create local grouop in api under corrisponding Product
        await createGroup(group.productName, baseProduct.id);
    }
}

