import React, { Component, Fragment, useEffect, useRef } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { getDataPlans } from '../Services/DataPlanService';
import { checkOrderNumber } from '../Services/OrderService';
export class CreateEditOrders extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showHide: false,
            loading: true,
            isEdit: false,
            orderId: 0,
            orderNumber: props.orderToEdit ? props.orderToEdit.orderNumber : '',
            dataPlan: props.orderToEdit ? props.orderToEdit.dataPlan : '',
            qty: props.orderToEdit ? props.orderToEdit.qty : 0,
            startDate: props.orderToEdit ? props.orderToEdit.startDate : '',
            expDate: props.orderToEdit ? props.orderToEdit.expDate :'',
            isActive: true,
            dataPlansOjbect: [],
            isValid: true,
            //validators
            orderNumberValid: '',
            dataPlanValid: '',
            qtyValid: '',
            startDateValid: '',
            expDateValid: '',
            formControl: 'form-control',
            errorMessages: [],
            submitDisabled: true,
            hasErrors: false,
            orderNumberAlreadyUsed: 'Order number already used',
            ordernumberMustHaveMoreChar: 'Order number must be more than 3 digits',
            orderNumberRequired: 'Order number required',
            expirationDateRequired: 'Expiration date required',
            expDateMustBeGreaterThanStart: 'Expiration date must be greater than start date',
            startDateRequired: 'Start date required',
            qtyRequired: 'Quantity required',
            qtyMustBePositive: 'Quantity must be more than zero',
            dataPlanRequired: 'Data plan required',
            oldOrder: '' //save value when editing to avoid checking if exists on edit 
        }
    }
    showHideOrders(orderObject) {
        this.clearError();
        this.clearOrder();
        if (orderObject) {
            this.setState({
                isEdit: true,
                qty: orderObject.qty,
                expDate: orderObject.expDate,
                startDate: orderObject.startDate,
                orderNumber: orderObject.orderNumber,
                dataPlan: orderObject.dataPlanId,
                orderId: orderObject.orderId,
                showHide: !this.state.showHide,
                oldOrder: orderObject.orderNumber, 
            })
        } else {
            this.setState({
                showHide: !this.state.showHide,
                oldOrder: '',
                isEdit: false,
            })
        }
    }
    async getAvailablePlans() {
        var plans = await getDataPlans();
        this.setState({dataPlansOjbect : plans})
    }
    componentDidMount() {
        this.getAvailablePlans();
    }
    clearOrder() {
        this.setState({
            orderId: 0,
            orderNumber: '',
            dataPlan:'',
            qty: 0,
            startDate:  '',
            expDate: '',
            orderNumberValid: '',
            dataPlanValid: '',
            qtyValid: '',
            startDateValid: '',
            expDateValid: '',
        })
    }
    async checkOrderNumberValidity(val) {
        let valid = 'is-invalid';
        if (val) {
            if (val.length > 3) {
                if (val == this.state.oldOrder) {
                    valid = 'is-valid';
                    this.clearError('order');
                } else {
                    let orderValid = await checkOrderNumber(val);
                    if (orderValid) {
                        valid = 'is-valid';
                        this.clearError('order');
                    } else {
                        this.addErrorMessage(this.state.orderNumberAlreadyUsed);
                    }
                }
            } else {
                this.addErrorMessage(this.state.ordernumberMustHaveMoreChar);
            }
        } else {
            this.addErrorMessage(this.state.orderNumberRequired)
        }
        this.setState({
            orderNumberValid: valid
        })
    }
    checkExpDateValidity(val) {
        let valid = 'is-invalid';
        if (val) {
            if (val > this.state.startDate) {
                valid = 'is-valid';
                this.clearError('expDate');
            } else {
                this.addErrorMessage(this.state.expDateMustBeGreaterThanStart)
            }
        }
        else {
            this.addErrorMessage(this.state.expirationDateRequired)
        }
        this.setState({
            expDateValid: valid
        })
    }
    checkStartDateValidity(val) {
        let valid = 'is-invalid';
        if (val) {
            valid = 'is-valid';
            this.clearError('startDate');
        }
        else {
            this.addErrorMessage(this.state.startDateRequired)
        }
        this.setState({
            startDateValid: valid
        })
    }
    checkQtyValidity(val) {
        let valid = 'is-invalid';
        if (val) {
            if (parseInt(val)) {
                if (parseInt(val) > 0) {
                    valid = 'is-valid';
                    this.clearError('qty');
                } else {
                    this.addErrorMessage(this.state.qtyMustBePositive)
                }
            } else {
                this.addErrorMessage(this.state.qtyMustBePositive)
            }
        }
        else {
            this.addErrorMessage(this.state.qtyRequired)
        }
        this.setState({
            qtyValid: valid
        })
    }
    checkDataPlanValidity(val) {
        if (val) {
            if (val != '') {
                this.setState({
                    dataPlanValid: 'is-valid'
                })
                this.clearError('dataPlan');
            } else {
                this.addErrorMessage(this.state.dataPlanRequired)
                this.setState({
                    dataPlanValid: 'is-invalid'
                })
            }
        } else {
            this.addErrorMessage(this.state.dataPlanRequired)
            this.setState({
                dataPlanValid: 'is-invalid'
            })
        }
    }
    addErrorMessage(message) {        
        if (this.state.errorMessages.find(x => x == message) == null) {
            this.state.errorMessages.push(message);
            var temp = this.state.errorMessages;
            this.setState({
                errorMessages: temp,
                hasErrors: true,
            })
        }
    }
    clearError(type) {
        var temp = this.state.errorMessages;
        switch (type) {
            case 'order':
                temp = temp.filter(x => x != this.state.orderNumberRequired && x != this.state.orderNumberAlreadyUsed && x != this.state.ordernumberMustHaveMoreChar);
                break;
            case 'qty':
                temp = temp.filter(x => x != this.state.qtyRequired && x != this.state.qtyMustBePositive);
                break;
            case 'dataPlan':
                temp = temp.filter(x => x != this.state.dataPlanRequired);
                break;
            case 'startDate':
                temp = temp.filter(x => x != this.state.startDateRequired);
                break;
            case 'expDate':
                temp = temp.filter(x => x != this.state.expirationDateRequired && x != this.state.expDateMustBeGreaterThanStart);
                break;
        }
        this.setState({
            errorMessages: temp,
            hasErrors: temp.length > 0 ? true : false
        })
    }
    createOrderObject() {
        return {
            orderNumber: this.state.orderNumber,
            expDate: this.state.expDate,
            startDate: this.state.startDate,
            qty: this.state.qty,
            dataPlanId: this.state.dataPlan,
            dataPlan: { id: this.state.dataPlan, name: this.state.dataPlansOjbect.find(x => x.dataPlanId == this.state.dataPlan) },
            orderId: this.state.orderId,            
        }
    }
    validateForm(){
        this.checkDataPlanValidity(this.state.dataPlan);
        this.checkExpDateValidity(this.state.expDate);
        this.checkOrderNumberValidity(this.state.orderNumber);
        this.checkStartDateValidity(this.state.startDate);
        this.checkQtyValidity(this.state.qty);
    }
    addOrderToParent(e) {
        e.preventDefault();
        this.validateForm();
        if (e.target.parentElement.parentElement.checkValidity()) {
            let order = this.props.addOrder(this.createOrderObject())
            if (order) {
                this.showHideOrders(null);
            } else {
              toast.error("Order was not added successfully!")
            }
        }
    }
    render() {
        return (
            <Modal show={this.state.showHide} className="modal-create" fullscreen="no" onHide={() => this.showHideOrders()}>
                <Modal.Header closeButton>
                    <Modal.Title>Orders</Modal.Title>
                </Modal.Header>
                <form>
                    <Modal.Body>
                        <div className="col-10">
                            <div className="form-group">
                                <label>Order Number</label>
                                <input
                                    type="text"
                                    className={this.state.formControl + " " + this.state.orderNumberValid}
                                    value={this.state.orderNumber}
                                    maxLength={25}
                                    onChange={(event) => { this.setState({ orderNumber: event.target.value }); this.checkOrderNumberValidity(event.target.value);  }}
                                    placeholder="*Order Number (must not be duplicated)"
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label>Data Plan</label>
                                <select value={this.state.dataPlan}
                                    className={this.state.formControl + " " + this.state.dataPlanValid}
                                    onChange={(e) => { this.setState({ dataPlan: e.target.value }); this.checkDataPlanValidity(e.target.value)}}
                                    required>
                                    <option value="">Select Data Plan</option>
                                    {
                                        this.state.dataPlansOjbect.map((i) => {
                                            return <option key={i.dataPlanId}
                                                name={i.name}
                                                value={i.dataPlanId}>
                                                {i.name}
                                            </option>;
                                        })
                                    }
                                </select>
                            </div>
                            <div className="form-group">
                                <label>Data Plan Qty</label>
                                <input
                                    type="number"
                                    className={this.state.formControl + " " + this.state.qtyValid}
                                    value={this.state.qty}
                                    maxLength={25}
                                    onChange={(event) => { this.setState({ qty: event.target.value }); this.checkQtyValidity(event.target.value) }}
                                    placeholder="*Data Plan Qty Number (must not be duplicated)"
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label>Start Date</label>
                                <input
                                    type="date"
                                    className={this.state.formControl + " " + this.state.startDateValid}
                                    defaultValue={this.state.startDate == '' ? this.state.startDate : this.state.startDate.split('T')[0]}
                                    maxLength={25}
                                    onChange={(event) => { this.setState({ startDate: event.target.value }); this.checkStartDateValidity(event.target.value);}}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label>Expiry Date</label>
                                <input
                                    type="date"
                                    className={this.state.formControl + " " + this.state.expDateValid}
                                    defaultValue={this.state.expDate == '' ? this.state.expDate : this.state.expDate.split('T')[0]}
                                    maxLength={25}
                                    onChange={(event) => { this.setState({ expDate: event.target.value }); this.checkExpDateValidity(event.target.value);}}
                                    required
                                />
                            </div>
                            {
                                this.state.hasErrors ?
                                    <div className="alert alert-danger">
                                        <ul>
                                            {
                                                this.state.errorMessages.map(x => <li key={x}>{x}</li>)
                                            }
                                        </ul>
                                    </div>:<></>
                            }
                        </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.showHideOrders()}>
                        Close
                        </Button>
                        <Button variant="primary" onClick={(e) => this.addOrderToParent(e)}>
                            {!this.state.isEdit ? <>Add Order</> : <>Replace Order</>}
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        )
    }
}