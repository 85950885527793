import { awaitBillingReportStatuscheck, getDataOpsForDevice, requestNewBillingReport } from "../components/Services/BillingService";
import { getTodayInBillingFormat } from "../components/Services/DateService";
import { formatDate } from "../components/Services/SystemService";
import React, { Component } from 'react';

export class DeviceUsageView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentDeviceUsage: { totalOps: 0, asOf: '' },
            isGettingDataOps: false,
            currentDevice: props.device ?? {},
            componentLoading: true,
        }
    }
    componentDidMount() {
        this.loadData();
    }
    async loadData() {
        this.setState({
            componentLoading: true
        })
        let currentDeviceUsage = await getDataOpsForDevice(this.state.currentDevice.id);
        this.setState({
            currentDeviceUsage: currentDeviceUsage,
            componentLoading: false
        })
    }
    async getDataOps(e) {
        this.setState({
            isGettingDataOps: true
        });

        let res = await getDataOpsForDevice(this.state.currentDevice.id);
        let result = await this.refreshReport()
        let currentDeviceusage = await getDataOpsForDevice(this.state.currentDevice.id);
        this.setState({
            currentDeviceUsage: currentDeviceusage,
            isGettingDataOps: false,
        })
    }
    async refreshReport() {
        let report = await requestNewBillingReport(getTodayInBillingFormat(), getTodayInBillingFormat(), this.state.token);
        if (report.data.id)
            await awaitBillingReportStatuscheck(report.data.id, this.state.token);
    }
    render() {
        return <>
            {this.state.componentLoading ?
                <tr><th>
                <>Loading Usage...
                        <div className="spinner-border spinner-border-sm"></div></></th></tr> :
                <>
                    <tr><th>Data Ops (Current Month)</th></tr>
                    <tr>
                        <td>
                            <>
                                {this.state.currentDeviceUsage?.totalOps} <br />
                                <b>as of</b> {formatDate(this.state.currentDeviceUsage?.asOf, 'dateTime')} &nbsp;
                            </>
                        </td>
                    </tr>
                </>
                }
        </>
    }
}