import { Tab } from 'bootstrap';
import React, { Component } from 'react';
import { Tabs } from 'react-bootstrap';
import { isCurrentUserAdmin, isCurrentUserPROJECTADMIN, isCurrentUserSuperAdmin } from '../../common/roleManager';
import { BillingDashboardTab } from './BillingDashboardTab';
import { BillingSettingsTab } from './BillingSettingsTab';
import { BillingTab } from './BillingTab';
export class Billing extends Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    }
    render() {
        return <>
            <Tabs defaultActiveKey={isCurrentUserAdmin() ||
                isCurrentUserSuperAdmin() ?
                "Dashboard" :
                "Billing"}
                id="uncontrolled-tab-example"
                className="mb-3" mountOnEnter={true} unmountOnExit={true}>
                {
                    isCurrentUserAdmin() || isCurrentUserSuperAdmin() || isCurrentUserPROJECTADMIN()?
                        <Tab eventKey="Dashboard" unmountOnExit={true} unmountOnExit={true} title="Dashboard">
                            <BillingDashboardTab />
                        </Tab>
                        :
                        <></>
                }
                <Tab eventKey="Billing" unmountOnExit={true} title="System Use Detail">
                    <BillingTab />
                </Tab>
                {
                    isCurrentUserAdmin() || isCurrentUserSuperAdmin() || isCurrentUserPROJECTADMIN() ?
                        <Tab eventKey="Settings" unmountOnExit={true} title="Settings">
                            <BillingSettingsTab />
                        </Tab>
                        :
                        <></>
                }
            </Tabs>
        </>
    }
}