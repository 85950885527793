///MAIN ENTRY: All fetching from api must go thru here
///URI should have api/controllerName/parameters on get
///URI should have api/controllerName/Parameters and/or stringified body
///TODO: This will be connected to nswag where additional api methods are
///auto generated

import { toast } from "react-toastify";
import authService from "../../api-authorization/AuthorizeService";

export const GET = async (uri) => {
    const response = await fetch(uri, await buildHeaders());
    return handleResponse(response);
}
export const GETAsString = async (uri) => {
    const response = await fetch(uri, await buildHeaders());
    return handleResponse(response, true);
}
export const POST = async (uri, stringifiedBody) => {
    let token = await authService.getAccessToken();
    const response = await fetch(uri, {
        method: 'POST',
        headers: !token ? {} : {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json;charset=UTF-8',
            'Accept': 'application/json, text/plain',},
        body: stringifiedBody
    });
    return handleResponse(response);
}
export const PUT = async (uri, stringifiedBody) => {
    let token = await authService.getAccessToken();
    const response = await fetch(uri, {
        method: 'PUT',
        headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
        body: stringifiedBody
    });
    return handleResponse(response);
}
export const HEAD = async (uri, stringifiedBody) => {
    let token = await authService.getAccessToken();
    const response = await fetch(uri, {
        method: 'HEAD',
        headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
        body: stringifiedBody
    });
    return handleResponse(response);
}
export const DELETE = async (uri) => {
    let token = await authService.getAccessToken();
    const response = await fetch(uri, {
        method: 'DELETE',
        headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
        'Content-Type': 'application/json;charset=UTF-8',

    });
    return handleResponse(response);
}
export const POST_FILE = async (file, uri) => {
    try {
        const token = await authService.getAccessToken();
        const formData = new FormData();
        formData.append('file', file)
        const response = await fetch(uri, {
            method: "POST",
            headers: !token ? {} : {
                'Authorization': `Bearer ${token}`,
            },
            body: formData
        });
        let fetchedData = await response.json();
        return fetchedData;
    }
    catch (e) {
        console.log(e)
        return { success: false, message: "something went wrong" }
    }
}
const buildHeaders = async () => {
    let token = await authService.getAccessToken();
    return { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } }
}
const handleResponse = async (response, asText = false) => {
    if (response.status > 200 && response.status != 401) {
        toast.error('Failed to load');
        return [];
    }
    if (response.status == 401) {
        console.log("unauthorized");
        await authService.signOut();
        await authService.completeSignOut();
    } else {
        if (asText) {
            let data = await response.text();
            return data;
        } else {
            let data = await response.json();
            return data;
        }
    }
}
const optionsBuilder = async (method) => {
    const token = await authService.getAccessToken();
    return {
        method: method,
        headers: !token ? {} : {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json;charset=UTF-8',
            'Accept': 'application/json, text/plain',
        }
    }
}