import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { LoginMenu } from './api-authorization/LoginMenu';
import './NavMenu.css';
import logo from './../resources/images/logo.png'
import { hasLocalStoragePopulated, isCurrentUserAdmin, isCurrentUserCustomer, isCurrentUserDEVICEADMIN, isCurrentUserPROJECTADMIN, isCurrentUserSuperAdmin, isCurrentUserViewer } from '../common/roleManager';
import authService from './api-authorization/AuthorizeService';
import { PublicAppName } from './api-authorization/ApiAuthorizationConstants';
export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor (props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
        currentTab: 'Home',
        isCurrentUserSuperAdmin: false,
        isCurrentUserViewer: false,
        isCurrentUserAdmin: false,
        isCurrentUserCustomer: false,
        isCurrentUserPROJECTADMIN: false,
        isCurrentUserDEVICEADMIN: false,
        isLoading: true,
    };
  }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
    }
    componentDidMount() {
        this.getUserRights()
    }
    async getUserRights() {
        let currentLocation = window.location.pathname.substring(1) == '' ? 'Home' : window.location.pathname.substring(1);
        var usr = await authService.getUser();
        if (usr) {
            this.setState({
                currentTab: currentLocation,
                isCurrentUserSuperAdmin: isCurrentUserSuperAdmin(),
                isCurrentUserAdmin: isCurrentUserAdmin(),
                isCurrentUserViewer: isCurrentUserViewer(),
                isCurrentUserCustomer: isCurrentUserCustomer(),
                isCurrentUserPROJECTADMIN: isCurrentUserPROJECTADMIN(),
                isCurrentUserDEVICEADMIN: isCurrentUserDEVICEADMIN(),
                isLoading: false,
            })
        }
    }
  render () {
    return (
      <header>
            <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
                    <Container>
                        <NavbarBrand tag={Link} to="/">
                            <img
                                src={require("./../resources/images/logo.png")}
                                width="30"
                                height="30"
                                className="d-inline-block align-top"
                                alt="Federal Signal Logo"
                            />
                        &nbsp; {PublicAppName}
                        </NavbarBrand>
                        <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={this.state.collapsed} navbar>
                        <ul className="navbar-nav flex-grow">
                                <LoginMenu>
                                </LoginMenu>
                            </ul>
                        </Collapse>
                    </Container>
        </Navbar>
      </header>
    );
  }
}
