import React from 'react';
export class FunctionQueue {
    constructor(retries, waitTime) {
        this.tasks = [];
        this.isProcessing = false;
        this.retries = retries ?? 3;
        this.waitTime = waitTime ?? 10;
    }
    enqueue(setter, getter, value) {
        this.tasks.push({ setter, getter, value, retries: this.retries });
        console.log('Intialized fallback function call for: ', setter, value);
        this.processQueue();
    }
    async processQueue() {
        if (this.isProcessing || this.tasks.length === 0) {
            return;
        }

        this.isProcessing = true;
        while (this.tasks.length > 0) {
            const { setter, getter, value, retries } = this.tasks[0];

            let success = false;
            for (let attempt = 0; attempt < retries; attempt++) {
                try {
                    console.log('attempt number:', attempt, 'for value:', value);
                    await this.wait(this.waitTime);
                    await setter();
                    const response = await getter();
                    if (value.toString() === response.result.toString()) {
                        success = true;
                        console.log(`Successfully set value ${value}`);
                        break;
                    } else {
                        console.error(`Failed to set value: ${value} after attempts`);
                    }
                }
                catch (error) {
                    console.error('Error during setting/getting execution:', error)
                }
            }
            this.tasks.shift();
        }
        this.isProcessing = false;
    }
    wait(seconds) {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, seconds * 1000)
        })
    }
}