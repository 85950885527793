import { GET, POST, PUT } from './ApiServices/ApiInterceptor';

 //Create or Update system Orders
export const addOrdersToSystem = async (systemId, ListofOrders) => {
    var values = JSON.stringify(ListofOrders);
    const fetchedData = await GET('api/Order/AddSystemOrders?email=' + localStorage.getItem("userEmail") + '&systemId=' + systemId + '&values=' + values);
    return fetchedData;
}
export const checkOrderNumber = async (orderNumber) => {
    const fetchedData = await GET('api/Systems/CheckOrderNumber?orderNumber=' + orderNumber);
    return fetchedData;
}
//Create/Update order and order data plans
export const createUpdateOrder = async (order, method) => {
    let value = JSON.stringify(order);
    let fetchedData;
    if (method.toLocaleLowerCase() === 'post')
        fetchedData = await POST('api/Order?value=' + value + '&email=' + localStorage.getItem('userEmail'));
    if (method.toLocaleLowerCase() === 'put')
        fetchedData = await PUT('api/Order?value=' + value + '&email=' + localStorage.getItem('userEmail'));
    return fetchedData;
}
//Get orders for gridview page
export const getOrders = async () => {
    const fetchedData = await GET('api/Order/' + localStorage.getItem("userEmail"));
    return fetchedData;
}



