import {  getAllDevicesWithAllData, pushLocalGroupstoRemote, getGroups, getVitalsObject } from '../Services/DeviceServices';
import React, { Component, Fragment } from 'react';
import { formatDate, getItems as getSystems } from '../Services/SystemService';
import { CustomTreeView }  from '../../common/CustomTreeView'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faChevronCircleUp, faSyncAlt, faTimes, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import ReactTooltip from "react-tooltip";
import { Breadcrumb, Card, Col, Container, InputGroup, Row, Table } from 'react-bootstrap';
import Loader from '../Common/Loader';
import { isCurrentUserAdmin, isCurrentUserCustomer, isCurrentUserPROJECTADMIN, isCurrentUserSuperAdmin, isCurrentUserViewer } from '../../common/roleManager';
import OrphanGroupsPopup from './OrphanGroupsPopup';
import { PublicAppName } from '../api-authorization/ApiAuthorizationConstants';
import { DeviceUsageView } from '../../common/DeviceUsageView';
import { DeviceAttributesView } from './DeviceAttributesView';
import { DeviceVitalsView } from '../../common/DeviceVitalsView';
export class Devices extends Component {

    constructor(props) {
        super(props);
        this.state = {
            apiToken: '',
            remoteGroups: [],
            devices: [],
            loading: true,
            systems: [],
            orphanGroups: [],
            orphanDevices: [],
            deviceCopy: [],
            pushLoading: false,
            cards: <div className="row">
                <div className="col-md-6 col-md-offset-3">Nothing to show.</div>
            </div>,
            content: '',
            syncLoading: false,
            showOrphanPopup: false,
            varValues: [],
            contentKey: 1,
        };
        this.updateSystemsAndGroups = this.updateSystemsAndGroups.bind(this);
        this.openOrphanModal = this.openOrphanModal.bind(this);
        this.closeOrphanModal = this.closeOrphanModal.bind(this);
        this.onItemClick = this.onItemClick.bind(this);
    }
    async updateSystemsAndGroups(apiToken = '') {
        this.setState({ syncLoading: true });
        var fullDevicesList = await getAllDevicesWithAllData();
        let remoteGroups = await this.getRemoteGroups();
        this.distinctOrphanDevices(fullDevicesList);
        let systems = await this.getSystems();
        await this.distinctOrphanGroups(remoteGroups, systems);
        this.setState({
            devices: fullDevicesList,
            loading: false,
            pushLoading: false,
            syncLoading: false,
            remoteGroups: remoteGroups,
        });
        this.setState({
            content: this.generateContent(),
            contentKey: this.state.contentKey + 1,
        })
    }
    componentDidMount() {
        this.componentAsync();
    }
    async componentAsync() {
        //generate token and make public to component
        let systems = await this.getSystems();
        let remoteGroups = await this.getRemoteGroups();
        let devices = await getAllDevicesWithAllData()
        await this.distinctOrphanGroups(remoteGroups, systems);
        this.distinctOrphanDevices(devices);
        this.setState({ devices: devices });
        this.setState({ remoteGroups: remoteGroups });
        this.setState({ content: this.generateContent() });
        this.setState({ loading: false });
    }
    async getSystems() {
        let systems = await getSystems();
        this.state.systems = systems;
        return systems;
    }
    async getRemoteGroups() {
        let groups = await getGroups();
        this.state.remotegroups = groups;
        return groups;
    }
    async distinctOrphanGroups(remoteGroups, systems) {
        //iterate through remote groups
        //and compare them to local groups in systems
        //the ones that do not exist are orphans. 
        let productsArr = []
        this.state.orphanGroups = [];
        for (var system of systems) {
            for (var product of system.systemProducts) {
                productsArr.push(product.productName)
            }
        }
        for (var group of remoteGroups) {
            if (productsArr.find(x => x.toLowerCase() === group.toLowerCase()) == undefined) {
                //check if already added
                if (this.state.orphanGroups.find(y => y === group) == undefined)
                    this.state.orphanGroups.push(group)
            }
            else {
                for (var system of systems) {
                    let localGroup = system.systemProducts.find((x) => x.productName.toLowerCase() == group.toLowerCase());
                    if (localGroup != undefined) {
                        localGroup.isOnServer = true;
                    }
                }
            }
        }
        this.setState({orphanGroups: this.state.orphanGroups})
    }
    distinctOrphanDevices(devices) {
        for (var device of devices) {
            if (device.groups == null || device.groups.length == 0) {
                this.state.orphanDevices.push(device);
            }
        }
    }

    onItemClick = (item) => {
        if (item.id) {
            item = [item]
        }
        this.setState({
            currentItem: item
        })
        let devices = [];
        if (item.length > 0) {
            for (var it of item) {
                if (it.id != undefined) {
                    let tempItem = this.state.devices.find(x => x.id == it.id)
                    tempItem.variables = []
                    devices.push(tempItem);
                }
            }
        }
        let vitalPromises = [];
        this.setState({
            cards: <div className="row">
                <div className="col-md-6 col-md-offset-3">Loading devices...</div>
            </div>
        })
        for (var device of devices) {
            vitalPromises.push(getVitalsObject(device.id));
        }

        Promise.all(vitalPromises).then(val => {
            for (var item of val) {
                for (var device of devices) {
                    if (device.id == item.deviceId) {
                        device.vitals = item;
                    }
                }
            }
            this.renderDeviceCards(devices);
        })
    };
    onExpandChangeOrphan = (event) => {
        event.item.expanded = !event.item.expanded;
    }
    onExpandChange = (event) => {
        event.item.expanded = !event.item.expanded;
    };
    pushGroups = async () => {
        await this.setState({ pushLoading: true });
        let groupNames = [];
        for (var system of this.state.systems) {
            for (var group of system.systemProducts) {
                if (!group.isOnServer)
                    groupNames.push({productName: group.productName, apiProductId: group.apiProductId});
            }
        }
        await pushLocalGroupstoRemote(groupNames);
        this.setState({ pushLoading: false });
        await this.updateSystemsAndGroups();
    }
    generateContent() {

        //treeview object for systems
        let sys = [];
        for (var system of this.state.systems) {
            system.systemName = system.name;
            let items = [];
            for (var group of system.systemProducts) {
                items.push({
                    text: group.productName,
                    isOnServer: group.isOnServer,
                    items: this.state.devices.filter((x) => x.groups.includes(group.productName.toLowerCase())),
                });
            }
            sys.push({
                isSystemDirectory: true,
                text: system.systemName,
                items: items
            })
        }

        //treeview object for orphan groups declaration
        let orph = []
        for (var orphan of this.state.orphanGroups) {
            let orphanItems = [];
            var orphanGroupDevices = this.findGroupDevices(orphan);
            for (var d of orphanGroupDevices) {
                orphanItems.push({
                    text: d.name,
                    id: d.id,
                    item: d,
                    isDevice: true,
                })
            }
            orph.push({
                text: orphan,
                items: orphanItems
            });
        }

        //treeview object for orphan devices
        let orphHeader = []
        let orphDevices = []
        for (var device of this.state.orphanDevices) {            
            orphDevices.push({
                text: device.name,
                id: device.id,
                isDevice: true,
            });
        }
        orphHeader.push({
            text: 'Orphan Devices',
            items: orphDevices
        })
            return (<Fragment>
                <div>
                    <h6 className="treeViewHeader">Systems</h6>
                    <CustomTreeView data={sys} onItemClick={this.onItemClick} />
                    {!isCurrentUserCustomer() ?
                        <>
                            <h6 className="treeViewHeader">Orphan Groups</h6>
                            <CustomTreeView data={orph} onItemClick={this.onItemClick} />
                            <h6 className="treeViewHeader">Orphan Devices</h6>
                            <CustomTreeView data={orphHeader} onItemClick={this.onItemClick} />
                        </> :
                        <></>

                    }
                </div>
            </Fragment>);
    }
    findGroupDevices(groupName) {
        return this.state.devices.filter((x) => x.groups.includes(groupName.toLowerCase()))
    }
    renderDeviceCards(devices) {
        let content = (
            <div className="row">{devices.map((data, index) => {
                return <Fragment key={ index * 12}>
                    <div className="card mt-1 ml-2" style={{"width":"47%"}} key={index}>
                        <div className="card-body">
                            <h6 className="card-title">{data.online ? <FontAwesomeIcon icon={faCheckCircle} color="green" /> : <FontAwesomeIcon color="red" icon={faTimes} />} {data.name}</h6>
                            <h6 className="card-subtitle mb-2 text-muted">{data.id}</h6>
                            <p className="card-text">IP Address: {data.last_ip_address} </p>
                            <p className="card-text">Last handshake:  {formatDate(data.last_handshake_at, 'dateTime')}</p>
                            <DeviceAttributesView device={data} />
                            <Table responsive bordered className="wdth-10">
                                <tbody>
                                    <DeviceVitalsView device={data} />
                                    <DeviceUsageView device={data} />
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </Fragment>
            })}
            </div>
        );
        if (devices.length < 1) {
            content = <div className="row">
                <div className="col-md-6 col-md-offset-3">Nothing to show.</div>
            </div>
        }
        this.setState({
            cards: content
        })
    }
    closeOrphanModal() {
        this.setState({ showOrphanPopup: false })
    }
    openOrphanModal() {
        this.setState({showOrphanPopup: true})
    }
    render() {
        return (<>
            {this.state.loading ?
                <Loader />
                :
                <Fragment>
                    <Breadcrumb>
                        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                        <Breadcrumb.Item active>Sync Manager</Breadcrumb.Item>
                    </Breadcrumb>
                    <>
                        <Row>
                            {
                                <Col md={4} className="mt-1">
                                    {isCurrentUserAdmin() || isCurrentUserSuperAdmin() || isCurrentUserPROJECTADMIN() ?
                                        <Card>
                                            <Card.Body>
                                                <Card.Title>Sync Controls</Card.Title>
                                                <InputGroup className="mb-3">
                                                    <button className="btn btn-xs btn-primary" data-tip data-for="registerTip" onClick={this.pushGroups}>
                                                        {this.state.pushLoading ? <span>pushing...</span> : <FontAwesomeIcon icon={faChevronCircleUp} color="white" />}
                                                    </button>
                                                    <ReactTooltip id="registerTip" place="top" effect="solid">
                                                        Push changes to {PublicAppName} server
                                                    </ReactTooltip>
                                                    &nbsp; Sync items with server (Push)
                                                </InputGroup>

                                                <InputGroup className="mb-3">
                                                    <button className="btn btn-xs btn-primary" data-tip data-for="refreshTip"
                                                        onClick={async () => {
                                                             await this.updateSystemsAndGroups()
                                                        }}>
                                                        {this.state.syncLoading ? <span>Pulling..</span> : <FontAwesomeIcon icon={faSyncAlt} color="white" />}
                                                    </button>
                                                    <ReactTooltip id="refreshTip" place="top" effect="solid">
                                                        Sync data with source
                                                    </ReactTooltip>
                                                    &nbsp; Get items from server (Read)
                                                </InputGroup>

                                                {
                                                    isCurrentUserAdmin() || isCurrentUserSuperAdmin() ?
                                                        <InputGroup className="mb-3">
                                                            <button className="btn btn-xs btn-primary" data-tip data-for="removeTip"
                                                                onClick={() => {
                                                                    this.openOrphanModal();
                                                                }}>
                                                                <FontAwesomeIcon icon={faTrashAlt} color="white" />
                                                            </button>
                                                            <ReactTooltip id="removeTip" place="top" effect="solid">
                                                                Remove orphan product groups
                                                            </ReactTooltip>
                                                            &nbsp; Remove orphan groups
                                                        </InputGroup> : <></>
                                                }
                                            </Card.Body>
                                        </Card> : <></>
                                }
                                    <br />
                                    <Card key={this.state.contentKey}>
                                        <Card.Body>
                                            <Card.Title>Systems, Groups & Devices</Card.Title>
                                            {this.state.content}
                                        </Card.Body>
                                    </Card>
                                </Col>
                                }
                            <Col>{this.state.cards}</Col>
                        </Row>
                    </>
                    <OrphanGroupsPopup updateSystemsAndGroups={this.updateSystemsAndGroups} closeOrphanModal={this.closeOrphanModal} showOrphanPopup={this.state.showOrphanPopup} orphanGroups={this.state.orphanGroups} token={this.state.apiToken}  />
                </Fragment>
                    }
        </>);
    }
}

export default Devices;