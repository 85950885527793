import authService from "../api-authorization/AuthorizeService";
import { GET, POST, POST_FILE } from "./ApiServices/ApiInterceptor";
import { populateParticleBillingToken } from "./DeviceServices";


export const getSingleSystemBillingDetails = async (systemId, startDate, endDate) => {
    let params = 'systemId=' + systemId + '&startDate=' + startDate + '&endDate=' + endDate;
    let fetchedData = await GET('api/Billing/SingleSystemBillingDetails?' + params);
    return fetchedData;
}
export const getSingleSystemBillingDetailsByPeriod = async (systemId, period) => {
    //this will search for a specific period with/without system
    let params = 'systemId=' + systemId + '&billingPeriod=' + period ;
    const fetchedData = await GET('api/Billing/SingleSystemBillingDetailsByPeriod?' + params);
    return fetchedData;
}
export const getFilterBillingDetails = async (startDate, endDate) => {
    //this will search for a specific period with/without system
    let params = 'email=' + localStorage.getItem('userEmail') + 'startDate=' + startDate + '&endDate=' + endDate;
    const fetchedData = await GET('api/Billing/FilterBillingDetails?' + params)
    return fetchedData;
}
export const getFilterBillingDetailsByPeriod = async (period) => {
    //this will search for a specific period with/without system
    let params = 'billingPeriod=' + period + '&email=' + localStorage.getItem('userEmail');
    const fetchedData = await GET('api/Billing/FilterBillingDetailsByPeriod?' + params);
    return fetchedData;
}
export const getCurrentPeriodDataUsage = async (selectedPeriod) => {
    const fetchedData = await GET('api/Billing/CurrentPeriodBilling?selectedPeriod='+selectedPeriod)
    return fetchedData;
}
export const getAllUsage = async (billingPeriod) => {
    const fetchedData = await GET('api/Billing/GetUsage?billingPeriod=' + billingPeriod + '&email=' + localStorage.getItem('userEmail'))
    return fetchedData;
}
export const getBillingSettings = async () => {
    const fetchedData = await GET('api/Billing/settings')
    return fetchedData;
}
export const getAllBillingPeriods = async () => {
    const fetchedData = await GET('api/Billing/GetAllBillingPeriods')
    try {
        fetchedData.sort((a, b) => new Date(b) - new Date(a))
        return fetchedData;
    }
    catch {
        return fetchedData
    }
}
export const getDataOpsForDevice = async (deviceId) => {
    const fetchedData = await GET('api/Billing/GetDataOpsForDevice/' + localStorage.getItem('userEmail') + '/' + deviceId)
    return fetchedData;
}
export const postBillingSettings = async (billingObject) => {
    let fetchedData = await POST('api/Billing/Settings?' + 'email=' + localStorage.getItem('userEmail'), JSON.stringify(billingObject))
    return fetchedData;
}
export const uploadBillingFile = async (file) => {
    let fetchedData = await POST_FILE(file, 'api/Billing/Upload/' + localStorage.getItem('userEmail'));
    return fetchedData;
}
export const getCurrentBillingPeriodDates = async () => {
    const fetchedData = await GET('api/Billing/GetCurrentBillingPeriodDates')
    return fetchedData;
}
export const requestNewBillingReport = async (startDate, endDate, token) => {
    token = await populateParticleBillingToken();
    const serviceAgreementId = '263631';
    const orgId = '63d168e70c844e4f7babc6a4';
    let res = await fetch('https://api.particle.io/v1/orgs/'+orgId+'/service_agreements/'+serviceAgreementId+'/usage_reports', {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer '+token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            'report_type': 'devices', //CRITICAL: keep report type as DEVICE
            'date_period_start': startDate, //example: '2022-01-01',
            'date_period_end': endDate //example: '2022-01-31'
        })
    });
    if (res.ok) {
        let response = await res.json();
        //use response.data.id to check report status
        return response;
    }
}
export const checkBillingReportStatus = async (reportId, token) => {
    token = await populateParticleBillingToken();
    let res = await fetch('https://api.particle.io/v1/orgs/63d168e70c844e4f7babc6a4/usage_reports/' + reportId, {
        headers: {
            'Authorization': 'Bearer ' + token,
            'Accept': 'application/json'
        }
    });
    if (res.ok) {
        let response = await res.json();
        return response;
    }
    //data.attributes.state indicates if report is ready
    //data.attributes.download_url to download file
}
export const downloadBilligFileFromUrl = async (url) => {
    let fetchedData = await GET('api/Billing/ReadFileFromLink?email=' + localStorage.getItem('userEmail') + '&url=' + url);
    return fetchedData;
}
export const awaitBillingReportStatuscheck = async (reportId, token) => {
    token = await populateParticleBillingToken();
    let result = await checkBillingReportStatus(reportId, token);
    while (!result || !result.data.attributes.state || result.data.attributes.state != 'available') {
        result = await checkBillingReportStatus(reportId, token);
    }
    let res = await downloadBilligFileFromUrl(encodeURIComponent(result.data.attributes.download_url));
    return res;
}
export const getSettingsThreshold = async () => {
    let settings = await getBillingSettings();
    settings = settings[0]
    let threshold = 100;
    if (settings.is95) {
        threshold = 95;
    }
    if (settings.is85) {
        threshold = 85;
    }
    if (settings.is75) {
        threshold = 75;
    }
    return threshold
}