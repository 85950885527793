import React from 'react'
import { Col, Spinner } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import { Input, Label, Row } from 'reactstrap';
import Loader from '../Common/Loader';
import { uploadNewDevices } from '../Services/DeviceControllerService';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

import { activateSim, activateViaHead, addDeviceToProduct, claimDevice, forceOverTheAirUpdates, getAllProducts } from '../Services/DeviceServices';
import { getLocalSystems } from '../Services/SystemService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faHamburger } from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';
import { delay } from '../Services/diagnosticsService';

export class DeviceBulkTab extends React.Component
{
    constructor(props) {
        super(props);
        this.state = {
            file: '',
            isUploading: false,
            devices: [],
            products: [],
            selectProductId: null,
            isSettingUp: false,
            currentDeviceId: '',
            currentProductId: '',
            currentIccid: '',
            bulkLoading: false,
            showReport: false,
            bulkReport: [],
            singleDeviceToProduct: []
        }
        this.inputRef = React.createRef();
        this.setShow = this.setShow.bind(this);
    }
    setShow() {
        this.setState({ showReport: !this.state.showReport })
    }
    componentDidMount() {
        this.loadData();
    }
    async loadData() {
        let products = await getAllProducts();
        this.setState({
            products: products.products
        })
    }
    async setUpSingleDevice(row, e) {
        if (!row.selectedProductId || row.selectedProductId == '' || row.selectedProductId == null) {
            toast.error('please select a product!')
        }
        const deviceId = row.deviceId;
        const iccid = row.iccid;
        const productId = row.selectedProductId;
        const button = e.target;
        return;
        button.innerHTML = '<div color="white" class="spinner-border spinner-border-sm"></div>'

        //Activate SIM
        let res = await activateViaHead(iccid)

        if (res.ok) {
            //claim device after 90 seconds
            setTimeout(async () => {
                let res = await claimDevice(deviceId)
                if (res.success)
                    toast.success('Device claimed successfully!')
                else
                    toast.error('Device claim failed!')
            }, 90000)
            //add to product after 30 seconds
            setTimeout(async () => {
                let register = await addDeviceToProduct(productId, deviceId)
                if (register.ok)
                    button.innerHTML = '<div color="white" class="spinner-border spinner-border-sm"></div>'
                else
                    toast.error('Device adding to product failed!')

                button.innerHTML = 'Setup';
            }, 12000)
        } else {
            toast.error('Device failed to activate no further actions will be taken. Please try again!')
        }
    }
    uploadDevices(e) {
        if (e == '' || !e) {
            toast.error('Please select a file!')
            return;
        }
        this.setState({ isUploading: true, file: e })
        uploadNewDevices(e).then((x) => {
            if (x.success) {
                this.setState({
                    data: x.data,
                    isUploading: false,
                })
            } else {
                toast.error(x.message)
            }
        }).catch((err) => {
            toast.error('Something went wrong!')
            this.setState({
                isUploading: false,
            })
        })

    }
   
    createDefs() {
        //define general columns
        var columns = [
            {
                name: 'DeviceId',
                selector: row => row.deviceid,
                sortable: true,
                width: "200px"
            },
            {
                name: 'SKU',
                selector: row => row.sku,
                sortable: true,
                width: "140px"
            },
            {
                name: 'ICCID',
                selector: row => row.iccid,
                sortable: true,
                width: "190px"
            },
            {
                name: 'IMEI',
                selector: row => row.imei,
                sortable: true,
                width: "140px"
            },
            {
                name: 'Serial No.',
                selector: row => row.seriaL_NUMBER,
                sortable: true,
                width: "150px"
            },
            {
                name: 'Assign Product',
                selector: (row) => {
                    return <select className="form-control form-control-sm" onChange={(e) => {
                        this.handleSingleDeviceToProductId(e.target.value, row.deviceid)
                    }}
                        defaultValue={this.state.selectProductId}
                    >
                        <option value="">Select Product</option>
                        {this.state.products.map((x, index) => { return <option value={x.id} key={index}>{x.name}</option>})}
                    </select>
                },
                width: "160px"
            },
        ]
        return columns

    }
    handleSingleDeviceToProductId(productId, deviceid) {
        let copy = this.state.singleDeviceToProduct;
        let item = copy.find(x => x.deviceid === deviceid);
        if (item) {
            item.productId = productId
        } else {
            copy.push({deviceid: deviceid, productId: productId})
        }
        this.setState({ singleDeviceToProduct : copy })
    }

    async tryClaim(deviceId) {
        let claim = { ok: false }
        try {
            claim = await claimDevice(deviceId)
            if (claim) {
                claim = JSON.parse(claim);
            }
            return claim;
        } catch {
            return claim;
        }
    }
    async activateAll() {
        if (!this.state.selectProductId) {
            toast.error('Must select a valid prodcut')
            return;
        }
        if (this.state.data.length < 1) {
            toast.error('Must have at least one device in list')
            return;
        }
        this.setState({ bulkLoading: true });
        let report = [];
        for (let device of this.state.data) {
            let deviceWithCustomProductId = this.state.singleDeviceToProduct.find(x => x.deviceid === device.deviceid);
            let addToProd = await addDeviceToProduct(deviceWithCustomProductId ? deviceWithCustomProductId.productId : this.state.selectProductId, device.deviceid);
            let claim = await this.tryClaim(device.deviceid);
            if (!claim.ok) {
                claim = await this.tryClaim(device.deviceid);
            }
            report.push({
                deviceid: device.deviceid,
                claim: claim,
                addToProd: JSON.parse(addToProd)
            });
        }
        this.setState({ bulkLoading: false, bulkReport: report, showReport: true })
    }
    handleDeviceAddToProductStatus(response, deviceId) {
        response = JSON.parse(response);
        if (response.updatedDeviceIds.length > 0 && response.updatedDeviceIds.find(x => x === deviceId)) {
            toast.success(`Device ID ${deviceId} was added to product succesfully!`);
            return;
        }
        if (response.existingDeviceIds.length > 0 && response.existingDeviceIds.find(x =>  x === deviceId)) {
            toast.success(`Device ID ${deviceId} already exists in product!`);
            return;
        }
        if (response.invalidDeviceIds.length > 0 && response.invalidDeviceIds.find(x => x === deviceId)) {
            toast.error(`Device ID ${deviceId} is invalid. Please check device ID and try again!`);
            return;
        }
        if (response.nonmemberDeviceIds.length > 0 && response.nonmemberDeviceIds.find(x => x === deviceId)) {
            toast.error(`Device ID ${deviceId} is a non-member Device ID. Please contact admin for assistance!`);
            return;
        }
        toast.error(`Device ID ${deviceId} was not added to product. Please try again!`);
    }
    async activateSingleDevice(productId, deviceId) {
        if (!productId) {
            toast.error('Please select a product!');
            return;
        }
        if (!deviceId) {
            toast.error('Please enter a device ID!');
            return;
        }
        const addToProdRes = await addDeviceToProduct(productId, deviceId)
        this.handleDeviceAddToProductStatus(addToProdRes, deviceId);
        let res = await this.tryClaim(deviceId)
        if (!res.ok) {
            res = await this.tryClaim(deviceId)
        }
        if (res.ok) {
            toast.success(`Device ID ${deviceId} was added activated succesfully!`);
        } else {
            toast.error(`Device ID ${deviceId} was not activated. please try again later!`);
        }
    }
    render() {
        return (<>
            <Row>
                <Col md="2">
                    Upload Devices:
                </Col>
                <Col md="8">
                    <button className="btn btn-outline-primary form-control" onClick={() => this.inputRef.current?.click()}>Select</button>
                    <input className="d-none" type="file" ref={this.inputRef} onChange={(e) => this.uploadDevices(e.target.files[0])} />
                </Col>
                <Col md="2">
                </Col>
            </Row><br /><br />
            {
                !this.state.data || this.state.data.length < 1 ? <>
                    <Row className="pb-3">
                        <Col>
                            <Label>Enter Device Id</Label>
                            <input type="text" className="form-control form-control-sm"
                                onChange={(e) => this.setState({
                                    currentDeviceId: e.target.value
                                })} />
                        </Col>
                        <Col>
                            <Label>Enter ICCID</Label>
                            <input type="text" className="form-control form-control-sm"
                                onChange={(e) => this.setState({
                                    currentIccid: e.target.value
                                })} />
                        </Col>
                        <Col>
                            <Label>Select Product</Label>
                            <select className="form-control form-control-sm" onChange={(e) => {
                                this.setState({ selectProductId: e.target.value });
                            }}>
                                <option value="">Select Product</option>
                                {this.state.products.map((x, index) => { return <option value={x.id} key={index}>{x.name}</option> })}
                            </select>
                        </Col>
                        <Col>
                            <button className="btn btn-primary mt-4 p-2" onClick={() => this.activateSingleDevice(this.state.selectProductId, this.state.currentDeviceId) }>Activate</button>
                        </Col>
                    </Row>
                </> : <>
                        <Row>
                            <Col>
                                <Label>Select Product for Bulk Operation:</Label>
                                <select className="form-control form-control-sm mt-3" onChange={(e) => {
                                    this.setState({ selectProductId: e.target.value });
                                }}>
                                    <option value="">Select Product</option>
                                    {this.state.products.map((x, index) => { return <option value={x.id} key={index}>{x.name}</option> })}
                                </select>
                            </Col>
                            <Col>{this.state.bulkLoading ? <button className='btn btn-primary mt-5'><Spinner animation="border" /></button> : 
                                <button className='btn btn-primary mt-5 p-2' onClick={() => this.activateAll()}>Setup All</button>
                            }
                                <button className='btn btn-primary mt-5 p-2 ml-2' onClick={() => this.setState({ showReport: true })} disabled={(this.state.bulkReport.length < 1)} >Show Report</button>
                                <button className='btn btn-primary mt-5 p-2 ml-2' onClick={() => {
                                    this.setState({ showReport: false, bulkReport: [], bulkLoading: false, data: [], file: '', singleDeviceToProduct: [], selectProductId: '' })
                                    this.inputRef.current.value = '';
                                }
                                }>Reset</button>
                            </Col>
                        </Row>
                        <Row>
                            <DataTable key={this.state.selectProductId} data={this.state.data} columns={this.createDefs()} />
                        </Row>
                    </>
            }
            <BulkSetupReport show={this.state.showReport} setShow={this.setShow} paramData={this.state.bulkReport } />
        </>)
    }
}


function BulkSetupReport({ show, paramData, setShow }) {
    React.useEffect(() => {
    }, [show])
    React.useEffect(() => {
        setData(paramData)
    }, [paramData])

    const [data, setData] = React.useState([]);
    const handleClose = () => setShow();
    const handleDeviceAddToProductStatus = (response, deviceId) => {
        let status = <span className='text-danger'>FAILED</span>
        if (response.updatedDeviceIds.length > 0 && response.updatedDeviceIds[0] === deviceId) {
            status = <span className='text-success'>SUCCESSFULL</span> 
        }
        if (response.existingDeviceIds.length > 0 && response.existingDeviceIds[0] === deviceId) {
            status = <span className='text-warning'>ALREADY EXISTS</span> 
        }
        return status;
    }
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Bulk Upload Report</Modal.Title>
            </Modal.Header>
            <Modal.Body>{data?.length > 0 ?
                <>
                    <ul>
                        {data?.map((x, key) => {
                            return (<><li key={key}>
                                <b>Adding to Product for Device {x.deviceid} :</b> {handleDeviceAddToProductStatus(x.addToProd, x.deviceid)}<br />
                                <b>Claim for Device {x.deviceid} :</b> {x.claim.ok ? <span className='text-success'>SUCCESSFULL</span> : <span className='text-danger'>FAILED</span>}
                        </li><hr /></>)
                        })}
                    </ul>
                </> :
                <>No Report Available!</>}</Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleClose}>
                    Ok
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default BulkSetupReport;

