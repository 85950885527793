import { DELETE, GET, PUT, POST } from "./ApiServices/ApiInterceptor";

export const getDataPlans = async () => {
    const fetchedData = await GET('api/DataPlan/' + localStorage.getItem("userEmail"))
    return fetchedData;
}
export const getFsDataBlocks = async () => {
    const fetchedData = await GET('api/DataBlocks/' + localStorage.getItem("userEmail"))
    return fetchedData;
}
export const postFsDataBlocks = async (num) => {
    const fetchedData = await GET('api/DataBlocks/' + localStorage.getItem("userEmail") + "/" + num);
    return fetchedData;
}
export const createUpdateDataPlan = async (dataPlan, method) => {
    let fetchedData;
    if (method.toLowerCase() === 'post')
        fetchedData = await POST('api/DataPlan?value=' + JSON.stringify(dataPlan) + '&email=' + localStorage.getItem('userEmail'))
    if (method.toLowerCase() === 'put')
        fetchedData = await PUT('api/DataPlan?value=' + JSON.stringify(dataPlan) + '&email=' + localStorage.getItem('userEmail'))
    return fetchedData;
}
export const deleteDataPlan = async (id) => {
    const fetchedData = await DELETE('api/DataPlan/' + id);
    return fetchedData;
}
