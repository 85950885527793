import { DELETE, GET } from './ApiServices/ApiInterceptor';

let data = [];

const generateId = (data) =>
    data.reduce((acc, current) => Math.max(acc, current.ProductID), 0) + 1;

async function populateSystemsData() {
    let fetchedData = await GET('api/Systems?email=' + localStorage.getItem("userEmail"));
    data = fetchedData;
}

export const insertItem = (item) => {
    item.systemId = generateId(data);
    item.inEdit = false;
    data.unshift(item);
    return data;
};
//move to date service
export const formatDate = (date, format) => {
    if (!date || date == '')
        return "No Date Found";
    let nDate = new Date(date.replace('T', ' ').replace('Z', ''));
    var timelagging = nDate.getTimezoneOffset() / 60; // 5 or 6
    var utc = new Date(nDate);
    var cdt = new Date(utc.getTime() - ((1 * 60 * 60 * 1000) * timelagging));
    return cdt.toLocaleString();
}
export const convertUTCtoCDT = (date) => {
    var timelagging = 6; // 5 or 6
    var utc = new Date(date);
    var cdt = new Date(utc.getTime() - ((1 * 60 * 60 * 1000) * timelagging));
    return cdt.toLocaleString();
}
export const getItems = async () => {
    await populateSystemsData();
    return data;
};
export const checkInvalidDataPlans = async () => {
    let fetchedData = await GET('api/Systems/CheckDataPlans');
    return fetchedData;
}
export const getSystemGroups = async (systemId) => {
    let fetchedData = await GET('api/Systems/getSystemGroups?systemId=' + systemId + '&email=' + localStorage.getItem("userEmail"));
    return fetchedData;
}
export const searchSystems = async (item) => {
    let fetchedData = await GET('api/Systems/searchSystems?q=' + item + '&email=' + localStorage.getItem("userEmail"));
    return fetchedData;
}

export const updateItem = (item) => {
    let index = data.findIndex((record) => record.systemId === item.systemId);
    data[index] = item;
    return data;
};

export const deleteItem = async (item) => {
    await DELETE('api/Systems/' + item.systemId + '?email=' + localStorage.getItem("userEmail"));
    let temp = await populateSystemsData();
    return temp;
};
export const getLocalSystems = async()=> {
    const data = await GET('api/Systems?email=' + localStorage.getItem("userEmail"));
    return data;
}