import React, { Component, Fragment } from 'react';
import { Button, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import authService from './AuthorizeService';
import { ApplicationPaths } from './ApiAuthorizationConstants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faUserAlt } from '@fortawesome/free-solid-svg-icons';
import { ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import { getUserInfo } from '../Services/userServices';
import { isCurrentUserAdmin, isCurrentUserCustomer, isCurrentUserDEVICEADMIN, isCurrentUserPROJECTADMIN, isCurrentUserSuperAdmin, isCurrentUserViewer } from '../../common/roleManager';

export class LoginMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            userName: null,
            currentTab: 'Home',
            isCurrentUserSuperAdmin: false,
            isCurrentUserViewer: false,
            isCurrentUserAdmin: false,
            isCurrentUserCustomer: false,
        };
    }

    componentDidMount() {
        window.onpopstate =  () => { this.handleUrlChange() }; window.history.pushState({}, '');
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }
    handleUrlChange() {
        let currentLocation = window.location.pathname.substring(1) == '' ? 'Home' : window.location.pathname.substring(1);
        this.setState({
            currentTab: currentLocation
        });
    }
    async populateState() {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
        if (isAuthenticated && user) {
            localStorage.setItem("userEmail", user.name);
            let usr = await getUserInfo(user.name);
            let role = usr.roles[0];
            localStorage.setItem("userRole", role);
        }
        let currentLocation = window.location.pathname.substring(1) == '' ? 'Home' : window.location.pathname.substring(1);
        this.setState({
            isAuthenticated,
            userName: user && user.name,
            currentTab: currentLocation,
        });
    }
    render() {
        const { isAuthenticated, userName } = this.state;
        if (!isAuthenticated) {
            const registerPath = `${ApplicationPaths.Register}`;
            const loginPath = `${ApplicationPaths.Login}`;
            return this.anonymousView(registerPath, loginPath);
        } else {
            const profilePath = `${ApplicationPaths.Profile}`;
            const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };
            return this.authenticatedView(userName, profilePath, logoutPath);
        }
    }

    authenticatedView(userName, profilePath, logoutPath) {
        return (<Fragment>
            <>
                <>
                    <NavItem>
                        <NavLink tag={Link} to="/" className={(this.state.currentTab == 'Home' ? 'selectedTab' : 'text-dark')}
                            onClick={() => {
                                this.setState({ currentTab: 'Home' })
                            }}>Home</NavLink>
                    </NavItem>
                    {!isCurrentUserDEVICEADMIN() ?
                        <NavItem>
                            <NavLink tag={Link} className={(this.state.currentTab == 'Systems' ? 'selectedTab' : 'text-dark')} to="/Systems"
                                onClick={() => this.setState({ currentTab: 'Systems' })}>System Manager</NavLink>
                        </NavItem> : <></>
                    }
                    {/*Super Admin, Admin, Viewer, and restiricted customer*/}
                    {isCurrentUserAdmin() || isCurrentUserSuperAdmin() || isCurrentUserCustomer() || isCurrentUserPROJECTADMIN() || isCurrentUserViewer() ?
                        <NavItem>
                            <NavLink tag={Link} className={(this.state.currentTab === 'Devices' ? 'selectedTab' : 'text-dark')} to="/Devices"
                                onClick={() => this.setState({ currentTab: 'Devices' })}>
                                {isCurrentUserCustomer() ? "System Treeview" : "Sync Manager"}
                               </NavLink>
                        </NavItem> :
                        <></>}
                    {/*Super Admin and Admin*/}
                    {isCurrentUserSuperAdmin() || isCurrentUserAdmin() || isCurrentUserDEVICEADMIN() || isCurrentUserViewer() ?
                        <NavItem>
                            <NavLink tag={Link} className={(this.state.currentTab == 'DeviceManager' ? 'selectedTab' : 'text-dark')} to="/DeviceManager"
                                onClick={() => this.setState({ currentTab: 'DeviceManager' })}>Device Manager</NavLink>
                        </NavItem> :
                        <></>
                    }
                    {isCurrentUserAdmin() || isCurrentUserSuperAdmin() ?
                        <NavItem>
                            <NavLink tag={Link} className={(this.state.currentTab == 'Orders' ? 'selectedTab' : 'text-dark')} to="/Orders"
                                onClick={() => this.setState({ currentTab: 'Orders' })}>
                                Data Plans
                            </NavLink>
                        </NavItem> :
                        <></>}
                    {!isCurrentUserDEVICEADMIN() ?
                        <NavItem>
                            <NavLink tag={Link} className={(this.state.currentTab == 'Billing' ? 'selectedTab' : 'text-dark')} to="/Billing"
                                onClick={() => this.setState({ currentTab: 'Billing' })}>Billing Manager</NavLink>
                        </NavItem> : <></>
                    }
                    {/*Super Admin Only*/}
                    {isCurrentUserSuperAdmin() || isCurrentUserAdmin() || isCurrentUserPROJECTADMIN() ?
                        <NavItem>
                            <NavLink tag={Link} className={(this.state.currentTab == 'Users' ? 'selectedTab' : 'text-dark')} to="/Users"
                                onClick={() => this.setState({ currentTab: 'Users' })}>User Manager</NavLink>
                        </NavItem> :

                        <></>
                    }
                </>

                <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic" className="customDropDown">
                        <FontAwesomeIcon icon={faUserAlt} />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item href="#">
                            {/*<NavLink tag={Link} className="text-dark" to={profilePath}>Hello {userName.split("@")[0]}</NavLink>*/}
                            <NavLink tag={Link} className="text-dark" to={profilePath}>Profile</NavLink>
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item href="#">
                            <NavLink tag={Link} className="text-dark" to={logoutPath}>Logout</NavLink>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </>
        </Fragment>);

    }

    anonymousView(registerPath, loginPath) {
        return (<Fragment>
            {/*<NavItem>*/}
            {/*    <NavLink tag={Link} className="text-dark" to={registerPath}>Register</NavLink>*/}
            {/*</NavItem>*/}
            <NavItem>
                <NavLink tag={Link} className="text-dark" to={loginPath}>Go to Login</NavLink>
            </NavItem>
        </Fragment>);
    }
}
