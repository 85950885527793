import React, { Component } from 'react';
import { Button, Modal, Table } from "react-bootstrap";
import { toast } from 'react-toastify';
import { deleteProductGroup, getAllProducts } from '../Services/DeviceServices';
export class OrphanGroupsPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showOrphanPopup: props.showOrphanPopup,
            orphanGroups: props.orphanGroups,
            token: props.token,
            groupsToDete: [],
            allProds: [],
            isLoading: true
        }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.showOrphanPopup != undefined)
            this.setState({ showOrphanPopup: nextProps.showOrphanPopup })
        if (nextProps.orphanGroups != undefined)
            this.setState({ orphanGroups: nextProps.orphanGroups })
        this.loadData();
        this.setState({ groupsToDete: [] })
    }
    componentDidMount() {
        this.loadData()
    }
    async loadData() {
        let res = await getAllProducts(this.props.token);
        this.setState({ allProds: res.products, isLoading: false });
    }
    openModel() {
        this.setState({ showPopup: true });
    }
    async doDeleteGroups() {
        if (this.state.groupsToDete.length < 1) {
            toast.warning('No groups are selected');
            return;
        }
        await this.sendDeleteGroups();
    }
    async sendDeleteGroups() {
        let refresh = this.props.updateSystemsAndGroups;
        let token = this.props.token;
        let promiseArr = [];
        if (window.confirm("You are about to delete orphan groups on the server, are you sure?")) {
            for (var group of this.state.groupsToDete) {
                let product = this.state.allProds.find(x => x.groups.find(y => y === group))
                if (product != undefined) {
                    //capture all requests sent in a promise array
                    promiseArr.push(deleteProductGroup(group, product.slug));
                }
            }
        }
        //iterate through each response of the array and display message accordingly
        Promise.all(promiseArr).then((x) => {
            x.map(res => {
                if (res.isSuccessStatusCode)
                    toast.success(`Group ${group} was deleted successfully!`)
                else
                    toast.error(`Group ${group} was NOT deleted please contact admin!`)
            });
        }).catch(err => {
            err.map(reason => {
                toast.error(`Group ${group} was NOT deleted please contact admin!`)
            })
        })
            .finally(function (y) {
                //once all requests are completed, refresh parent data.
                refresh(token);
            });
    }
    handleChange(value) {
        if (this.state.groupsToDete.find(x => x === value) == undefined)
            this.state.groupsToDete.push(value);
        else
            this.state.groupsToDete = this.state.groupsToDete.filter(x => x != value);
    }
    render() {
        return (<Modal show={this.state.showOrphanPopup} onHide={() => this.props.closeOrphanModal()}>
                <Modal.Header closeButton>
                    <Modal.Title>Remove Groups</Modal.Title>
            </Modal.Header>
            {this.state.isLoading ? <></> : 
                <Modal.Body>
                    {this.state.orphanGroups.length < 1 ? <Table><tr><th>No Recrods Found</th></tr></Table>:
                    <Table>
                        <thead>
                            <tr>
                                <th>Group Name</th><th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.orphanGroups.map((x, index) => {
                                return <tr key={index}><td>{x}</td><td><input type='checkbox' onClick={() => this.handleChange(x)} /></td></tr>
                            })}
                            <tr></tr>
                        </tbody>
                    </Table>}
                </Modal.Body>
                }
                <Modal.Footer>
                <Button variant="secondary" onClick={() => this.props.closeOrphanModal()}>
                        Close
                    </Button>
                <Button variant="danger" onClick={ () => this.doDeleteGroups()}>
                        Remove
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}
export default OrphanGroupsPopup;