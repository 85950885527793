import React from 'react'
import { Breadcrumb, Tab, Tabs } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { DeviceList } from './DeviceList';
import { DiscoverDevices } from './DiscoverDevices';
import { getAllDevicesWithAllData, getAllProducts, getParticleDevices, populateParticleToken } from '../Services/DeviceServices';
import Loader from '../Common/Loader';
import { DeviceBulkTab } from './DeviceBulkTab';
import { diagnosticService } from '../Services/diagnosticsService';

export class DeviceView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            devices: [],
            products: [],
            orphanDevices: [],
            knownDevices: [],
            newDevices: [],
            isLoading: true,
        }
    }
    loadData = () => {
        this.generateData();
    }
    extractSystemNameFromGroupName = (gpName, productId) => {
        if (gpName == '')
            return 'No System';
        if (gpName.indexOf(productId) < 1)
            return 'No System';
        let gp = gpName.substring(gpName.indexOf(productId) + productId.toString().length + 1)
        let systemName = gp.substr(0, gp.indexOf('-group'))
        systemName = systemName.replace('-', ' ');
        return systemName == null || '' ? 'No System' : systemName ;
    }

    async generateData() {
        this.setState({
            devices: [],
            products: [],
            orphanDevices: [],
            knownDevices: [],
            newDevices: [],
            isLoading: true,
            conslidateDevices: []
        });

        var products = await getAllProducts(); //72 MS

        var devices = await getAllDevicesWithAllData();//massive 5900 MS !ISSUE

        this.setState({ devices: devices, products: products.products });
        this.distinctOrphanDevices(this.state.devices);
        this.distinctNewDevices(devices, products);
        let temp = [...this.state.orphanDevices, ... this.state.knownDevices, ...this.state.newDevices];
        let filterTemp = [];
        temp.forEach((item) =>
        {
            if (filterTemp.find(x => x.id == item.id) == undefined) {
                if (item.groups && item.groups.length > 0) {
                    item.displaySystemName = this.extractSystemNameFromGroupName(item.groups[0], item.product_id)
                } else {
                    item.displaySystemName = 'No System'
                }
                filterTemp.push(item);
            }
        });//38 MS

        this.setState({ conslidateDevices: filterTemp });
        this.setState({ isLoading: false });
    }
    distinctNewDevices(devices, products) {
        let temp = []
        for (var device of devices) {
            if (products.products.find(x => x.id == device.product_id) == undefined) {
                device.isNew = true;
                temp.push(device);
            }
        }
        this.setState({ newDevices: temp });
    }
    distinctOrphanDevices(devices) {
        for (var device of devices) {
            if (device?.variables?.systemId == undefined
                || device.variables.systemId == null
                || devices.variables.baudRate < 1) {
                if (device.groups == null) {
                    device.badConfig = true;
                    this.state.orphanDevices.push(device);
                }
                else if (device.groups.length == 0) {
                    device.badConfig = true;
                    this.state.orphanDevices.push(device);
                }
                else {
                    this.state.knownDevices.push(device);
                }
            }
        }
    }
    componentDidMount() {
        this.generateData();
    }
    render() {
        return (<>
                <Breadcrumb>
                <Breadcrumb.Item href="/DeviceManager">Device Manager</Breadcrumb.Item>
                </Breadcrumb>
                {this.state.isLoading ? <Loader /> : <>
                <Tabs defaultActiveKey="devices" id="uncontrolled-tab-example" className="mb-3">
                    <Tab eventKey="devices" title="Device List">
                        <DeviceList devices={this.state.conslidateDevices} products={this.state.products} loadData={this.loadData}/>
                    </Tab>
                    <Tab eventKey="bulkUpload" title="Device Bulk Setup">
                        <DeviceBulkTab  />
                    </Tab>
                    </Tabs>
                </>}
        </>)
    }
}
