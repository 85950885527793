export class diagnosticService  {    
    //all class members are to be static for time measurement
    static startTime = '';
    static endTime = '';
    static start = () => {
        this.startTime = new Date();
    };
    static end = (msg) => {
        this.endTime = new Date();
        var timeDiff = this.endTime - this.startTime; //in ms
        var ms = Math.round(timeDiff);
        console.log(ms + msg ?? " MS elapsed to perform operation");
    }
    static timeout = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
}
export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));