import { faCheck, faExclamationCircle, faEye, faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component, Fragment } from 'react';
import DataTable from 'react-data-table-component';
import { Col, Row } from 'reactstrap';
import Loader from '../Common/Loader';
import { createUpdateDataPlan, getDataPlans, getFsDataBlocks, postFsDataBlocks } from '../Services/DataPlanService';
import { Form, Modal, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
export class FsDataPlansTab extends Component {

    constructor(props) {
        super(props);
        this.state = {
            blocks: 0,
            isLoading: true,
            qty: 0
        }
    }
    componentDidMount() {
        this.loadData();
    }
    async loadData() {
        let data = await getFsDataBlocks();
        this.setState({ data: data[0], qty: data[0]?.qty });
        this.setState({ isLoading: false })
    }
    updateBlocks(e) {
        e.preventDefault();
        if (this.state.qty < 1) {
            toast.error("FS must have 1 block minimum");
            return;
        }
        postFsDataBlocks(this.state.qty).then(x => {
            if (x.success)
                toast.success(x.message)
            else
                toast.error(x.message);
        })
    }
    render() {
        return <>{this.state.isLoading ? <Loader /> :
            <>
                <label>Enter Number of Blocks</label>
                <input type="number"
                    className="form-control"
                    placeholder="blocks"
                    value={this.state.qty}
                    onChange={(e) => this.setState({qty: parseInt(e.target.value)})}
                />
                <button className="btn btn-primary" onClick={(e) => this.updateBlocks(e)}>Update</button>
            </>

        }</>
    }
}
