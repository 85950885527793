import { faBell, faCheck, faCheckCircle, faExclamationCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { Card, Fade } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { getAllUsage, getBillingSettings, getSettingsThreshold } from '../../Services/BillingService';
export class CustomerTopUsage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            customerTopUsage: [],
            isOverage: false,
            selectedPeriod: props.selectedPeriod,
            settings: {},
            threshold: .74
        };
    }
    componentDidUpdate(prevProps) {
        if (prevProps.selectedPeriod !== this.props.selectedPeriod) {
            this.getData();
        }
    }
    componentDidMount() {
        this.getData();
    }
    async getData() {
        let use = await getAllUsage(this.props.selectedPeriod ?? 0);
        let threshold = await getSettingsThreshold();
        var temp = [];
        for (var system of use) {
            if (system.currentDataOpsUsagePct > (threshold / 100) && system.currentDataOpsUsage != 0)
                temp.push(system)
            if (system.currentNumberDevices > system.plannedNumberDevices) 
                if (temp.find(x => x.name) == null)
                    temp.push(system)
        }
        this.setState({
            customerTopUsage: temp,
            isOverage: temp.length > 0,
            threshold: threshold
        });
    }
    returnUsageIcon(plannedUse, actualUse, usagePct) {
        if (actualUse > plannedUse) {
            return <FontAwesomeIcon icon={faExclamationCircle} color="red" />
        }
        if (usagePct > 100)
            return (<FontAwesomeIcon icon={faExclamationCircle} color="red" />)
        if (usagePct > this.state.threshold && actualUse <= plannedUse)
            return (<FontAwesomeIcon icon={faExclamationTriangle} color="orange" />)
        return (<FontAwesomeIcon icon={faCheckCircle} color="green" />)
    }
    createSysDefs() {
        //define general columns
        var columns = [
            {
                name: 'System',
                selector: row => row.name,
                sortable: true,
                wrap: true,
                width: "120px",
            },
            {
                name: 'Device Use',
                selector: row => {
                    return (<>
                        {row.currentNumberDevices > row.plannedNumberDevices ? <FontAwesomeIcon icon={faExclamationCircle} color="red" /> :
                            <FontAwesomeIcon icon={faCheckCircle} color="green" />}&nbsp;
                        {row.currentNumberDevices} <b>{' of '}</b> {row.plannedNumberDevices}</>)
                },
                sortable: true,
                wrap: true,
                width: "110px",
            },
            {
                name: 'Data Use',
                selector: row => {
                    return (<>
                        {this.returnUsageIcon(row.plannedDataOpsUsage, row.currentDataOpsUsage, Math.round((row.currentDataOpsUsagePct * 100), 2).toFixed(0))}
                        &nbsp;{row.currentDataOpsUsage} <b>{' of '}</b>  {row.plannedDataOpsUsage}</>)
                },
                sortable: true,
                wrap: true,
                width: "166px",
            },
            {
                name: '% Data Use',
                selector: row => Math.round((row.currentDataOpsUsagePct * 100), 2).toFixed(0) + '%',
                sortable: true,
                wrap: true,
                width: "115px",
            }
        ]
        return columns;
    }
    render() {
        return <>
            <Card>
                <Card.Body>
                    <Card.Title>{
                        this.state.isOverage ? <FontAwesomeIcon icon={faBell} color="red"/>
                            : <FontAwesomeIcon icon={faCheck} color="green" />} Customers Usage Alert</Card.Title>
                    {this.state.customerTopUsage.length > 0 ?
                        <DataTable
                            width="200px"
                            columns={this.createSysDefs()}
                            data={this.state.customerTopUsage}
                            pagination
                        /> :
                        <p className="text-center pt-4">There are no alerts to display</p>
                    }
                </Card.Body>
            </Card>
        </>
    }
}