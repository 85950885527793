import React, { Component } from 'react';
import DataTable from 'react-data-table-component';
import { faCheckCircle, faExclamationCircle, faEye, faPen, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Form, InputGroup, Modal, ProgressBar, Row, Table } from 'react-bootstrap';
import { formatDate, getItems, getSystemGroups } from '../Services/SystemService';
import { addDeviceToProduct, addExistingDeviceToProduct, addProductGroupsToDevice, callDeviceFunction, getAndRefreshDeviceVitals, getDeviceInfo, getDeviceVariableWait, getDeviceVitals, getLastKnownDeviceInfo, getSingleDevice, getVitalsObject, renameDevice } from '../Services/DeviceServices';
import { Fragment } from 'react';
import {  Spinner } from 'reactstrap';
import { toast } from 'react-toastify';
import { awaitBillingReportStatuscheck, getDataOpsForDevice, requestNewBillingReport } from '../Services/BillingService';
import { getTodayInBillingFormat } from '../Services/DateService';
import { DeviceUsageView } from '../../common/DeviceUsageView';
import { DeviceVitalsView } from '../../common/DeviceVitalsView';
import { FunctionQueue } from '../Services/FunctionQueue';

export class DeviceDataTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            devices: this.props.devices,
            filterDevices: this.props.devices,
            products: this.props.products,
            show: false,
            showEdit: false,
            currentDevice: {},
            currentDeviceVitals: {},
            currentSystemId: '',
            currentGroup: '',
            currentDeviceName: '',
            currentBaudRate: '',
            currentProductId: '',
            validated: false,
            systems: [],
            systemGroups: [],
            token: '',
            isVarsLoading: false,
            progressBarPct: 0,
            showProgressModal: false,
            currentFirmwareVersion: '',
            isGettingDataOps: false,
            isRefreshVitals: true,
        }
        this.enterEdit = this.enterEdit.bind(this);
        this.filterItems = this.filterItems.bind(this);
        this.getDataOps = this.getDataOps.bind(this);
        this.queue = new FunctionQueue(3, 10);
    }
    componentDidUpdate() {
    }
    async getSystems() {
        let systems = await getItems();
        this.setState({systems: systems})
    }
    componentDidMount() {
        this.getSystems();
    }
    handleSubmit = (event) => {
        const form = event.currentTarget;
        this.setState({ validated: true });
        event.preventDefault();
        event.stopPropagation();
        let currentProductId = this.state.currentProductId;
        let currentDevice = this.state.currentDevice;
        let token = this.state.token;
        let currentGrpArray = [this.state.currentGroup]
        let currentDeviceName = this.state.currentDeviceName;
        if (form.checkValidity()) {
            if (this.state.currentDevice.isNew) {
                //method calls loadData internally
                //as the background worker needs to finish first
                this.assignDeviceToProduct();
            }
            else {

                this.editCurrentDevicesProduct(currentDevice, currentProductId, this.state.currentSystemId, currentDeviceName).then(() => {
                    //it is critical that update items happen after
                    //product upate and preferably a timeout of 1000ms
                    //due to the limitation of how Particle IO structure their
                    //devices within products and change varaible values 
                    //in which they have to wait for an over the air update
                    //error Unable to call function on device during an OTA
                    this.assignProductGroupToDevice(currentProductId, currentDevice, token, currentGrpArray);
                })
            }
            this.setShowEdit(false);
        }
    };
    async editCurrentDevicesProduct(currentDevice, currentProductId, currentSystemId, currentDeviceName) {
        if (this.state.currentDevice.product_id == this.state.currentProductId) {
            toast.warning("Device product wasn't changed.")
            this.renameDevice();
            this.callFunctions(this.state.currentDevice, this.state.currentSystemId);
            return;
        }
        toast.info(<>
            <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
            />
            &nbsp; Please wait, configuring device.</>, { autoClose: 25000, hideProgressBar: true });
        let res = await addDeviceToProduct(currentProductId, currentDevice.id);
        res = JSON.parse(res);
        if (res?.updatedDeviceIds?.length > 0) {
            toast.success('Device was reassigned to new product successfully!');
        }
        else {
            toast.error('Device was not assigned to product. Please try again!')
        }
        setTimeout(() => {
            this.renameDeviceWithParams(currentDeviceName, currentDevice);
            this.callFunctions(currentDevice, currentSystemId);
        }, 25000)
    }
    async renameDeviceWithParams(currentName, currentDevice) {
        if (currentName === currentDevice.name) {
            toast.warning("Device name was not changed, no requests were sent!");
            return;
        }
        let result = await renameDevice(currentName, currentDevice.id);
        if (result.name)
            toast.success("Device name was changed successfully!");
        else
            toast.error("Failed to change device name.");
    }
    async renameDevice() {
        let name = this.state.currentDeviceName;
        if (name === this.state.currentDevice.name) {
            toast.warning("Device name was not changed, no requests were sent!");
            return;
        }
        let result = await renameDevice(name, this.state.currentDevice.id);
        if (result.name)
            toast.success("Device name was changed successfully!");
        else
            toast.error("Failed to change device name.");
    }
    async assignDeviceToProduct() {
        let currentDevice = this.state.currentDevice;
        currentDevice.isUpdating = true;
        currentDevice.loadingFeedback = '';
        currentDevice.isRestartSuccess = true;
        //assign/add device to product
        var result = { success: true };
        if (!this.state.currentDevice.isTestDevice)
            result = await addDeviceToProduct(this.state.currentProductId, this.state.currentDevice.id);

        if (result.ok) {
            toast.success("Device was assigned to product successfully.");
            currentDevice.isNew = false;
            this.setShowEdit(false);
            this.setState({ currentDevice: currentDevice });
            this.setState({showProgressModal: true})
            //toast.info("Device will restart, the page will refresh automatically once device is ready. This may take up to 4 minutes.", { autoClose: false, hideProgressBar: true });
            //fire and forget progress checker
            this.checkDeviceStatusWorker(currentDevice.id, this.state.currentProductId);
        }
        else {
            toast.error("Failed to assign device to product. Please contact admin.");
        }
    }
    timer = ms => new Promise(res => setTimeout(res, ms));
    async checkDeviceStatusWorker(deviceId, productId) {
        for (var i = 0; i < 240; i++) {
            this.updateProgressBar(i);
            var res = await getDeviceInfo(deviceId, productId);
            if (res.functions) {
                if (res.functions.includes('Set System ID')) {
                    let device = this.state.currentDevice;
                    device.isNew = false;
                    device.isUpdating = false;
                    device.functions = res.functions
                    device.isRestartSuccess = true;
                    device.loadingFeedback = <span className="text-success">Ready</span>
                    this.updateProgressBar(240);
                    this.setState({ currentDevice: device, showProgressModal: false });
                    this.setShowEdit(false);
                    this.setShowEdit(true);
                    return;
                }
            }
            await this.timer(1000);
        }
        let device = this.state.currentDevice;
        device.isUpdating = false;
        device.isRestartSuccess = false;
        device.loadingFeedback = <span className="text-danger">Failed</span>;
        this.updateProgressBar(240);
        this.setState({ currentDevice: device });
        //this.props.loadData();
    }
    async assignProductGroupToDevice(currentProductId, currentDevice, token, currentGrpArray) {
        let res = await addProductGroupsToDevice(currentProductId, currentDevice.id, token, currentGrpArray)
        if (res && res.id)
            toast.success("Group was assigned to product successfully!")
        else
            toast.error("Group assignment failed to update!")
    }
    callFunctions(currentDevice, currentSystemId) {
        currentDevice.functions.forEach(async (x, index) => {
            if (x.toLowerCase().includes('system id')) {
                let res = await callDeviceFunction(x, currentSystemId, currentDevice.id);
                if (res && res.id) {
                    toast.success("Function " + x + " update successfully!")
                }
                else {
                    toast.error("Function " + x + " failed to update!")
                    this.queue.enqueue(async () => callDeviceFunction(x, currentSystemId, currentDevice.id), async () => getDeviceVariableWait(currentDevice.id, x.replace('Set ', '')), currentSystemId);
                }
            }
            else {
                //get a copy of the previously pulled function values if current values are undefined (submit with no change in form)
                let varValue = this.getVariableValue(x);
                let res = await callDeviceFunction(x, varValue
                                                    , currentDevice.id);
                if (res && res.id) {
                    toast.success("Function " + x + " update successfully!")
                }
                else {
                    toast.error("Function " + x + " failed to update!")
                    this.queue.enqueue(async () => callDeviceFunction(x, varValue, currentDevice.id), async () => getDeviceVariableWait(currentDevice.id, x.replace('Set ', '')), varValue);
                }
            }
            if (currentDevice.functions.forEach.length == index + 1) {
                setTimeout(() => {
                    this.props.loadData();
                }, 6000)
            }
        })
    }
    clearCurrentDeviceValues() {
        this.setState({
            currentProductId: '',
            currentDeviceVitals: '',
            currentDevice: '',
            currentSystemId: '',
            currentGroup: '',
            currentDeviceName: '',
        })
    }
    setShowEdit(val) {
        if (!val) {
            this.clearCurrentDeviceValues();
        }
        this.setState({ showEdit: val });
    }

    async refreshVitals(deviceId, btn) {
        this.setState({
            isRefreshVitals: true
        })
        let vitals = await getAndRefreshDeviceVitals(deviceId);
        this.setState({ currentDeviceVitals: vitals, isRefreshVitals: true })
    }
    async loadDeviceInfoForEditCreate(row, isView) {
        var singleDeviceInfo = null;
        //call this for known devices
        var deviceVitals = await getVitalsObject(row.id);
        if (!row.isNew) {
            if (row.online || !isView) {
                getDeviceVariableWait(row.id, 'System ID').then(deviceSystemId => {
                    if (deviceSystemId.result) {
                        this.setState({ currentSystemId: deviceSystemId.result })
                        this.populateSystemGroups(deviceSystemId.result, row.product_id);
                    }
                });
                getDeviceVariableWait(row.id, 'Baud Rate').then(baudRate => {
                    this.setState({ currentBaudRate: baudRate.result, })
                });
                getDeviceVariableWait(row.id, 'RTU Number').then(rTUNumber => {
                    this.setState({ currentrTUNumber: rTUNumber.result, })
                });
            } else {
                getLastKnownDeviceInfo(row.id).then(deviceInfo => {
                    this.setState({ currentSystemId: deviceInfo.systemId })
                    this.populateSystemGroups(deviceInfo.systemId, row.product_id);
                })
            }

            singleDeviceInfo = await getSingleDevice(row.id);
        }
        this.setState({
            currentDeviceName: row.name ?? '',
            currentProductId: row.product_id,
            currentGroup: row.groups[0],
            currentDeviceVitals: deviceVitals, 
            currentDevice: Object.assign(this.state.currentDevice, singleDeviceInfo),
        });
    }
    enterEdit(row) {
        toast.info(<>
            <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
            />
            &nbsp; Fetching device info...</>, { autoClose: false, hideProgressBar: true });
        this.loadDeviceInfoForEditCreate(row, false).then(() => {
            setTimeout(() => {
                this.setShowEdit(true);
                toast.dismiss();
            }, 500)
        });
    }
    async enterView(row) {
        toast.info(<>
            <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
            />
            &nbsp; Fetching device info...</>, { autoClose: false, hideProgressBar: true });
        await this.loadDeviceInfoForEditCreate(row, true);
        this.setShow(true);
        toast.dismiss();
    }
    setShow(show) {
        if (!show) {
            this.clearCurrentDeviceValues();
        }
        this.setState({ show: show })
    }
    filterItems(val) {
        let filterName = this.state.devices;
        let filterSystem = this.state.devices;
        let filterIds = [];
        if (val != '') {
            filterName = this.state.devices.filter(x => {
                if (x.name)
                    return x.name.toLowerCase().includes(val.toLowerCase()) || x.displaySystemName.toLowerCase().includes(val.toLowerCase())
            });
            filterSystem = this.state.devices.filter(x => {
                 if (x.displaySystemName)
                    return x.displaySystemName.toLowerCase().includes(val.toLowerCase())
            });
            filterIds = this.state.devices.filter(x => x.id.toLowerCase().includes(val.toLowerCase()))
        }
        let temp = [...filterName, ...filterSystem, ...filterIds];
        let filterTemp = [];
        temp.forEach((item) => {
            if (filterTemp.find(x => x.id == item.id) == undefined) {
                filterTemp.push(item);
            }
        });
        this.setState({ filterDevices: filterTemp });
    }
    findCurrentGroupName(systemGroups, productId) {
        let groupObj = '';
        for (var item of systemGroups) {
            if (item.apiProductId.toLowerCase() == this.state.products.find(x => x.id == productId).slug.toLowerCase()) {
                groupObj = item;
            }
        }
        return groupObj;
    }
    async populateSystemGroups(systemId, productId) {
        if (systemId == null || systemId == undefined || systemId == '') {
            this.setState({
                systemGroups: [], currentSystemId: '',
            });
            return;
        }
        var systemGroups = await getSystemGroups(systemId);
        let tempGroups = [];

        for (var item of systemGroups) {
            if (item.apiProductId.toLowerCase() == this.state.products.find(x => x.id == productId).slug.toLowerCase()) {
                tempGroups.push(item);
            }
        }
        let currentGroup = this.findCurrentGroupName(systemGroups, productId);
        this.setState({
            systemGroups: systemGroups,
            currentSystemId: systemId,
            currentGroup: currentGroup?.productName?.toLowerCase() ?? ""
        });
    }
    getVariableNameFromFunction(varName) {
        varName = varName.replace('Set ', '').replace(' ', '');
        if (varName == 'SystemID')
            varName = 'System ID';
        return varName;
    }

    getVariableValue(x) {
        switch (x) {
            case "Set System ID":
                return this.state.currentSystemId;
            case "Set RTU Number":
                return this.state.currentrTUNumber;
            case "Set Baud Rate":
                return this.state.currentBaudRate;
            case "Set Encryption Key":
                return this.state.currentEncryptionKey;
            default:
                return "";
        }
    }
    getSystemName(systemId) {
        let system = this.state.systems.find(x => x.systemId == systemId);
        if (system)
            return system.name
        return '';
    }
    alphbeticalSort(rowA, rowB) {
        let a = rowA.displaySystemName == 'No System' ? 'none' : rowA.displaySystemName;
        let b = rowB.displaySystemName == 'No System' ? 'none' : rowB.displaySystemName;
        return b.length - a.length;
    }
    primaryAndSecondaryAndTrisSort(rowA, rowB) {
        const a = rowA.name?.toLowerCase() ?? "z";
        const b = rowB.name?.toLowerCase() ?? "z";
        let alphabetical = 0;
        if (a > b) {
            alphabetical = 1;
        }

        if (b > a) {
            alphabetical = -1;
        }

        let a1 = rowA.online;
        let b1 = rowB.online;
        let a2 = rowA.last_heard;
        let b2 = rowB.last_heard;

        return ((b1 - a1) || alphabetical || (new Date(a2) - new Date(b2)))
    }
    onlineSort(rowA, rowB) {
        let a = rowA.online;
        let b = rowB.online;
        return b - a;
    }
    productSort(rowA, rowB) {
        let a = rowA.isNew == undefined ? false : rowA.isNew;
        let b = rowB.isNew == undefined ? false : rowB.isNew;
        return b - a;
    }
    configSort(rowA, rowB) {
        let a = rowA.badConfig == undefined ? false : rowA.badConfig ;
        let b = rowB.badConfig == undefined ? false : rowB.badConfig;
        return b - a;
    }
    updateProgressBar(current) {
        this.setState({ progressBarPct: current + "px" });
    }
    async getDataOps(e) {
        this.setState({
            isGettingDataOps: true
        });
        let res = await getDataOpsForDevice(this.state.currentDevice.id);
        let result = await this.refreshReport()
        let currentDeviceusage = await getDataOpsForDevice(this.state.currentDevice.id);
        this.setState({
            currentDeviceUsage: currentDeviceusage,
            isGettingDataOps: false,
        })
    }
    async refreshReport() {
        let report = await requestNewBillingReport(getTodayInBillingFormat(), getTodayInBillingFormat(), this.state.token);
        if (report.data.id)
            await awaitBillingReportStatuscheck(report.data.id, this.state.token);
    }
    caseInsensitiveSort(rowA, rowB) {
        //assign all nulls a z to place at bottom of list
        const a = rowA.name?.toLowerCase() ?? "z";
        const b = rowB.name?.toLowerCase() ?? "z";

        if (a > b) {
            return 1;
        }

        if (b > a) {
            return -1;
        }

        return 0;
    };
    genericSort(rowA, rowB, type) {
        let a = '';
        let b = '';
        switch (type) {
            case 'system':
                a = rowA.displaySystemName ? rowA.displaySystemName.toLowerCase() : 'none';
                b = rowB.displaySystemName ? rowB.displaySystemName.toLowerCase() : 'none';
                break;
            case 'deviceId':
                a = rowA.id.toLowerCase();
                b = rowB.id.toLowerCase();
                break;
            case 'fullyconfigured':
                a = rowA.badConfig ?? false;
                b = rowB.badConfig ?? false;
                break;
            case 'product':
                let product = this.state.products.find(x => x.id == rowA.product_id);
                let productB = this.state.products.find(x => x.id == rowB.product_id);
                a = product ? product.name.toLowerCase() : 'none'
                b = productB ? productB.name.toLowerCase() : 'none';
                break; 
            case 'lastHeard':
                a = rowA.last_heard ? new Date(rowA.last_heard) : '';
                b = rowB.last_heard ? new Date(rowB.last_heard) : '';
        }
        if (a > b) {
            return 1;
        }

        if (b > a) {
            return -1;
        }

        return 0;
    }
    handleProductChange(productId) {
        let groupObj = '';
        for (var item of this.state.systemGroups) {
            if (item.apiProductId.toLowerCase() == this.state.products.find(x => x.id == productId).slug.toLowerCase()) {
                groupObj = item;
            }
        }
        let currentDevice = this.state.currentDevice
        if (productId == 15516) {
            currentDevice.functions = ['Set System ID', 'Set RTU Number', 'Set Baud Rate'];
        }
        else {
            currentDevice.functions = ['Set System ID']
        }
        this.setState({
            currentDevice: currentDevice,
            currentGroup: groupObj?.productName?.toLowerCase() ?? "",
            currentSystemId: this.state.currentSystemId,
            currentProductId: productId,
            currentDeviceName: this.state.currentDeviceName
        })
    }
    getKnownFunctionNames() {
        return ['Set RTU Number', 'Set System ID', 'Set Baud Rate'];
    }
    tryGetSystemName(row) {
        return row.displaySystemName == 'No System' ? <span className="badge badge-warning">None</span> : row.displaySystemName;
    }
    handleSystemNameChange(value) {
        this.setState({ currentSystemId: value });
        this.populateSystemGroups(value, this.state.currentProductId)
    }
    render() {
        return (<>
            {this.state.devices.length > 0 ?
                <>
                    <input type='text' placeholder="Type to filter..." className="form-control" onChange={(e) => this.filterItems(e.target.value)} />
                    <br />
                </>
                : <></>}
            <DataTable
                data={this.state.filterDevices?.sort(this.primaryAndSecondaryAndTrisSort)}
                columns={[
                    {
                        name: 'Name',
                        selector: row => row.name,
                        sortable: true,
                        width: "150px",
                        wrap: true,
                        sortFunction: this.caseInsensitiveSort
                    },
                    {
                        name: "Online",
                        selector: row => row.online ? <FontAwesomeIcon icon={faCheckCircle} color="green" /> : <FontAwesomeIcon icon={faTimes} color="red" />,
                        sortable: true,
                        wrap: true,
                        sortFunction: this.onlineSort,
                        width: '100px',
                        center: true
                    },
                    {
                        name: "Last Heard",
                        selector: row => formatDate(row.last_heard, 'dateTime'),
                        sortable: true,
                        wrap: true,
                        sortFunction: (rowA, rowB) => this.genericSort(rowA, rowB, 'lastHeard')
                    },
                    {
                        name: "Device ID",
                        selector: row => row.id,
                        sortable: true,
                        wrap: true,
                        width: '250px',
                        sortFunction:(rowA, rowB) =>  this.genericSort(rowA, rowB, 'deviceId')
                    },
                    {
                        name: "Fully Configured",
                        selector: row => row.badConfig ? <FontAwesomeIcon icon={faExclamationCircle} color="red" /> : <FontAwesomeIcon icon={faCheckCircle} color="green" />,
                        sortable: true,
                        wrap: true,
                        width: "140px",
                        sortFunction: (rowA, rowB) => this.genericSort(rowA, rowB, 'fullyconfigured')
                    },
                    {
                        name: "Product",
                        selector: row => !row.isNew ? <span className="badge badge-success" value="yes">{this.state.products.find(x => x.id == row.product_id)?.name}</span> : <span className="badge badge-warning" value="no">None</span>,
                        sortable: true,
//                        sortFunction: this.productSort,
                        wrap: true,
                        sortFunction: (rowA, rowB) => this.genericSort(rowA, rowB, 'product')
                    },
                    {
                        name: "System",
                        selector: (row) => {return this.tryGetSystemName(row) },
                        sortable: true,
                        wrap: true,
                        width: "120px",
                        sortFunction: (rowA, rowB) => this.genericSort(rowA, rowB, 'system')
                    },
                    {
                        name: "Actions",
                        width: "140px",
                        cell: (row) => {
                            return (
                                <>
                                    <button className="btn btn-sm btn-primary" onClick={() => this.enterView(row)}>
                                        <FontAwesomeIcon icon={faEye} />
                                    </button>
                                    &nbsp;&nbsp;{row.online ?
                                        <button className="btn btn-sm btn-warning" onClick={() => this.enterEdit(row)}>
                                            {<FontAwesomeIcon icon={faPen} />}
                                        </button> :
                                        <button className="btn btn-sm btn-secondary disabled" disabled>
                                            {<FontAwesomeIcon icon={faPen} />}
                                        </button>
                                    }
                                </>
                            );
                        }
                    },
                    {
                        selector: row => row.loadingFeedback,
                        wrap: true,
                        width: "70px"
                    },
                ]}
                sortable
                pagination />
            { /* View  */ }
            <Modal show={this.state.show} fullscreen={"Yes"} onHide={() => this.setShow(false)} style={{maxwidth: '100% important!'}}>
                <Modal.Header closeButton>
                    <Modal.Title>{ this.state.currentDevice.name }</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-6">
                            <Table responsive bordered>
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Name</th>
                                    <th>Last IP Address</th>
                                    <th>Last Heard</th>
                                    <th>Last Handshake</th>
                                    <th>Mobile Secret</th>
                                </tr>
                                <tr>
                                    <td>{this.state.currentDevice.id}</td>
                                    <td>{this.state.currentDevice.name}</td>
                                    <td>{this.state.currentDevice.last_ip_address}</td>
                                    <td>{formatDate(this.state.currentDevice.last_heard, 'dateTime')}</td>
                                    <td>{formatDate(this.state.currentDevice.last_handshake_at, 'dateTime')}</td>
                                    <td>{this.state.currentDevice.mobile_secret}</td>
                                </tr>
                                <tr>
                                    <th>Product ID</th>
                                    <th>Online</th>
                                    <th>Cellular</th>
                                    <th>Status</th>
                                    <th>Platform ID</th>
                                    <th>Firmware Version</th>
                                 </tr>
                                <tr>

                                    <td>{this.state.currentDevice.product_id}</td>
                                    <td>{this.state.currentDevice.online ? "Yes" : "No"}</td>
                                    <td>{this.state.currentDevice.cellular ? "Yes" : "No"}</td>
                                    <td>{this.state.currentDevice.status}</td>
                                    <td>{this.state.currentDevice.platform_id}</td>
                                    <td>{this.state.currentDevice.firmware_version}</td>
                                </tr>
                                <tr>
                                    <th>Serial Number</th>
                                    <th>ICCID</th>
                                    <th>IMEI</th>
                                    <th>OS Version</th>
                                    <th>Product Group</th>
                                    <th>Notes</th>
                                </tr>
                                <tr>
                                    <td>{this.state.currentDevice.iccid}</td>
                                    <td>{this.state.currentDevice.serial_number}</td>
                                    <td>{this.state.currentDevice.imei}</td>
                                    <td>{this.state.currentDevice.system_firmware_version}</td>
                                    <td>{this.state.currentGroup}</td>
                                    <td>{this.state.currentDevice.notes == null ? "No Notes" : this.state.currentDevice.notes}</td>
                                </tr>
                            </thead>
                            </Table>
                            <Table responsive striped bordered>
                                <thead>
                                    {
                                        this.state.currentDevice.functions ?
                                            <>
                                                <tr>
                                                    {this.state.currentDevice.functions.map((x, index) => {
                                                        if (x === 'Set System ID') {
                                                            return <Fragment key={index}>
                                                                <th>System Name</th>
                                                                <th>{this.getVariableNameFromFunction(x)}</th>
                                                            </Fragment>
                                                        } else {
                                                            return <th key={index}>{this.getVariableNameFromFunction(x)}</th>
                                                        }
                                                    })}
                                                </tr>
                                                <tr>
                                                    {this.state.currentDevice.functions.map((x, index) => {
                                                        if (x === 'Set System ID') {
                                                            return <Fragment key={index}>
                                                                <td>{this.getSystemName(this.getVariableValue(x))}</td>
                                                                <td>{this.getVariableValue(x)}</td>
                                                            </Fragment>
                                                        } else {
                                                            return <td key={index}>{this.getVariableValue(x)}</td>
                                                        }
                                                    })}
                                                </tr></> : <></>
                                    }
                                </thead>
                            </Table>
                        </div>
                        <div className="col-md-2">
                            <Table responsive bordered>
                                <tbody>
                                    <DeviceVitalsView device={this.state.currentDevice} />
                                    <DeviceUsageView device={this.state.currentDevice} />
                                </tbody>
                            </Table>
                        </div>

                    </div>
                </Modal.Body>
            </Modal>
            { /* Create/Edit  */}
            <Modal show={this.state.showEdit} fullscreen={"Yes"} onHide={() => this.setShowEdit(false)} style={{ maxwidth: '100% important!' }}>
                <Modal.Header closeButton>
                    <Modal.Title>{this.state.currentDevice.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
                        <Row className="mb-3">
                        <Form.Group as={Col} md="8">
                            <Form.Label>Select Product</Form.Label>
                                <Form.Control
                                    as="select"
                                    defaultValue={this.state.currentProductId} 
                                    onChange={(r) => this.handleProductChange(r.target.value)}
                                    required>
                                <option value="">Please Select Product</option>
                                {
                                    this.state.products.map((x, index) => {
                                        return <option value={x.id} key={index}>{x.name}</option>
                                    })
                                }
                                </Form.Control>
                            </Form.Group>
                            </Row>
                            {this.state.currentDevice.isNew ? <></> :
                            <>
                                <Row>
                                    <Form.Group as={Col} md="8" controlId="validationCustom01" key={Math.random}>
                                        <Form.Label>Device Name</Form.Label>
                                        <InputGroup>
                                            <Form.Control
                                                required
                                                type="text"
                                                value={this.state.currentDeviceName}
                                                onChange={(e) => {
                                                    this.setState({ currentDeviceName: e.target.value });
                                                }}
                                                col={6}
                                            />
                                        </InputGroup>
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                    <Row className="mb-3">
                                    <Form.Group as={Col} md="8">
                                        <Form.Label>Select System</Form.Label>
                                        <Form.Control as="select"
                                            value={this.state.currentSystemId}
                                            onChange={(e) => {
                                                this.handleSystemNameChange(e.target.value)
                                            }}
                                            required>
                                            <option value="">Please Select System</option>
                                            {
                                                this.state.systems.map((x, index) => {
                                                    return <option value={x.systemId} key={index}>{x.orderNumber}-{x.name}</option>
                                                })
                                            }
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group as={Col} md="8">
                                        <Form.Label>Select Customer Product Group</Form.Label>
                                        <Form.Control as="select"
                                            value={this.state.currentGroup}
                                            onChange={(r) => this.setState({ currentGroup: r.target.value })} required disabled>
                                            <option value="">Please Select Product Group</option>
                                            {
                                                this.state.systemGroups.map((x, index) => {
                                                    return <option value={x.productName.toLowerCase()} key={index}>{x.productName}</option>
                                                })
                                            }
                                        </Form.Control>
                                    </Form.Group>
                                </Row>
                                <h6>Call Functions</h6>
                                <Row className="mb-3" key={this.state.currentDevice.id ?? 1}>
                                    {
                                        this.state.currentDevice.functions ?
                                            this.state.currentDevice.functions.map((x, index) => {
                                                let isDisabled = false;
                                                let type = "text";
                                                if (x.toLowerCase() == "set system id") { isDisabled = true }
                                                if (x.toLowerCase() == "set rtu number") { type = "number" }
                                                if (x.toLowerCase() == "set baud rate") {
                                                    return <Form.Group as={Col} md="8" key={index}>
                                                        <Form.Label>Select Baud Rate</Form.Label>
                                                        <select
                                                            className="form-control"
                                                            value={this.state.currentBaudRate}
                                                            key={x}
                                                            onChange={(e) => this.setState({ currentBaudRate : e.target.value })} required>
                                                            <option value="">Please Select Baud Rate</option>
                                                            <option value="1200" >1200</option>
                                                            <option value="9600" >9600</option>
                                                        </select>
                                                    </Form.Group>
                                                }
                                                else {
                                                    if (this.getKnownFunctionNames().find(name => name === x) != null) {
                                                        return <Form.Group as={Col} md="8" controlId="validationCustom01" key={index}>
                                                            <Form.Label>{x} {x === 'Set System ID' ? ' System' : ' Other'}</Form.Label>
                                                            <InputGroup>
                                                                <Form.Control
                                                                    required
                                                                    key={x === 'Set System ID' ? this.getVariableValue(x) : x}
                                                                    type={type}
                                                                    defaultValue={this.getVariableValue(x)}
                                                                    onChange={(e) => {
                                                                        if(x === 'Set System ID') 
                                                                            this.setState({ currentSystemId: e.target.value })
                                                                        if (x === 'Set RTU Number')
                                                                            this.setState({ currentrTUNumber: e.target.value })
                                                                    }}
                                                                    col={6}
                                                                    disabled={isDisabled}
                                                                />
                                                            </InputGroup>
                                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                                        </Form.Group>
                                                    }
                                                }
                                            }) : <></>
                                    }
                                </Row>
                            </>}
                        <Button type="submit">Submit</Button>
                    </Form>
                </Modal.Body>
            </Modal>
            {/*Show progress*/}
            <Modal show={this.state.showProgressModal} onHide={() => this.setState({ showProgressModal: false})}>
                <Modal.Header closeButton className="d-block">
                    <Modal.Title>{this.state.currentDevice.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <b>Please wait... Device is being configured</b>
                    <br />
                    <div className="progress mt-1" style={{ marginLeft: "35%", height: "15px", width: "240px" }}>
                        <div className={"progress-bar  progress-bar-danger"} style={{ width: this.state.progressBarPct, height: "15px"}}></div>
                        </div>
                    {this.state.currentDevice.loadingFeedback}<br />
                    {!this.state.currentDevice.isRestartSuccess ?
                        <small>
                            Device communication failed. Please restart the device, refresh the page, and try again.
                        </small> :
                        <small>
                            Device is restarting. This page will refresh automatically once device is ready. This may take up to 4 minutes.
                        </small>
                    }
                </Modal.Body>
            </Modal>

        </>)
    }
}