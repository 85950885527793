import { Col } from 'react-bootstrap';
import { Row } from 'reactstrap';
import React, { Component } from 'react';
import { CustomerTopUsage } from './Dashboard/CustomerTopUsage';
import { FsTotalUsage } from './Dashboard/FsTotalUsage';
import { UploadBilling } from './Dashboard/UploadBilling';
import { getAllBillingPeriods } from '../Services/BillingService';
export class BillingDashboardTab extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedPeriod: (new Date().getMonth() + "-" +new Date().getFullYear()),
            billingPeriodList: []
        };
        this.loadData = this.loadData.bind(this);
    }
    componentDidMount() {
        this.loadData();
    }
    async loadData() {
        let d = await getAllBillingPeriods();
        this.setState({
            billingPeriodList: d,
            selectedPeriod: d[0]
        })
    }

    render() {
        return <>
            <Row className="mt-3">
                <Col><UploadBilling loadData={this.loadData} /></Col>
            </Row>
            <Row className="mt-3">
                <Col md="4" className="offset-4"><select onChange={(e) => {
                    this.setState({
                        selectedPeriod: e.target.value
                    })
                }} className="form-control"
                    value={this.state.selectedPeriod}>
                    <option value="">Select Period</option>

                    {this.state.billingPeriodList.map((val, index) => {
                        let str = index === 0 ? <option value={val} key={index}>{val}</option> :
                                          <option value={val} key={index}>{val}</option>
                            
                        return str;
                    })
                    }
                </select></Col>
            </Row>
            <Row className="mt-3">
                <Col md="6"><FsTotalUsage selectedPeriod={ this.state.selectedPeriod} /></Col>
                <Col md="6"><CustomerTopUsage selectedPeriod={this.state.selectedPeriod}/></Col>
            </Row>
        </>
    }
}