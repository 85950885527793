import React, { Component} from 'react';
import { getDeviceVariableWait } from '../Services/DeviceServices';
export class DeviceAttributesView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentDevice: this.props.device ?? {},
            isLoading: true,
            variables: [],
        }
    }
    componentDidMount() {
        this.loadData();
    }
    async loadData() {
        let temp = [];
        this.state.currentDevice.functions.forEach((funcName, i) => {
            if (this.state.currentDevice.online) {
                let functionName = this.extractVariableNameFromFunction(funcName);
                getDeviceVariableWait(this.state.currentDevice.id, functionName).then(res => {
                    temp.push({
                        name: res.name,
                        value: res.result,
                    })
                    this.setState({
                        variables: temp,
                        isLoading: false,
                    });
                })
            } else {
                this.setState({
                    isLoading: false
                })
            }
        })
    }
    extractVariableNameFromFunction(functionName) {
        if (functionName) {
            return functionName.replace("Set ", "");
        }
        return functionName;
    }
    render() {
        return <>
            {this.state.isLoading ? <>
                <>Loading Attributes... <div className="spinner-border spinner-border-sm"></div></>
            </> :
                <>
                    <h6 className="card-title">Device Attributes</h6>
                    <ul>
                        {this.state.variables.length < 1 ? <li>No attributes available, device is offline</li> : <></>}
                        {this.state.variables.map((variable, i) => variable.name && variable.name != '' ? (<li key={i}>{variable.name} : {variable.value}</li>) : <></> )}
                    </ul>
                    </>
                }
            </>
        }
    }

