import React, { Component } from 'react';
import { DeviceDataTable } from './DeviceDataTable';
export class DiscoverDevices extends Component {
    constructor(props) {
        super(props);
        this.state = {
            devices: this.props.devices,
        }
    }
    render() {
        return (<>
            {
                <>
                    <DeviceDataTable devices={this.state.devices} products={this.props.products} loadData={this.props.loadData}/>
                </>
                }
        </>)
    }
}