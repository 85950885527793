export const isDateLessThanXDaysFromToday = (date, numOfDays) => {
    let today = new Date();
    let compDate = new Date(date);
    if (new Date() > compDate)
        return false
    if (numOfDays && date) {
        if (parseInt(numOfDays) > -1) {
            compDate.setDate(compDate.getDate() - numOfDays);
            var compareDate = new Date(compDate)
            return today >= compareDate
        }
    }
    return false;
}

export const spliceTimeFromCSharpDate = (date) => {
    return date.split('T')[0];
}
export const getTodayInBillingFormat = () => {
    //example: '2022-01-01'
    const today = new Date();
    const date = today.getFullYear() + '-' + (parseInt(today.getMonth()) + 1) + '-' + today.getDate()
    return date;
}
export const formatJSDate = (JsDate) => {
    if (JsDate) {
        JsDate = new Date(JsDate);
        let date = JsDate.getFullYear() + '-' + (parseInt(JsDate.getMonth()) + 1) + '-' + JsDate.getDate()
        return new Date(date);
    }
    return '';
}