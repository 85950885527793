import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { FetchData } from './components/FetchData';
import { RegisteredDevices } from './components/RegisteredDevices';
import { Counter } from './components/Counter';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import { Systems } from './components/Systems/Systems';
import { Devices } from './components/Devices/Devices';
import { DeviceView } from './components/DeviceManager/DeviceView'
import usersMain from './components/Users/usersMain';
import './resources/css/KendoNew.css';
import './custom.css'
import { Orders } from './components/Orders/Orders';
import { Billing } from './components/Billing/Billing';


export default class App extends Component {
    static displayName = App.name;

  render () {
    return (
        <Layout>
            <AuthorizeRoute exact path='/' component={Home} />
            <AuthorizeRoute path='/fetch-data' component={FetchData} />
            <AuthorizeRoute path='/RegisteredDevices' component={RegisteredDevices} />
            <AuthorizeRoute path='/Systems' component={Systems} />
            <AuthorizeRoute path='/Devices' component={Devices} />
            <AuthorizeRoute path='/Users' component={usersMain} />
            <AuthorizeRoute path='/Orders' component={Orders} />
            <AuthorizeRoute path='/DeviceManager' component={DeviceView} />
            <AuthorizeRoute path='/Billing' component={Billing} />
        <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
      </Layout>
    );
  }
}
