import { Tab } from 'bootstrap';
import React, { Component } from 'react';
import { Col, Form, Tabs } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Label } from 'reactstrap';
import Loader from '../Common/Loader';
import { getBillingSettings, postBillingSettings } from '../Services/BillingService';
import { getUsers } from '../Services/userServices';
import { Billing } from './Billing';
export class BillingSettingsTab extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isSms: false,
            isEmail: true,
            is75: true,
            is85: false,
            is95: false,
            isTargettingUserGroup: false,
            isTargettingUsers: false,
            isPushNotification: false,
            users: [],
            usersList: [],
            billingSettingId: 0,
            loader: true,
        };
    }
      componentDidMount() {
         this.loadData();
    }

    async loadData() {
        let settings = await getBillingSettings();
        let users = await getUsers();

        if (settings && users.success) {
            //only one settings row in db
            settings = settings[0]
            users = users.users.filter(x => x.roles[0] != 'CUSTOMER')
            users.forEach((x) => {
                if (settings.usersList.indexOf(x.user.userName) > -1)
                    x.isSelected = true
                else
                    x.isSelected = false
            })
            this.setState({
                users: users,
                billingSettingId: settings.billingSettingId,
                isEmail: settings.isEmail,
                isSms: settings.isSms,
                is75: settings.is75,
                is85: settings.is85,
                is95: settings.is95,
                isPushNotification: settings.isPushNotification,
                isTargettingUserGroup: settings.isTargettingUserGroup,
                isTargettingUsers: settings.isTargettingUsers,
                usersList: settings.usersList.split(','),
                loader: false,
            })
        }
    }
    handleUserCheck(user, checked) {
        user.isSelected = checked
        this.setState({ ...this.state.users, ...user })
        if (checked) {
            if (this.state.usersList.find(x => x == user.user.userName) == undefined)
                this.state.usersList.push(user.user.userName)
        }
        else {
            this.state.usersList = this.state.usersList.filter(x => x != user.user.userName)
        }
    }
    async submitBillingSettings(e) {
        let billingObject = {
             billingSettingId : this.state.billingSettingId,
             isEmail  : this.state.isEmail,
             isSms  : this.state.isSms,
             is75  : this.state.is75,
             is85  : this.state.is85,
             is95  : this.state.is95,
             isPushNotification  : this.state.isPushNotification,
             isTargettingUserGroup  : this.state.isTargettingUserGroup,
             isTargettingUsers  : this.state.isTargettingUsers,
             usersList  : this.state.usersList.toString()
        }
        let res = await postBillingSettings(billingObject);
        if (res.success) {
            toast.success(res.message)
        }
        else {
            toast.error(res.message)
        }
    }
    render() {
        return <>{ this.state.loader ? <Loader /> : 
                <>
                    <h3>BillingSettingsTab</h3>

                    <Form.Group>
                        <br />
                        <Label>Please Notification Target</Label>
                        <Form.Check
                            type="switch"
                            id="userGroups"
                            label="Target User Groups"
                            checked={this.state.isTargettingUserGroup}
                            onChange={(e) => { this.setState({ isTargettingUserGroup: e.target.checked }) }}
                        />
                        <Form.Check
                            type="switch"
                            id="users"
                            label="Target Specific Users"
                            checked={this.state.isTargettingUsers}
                            onChange={(e) => { this.setState({ isTargettingUsers: e.target.checked }) }}
                        />
                        {
                            this.state.isTargettingUsers ?
                                <>
                                    <br />
                                    <div className="offset-md-1">
                                        <Label>Please Select Specific Users</Label>
                                        {
                                            this.state.users.map((x, index) => {
                                                return <Form.Check
                                                    key={index}
                                                    type="switch"
                                                    id={x.user.userName}
                                                    label={x.user.userName}
                                                    checked={x.isSelected}
                                                    onChange={(e) => { this.handleUserCheck(x, e.target.checked) }}

                                                />
                                            })
                                        }
                                    </div>
                                </>
                                : <></>
                        }
                        <br />
                        <Label>Threshold Alert Triggers</Label>
                        <Form.Check
                            type="switch"
                            id="75Id"
                            label="75% of Data Usage"
                            checked={this.state.is75}
                            onChange={(e) => { this.setState({ is75: e.target.checked }) }}
                        />
                        <Form.Check
                            type="switch"
                            id="85Id"
                            label="85% of Data Usage"
                            checked={this.state.is85}
                            onChange={(e) => { this.setState({ is85: e.target.checked }) }}
                        />
                        <Form.Check
                            type="switch"
                            id="95Id"
                            label="95% of Data Usage"
                            checked={this.state.is95}
                            onChange={(e) => { this.setState({ is95: e.target.checked }) }}
                        />
                    </Form.Group>
                    <Form.Group>
                        <br />
                        <Label>Notification Method</Label>
                        <Form.Check
                            type="switch"
                            id="emailId"
                            label="Email"
                            checked={this.state.isEmail}
                            onChange={(e) => { this.setState({ isEmail: e.target.checked }) }}
                        />
                        <Form.Check
                            type="switch"
                            id="smsId"
                            label="SMS"
                            checked={this.state.isSms}
                            onChange={(e) => { this.setState({ isSms: e.target.checked }) }}
                        />
                        <Form.Check
                            disabled
                            type="switch"
                            id="custom-switch"
                            label="Push Notifications (Not available)"
                        />
                    </Form.Group>
                    <button className="btn btn-primary" onClick={(e) => this.submitBillingSettings(e)}>Submit</button>
                </>
        }
        </>
    }
}