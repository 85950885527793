import React, { Fragment, useState, render, useEffect } from 'react';
import { Collapse, Button, CardBody, Card } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFolder, faChevronCircleDown, faChevronCircleRight as faObjectGroup, faFolderOpen, faExclamation, faCheck, faStream, faMobileAlt as faMobile, faCheckCircle, faCircle, faChevronCircleRight, faStopCircle, faTimes
} from '@fortawesome/free-solid-svg-icons';
import { isCurrentUserCustomer } from './roleManager';
import { OverlayTrigger, Pagination, Tooltip } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';

///Attributes
///searchable: bool : show/hide the search items filter on top
///pagination: bool : show/hide pagination instead of scrollbar

///Set isDevice to change folder icon to mobile icon
///Set isOnServer to display green check or exclamation 
///pass in array of items within each item to illustrate
///the child effect and use the tree view

export const CustomTreeView = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [currentItems, setCurrentItems] = useState(props.pagination ? props.data?.slice(0, 5) : props.data);
    const [currentPage, setCurrentPage] = useState(1);
    //defualt behavior for component
    const isPagination = props.pagination ?? false;
    const isScrollable = props.scrollable ?? true;
    const isSearchable = props.searchable ?? true;
    const toggle = () => setIsOpen(!isOpen);
    let content = [];
    let counter = 1;
    function incrementCurrentPage(event, index) {
        index = parseInt(event.target.text);
        index = index - 1;
        setCurrentItems(props.data?.slice(index, index + 5))
        setCurrentPage(index + 1);
    }
    function handleInput(input) {
        let temp = props.data.filter(x => x.text.toLowerCase().includes(input.toLowerCase()))
        if (temp.length > 0 && input && input != '') {
            setCurrentItems(temp);
        }
        else {
            if (props.pagination) {
                setCurrentItems(props.data?.slice(0, 5))
                setCurrentPage(1);
            } else {
                setCurrentItems(props.data)
            }
        }
    }
    if (isPagination) {
        let boxes = [];
        if (props.data.length > 5) {
            for (var index = 1; index <= props.data.length; index += index == 1 ? 4 : 5) {
                boxes.push(<Pagination.Item key={index} active={index === currentPage} onClick={(e) => incrementCurrentPage(e, index)}>{index}</Pagination.Item>)
            }
            content.push(<Pagination size="sm" key={Math.random()}>{boxes}</Pagination>)
        }
    }
    if (props.data.length > 0) {
        for (let item of currentItems) {
            content.push(
                <div key={counter * 12} >
                    <TreeViewParent key={counter + Math.random()} item={item} onItemClick={props.onItemClick} />
                </div>);
            counter++;
        }
    }
    else {
        content.push(<small key={Math.random}>No Records Found</small>);
    }
    return (<>
        {isSearchable ?
            <><input type="text" className="form-control form-control-sm" placeholder="Search Items" onChange={(e) => handleInput(e.target.value)} /><br /></> : <></>}
        <div style={{ overflowY: isScrollable ? 'auto' : 'none', height: isScrollable ? '250px' : '100%' }}>{content}</div></>)
}
export const TreeViewParent = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isOutofSync, setIsOutofSync] = useState("#3264a8");
    const toggle = () => setIsOpen(!isOpen);
    let content = [];
    useEffect(() => {
            for (var item of props.item.items) {
                if (props.item.isSystemDirectory) {
                    if (!item.isOnServer)
                        setIsOutofSync("red")
                    else 
                        setIsOutofSync("#3264a8")
            }
        }
    }, [props])
    content.push(<div key={Math.random()}>
        <span color="primary" className="treeviewParent" onClick={() => { props.onItemClick(props.item.items); toggle(); }}>
            {isOpen ?
                <FontAwesomeIcon icon={faChevronCircleDown} color={isOutofSync} /> :
                <FontAwesomeIcon icon={faObjectGroup} color={isOutofSync} />}
            &nbsp;{props.item.text}
        </span>
        <Collapse isOpen={isOpen} className="treeViewParent" >
            <TreeViewChild items={props.item.items} onItemClick={props.onItemClick} />
        </Collapse>
    </div>);
    return (<>{content}</>)
}
export const TreeViewChild = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    var content = [];
    let counter = 36549523;
    for (let item of props.items) {
        if (item.isDevice) {
            content.push(
                <span className="treeviewItem" onClick={() => props.onItemClick(item)} key={counter + Math.random()}>
                <FontAwesomeIcon icon={faMobile} color="gray" />
                    &nbsp;{item.text}&nbsp;
                    <small>
                        {!isCurrentUserCustomer() ?
                            <FontAwesomeIcon icon={faCheck}  color="#37b873" />:<></>
                            }
                </small>
            </span>)
            } else {
            content.push(
                <div key={counter}>
                    {item.items && item.items.length > 0 ? 
                        <TreeViewGrandParent key={counter + Math.random()} item={item} onItemClick={props.onItemClick} />
                        :

                        <span className="treeviewItem" onClick={() => props.onItemClick(item)} key={counter + Math.random()}>
                            {isOpen ?
                                <FontAwesomeIcon icon={faChevronCircleDown} color="#3264a8" />
                                    :
                                <FontAwesomeIcon icon={faChevronCircleRight} color="#3264a8" />}
                            &nbsp;{item.text}&nbsp;
                            {!isCurrentUserCustomer() ? 
                                <small>                                        
                                    {item.isOnServer ?
                                        <>
                                        <OverlayTrigger
                                            placement="top"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={<Tooltip id={"button-sync"+counter}>Fully in sync</Tooltip>}
                                        >
                                            <FontAwesomeIcon icon={faCheck}  color="#37b873" />
                                            </OverlayTrigger>
                                            </>
                                        :
                                        <>
                                        <OverlayTrigger
                                            placement="top"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={<Tooltip id={"button-sync" + counter}>Not in sync</Tooltip>}
                                        >
                                                <FontAwesomeIcon icon={faExclamation}  color="#e83a5a" />
                                            </OverlayTrigger>
                                            </>
                                            }
  

                                </small>
                                :
                                <></>}
                            </span>
                        }
                    </div>
                )
            }
        counter++;
    }
    return (<>{content}</>)
}
export const TreeViewGrandParent = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    let content = [];
    content.push(<div key={Math.random()}>
        <span color="primary" className="treeviewItem" onClick={() => { props.onItemClick(props.item.items); toggle(); }}>
            {isOpen ? <FontAwesomeIcon icon={faChevronCircleDown} color="#3264a8" /> : <FontAwesomeIcon icon={faChevronCircleRight} color="#3264a8" />}
            &nbsp;{props.item.text}&nbsp;
            {!isCurrentUserCustomer() ? 
                <>
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip id={"button-sync"}>Fully in sync</Tooltip>}
                                        >
                    <FontAwesomeIcon icon={faCheck}  color="#37b873" />
                    </OverlayTrigger>
                </>
                : <></>}
        </span>
        <Collapse isOpen={isOpen} className="treeViewParent" >
            <TreeViewGrandChild items={props.item.items} onItemClick={props.onItemClick} />
        </Collapse>
    </div>);
    return (<>{content}</>)
}
export const TreeViewGrandChild = (props) => {
    var content = [];
    for (let item of props.items) {
        content.push(
            <span className="treeviewGrandItem" onClick={() => props.onItemClick(item)} key={Math.random()}>
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={<Tooltip id="button-tooltip-2">{item.online ? 'Device Online' : 'Device Offline'}</Tooltip>}
                >
                    {
                        item.online ?
                            <FontAwesomeIcon icon={faCheckCircle} color="green" />
                            :
                            <FontAwesomeIcon icon={faTimes} color="red" />
                    }
                </OverlayTrigger>
                    &nbsp;{item.name}&nbsp;
                <small>
                    {!isCurrentUserCustomer() ?
                        <FontAwesomeIcon icon={faCheck} color="#37b873" />
                        : <></>
                    }
                </small>
            </span>)
    }
    return content;
}