import React, { Component } from 'react';
import { Col, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Row } from 'reactstrap';
import { awaitBillingReportStatuscheck, getCurrentBillingPeriodDates, getDataOpsForDevice, requestNewBillingReport, uploadBillingFile } from '../../Services/BillingService';
import { getTodayInBillingFormat } from '../../Services/DateService';
export class UploadBilling extends Component {

    constructor(props) {
        super(props);
        this.state = {
            file: null,
            startDate: null,
            endDate: null,
            loading: false,
            isGettingDataOps: false,
        };
        this.inputRef = React.createRef();
    }
    async postForm() {
        this.setState({loading: true})
        let res = await uploadBillingFile(this.state.file);
        if (res.success)
            toast.success(res.message)
        else
            toast.error(res.message)
        this.setState({ loading: false })
        this.props.loadData();
    }
    async getDataOps(e) {
        this.setState({
            isGettingDataOps: true
        });

        await this.refreshReport()
        toast.success('Billing values updated successfully!')
        this.setState({
            isGettingDataOps: false,
        })
    }
    async refreshReport() {
        let billingPeriodDates = await getCurrentBillingPeriodDates();
        let report = await requestNewBillingReport(billingPeriodDates.startDate, billingPeriodDates.endDate, this.state.token);
        if (report.data.id)
            await awaitBillingReportStatuscheck(report.data.id, this.state.token);
    }

    render() {
        return <>
            <Row >
                <Col md="2">
                    Upload Billing:
                </Col>
                <Col md="6">
                    <button className="btn btn-outline-primary form-control" onClick={() => this.inputRef.current?.click()}>Select</button>
                    <input className="d-none" type="file" ref={this.inputRef} onChange={(e) => this.setState({file: e.target.files[0]})} />
                </Col>
                <Col md="2">
                    <button className="btn btn-primary form-control" onClick={() => this.postForm()}>{
                        this.state.loading ? <>
                            Uploading... <Spinner animation="border" color="white" size="sm" />
                        </> : <>Upload</>
                    }                       
                    </button>
                </Col>
                <Col md="2">
                    <button className="btn btn-primary form-control" onClick={(e) => this.getDataOps(e)}>
                        {this.state.isGettingDataOps ? <div color="white" className="spinner-border spinner-border-sm"></div> :
                            <>Auto Upload</>
                        }
                    </button>
                </Col>
            </Row>
        </>
    }
}