import React, { Component, Fragment } from 'react';
import { useState } from 'react';
import { Breadcrumb, Card, Col, Container, InputGroup, Row, Tabs } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import Loader from '../Common/Loader';
import { faPen, faRecycle, faRedo, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { deleteUser, getAccountInfo, getUserInfo, getUsers, resetPassword } from '../Services/userServices';
import ReactTooltip from 'react-tooltip';
import { UserCreateEdit } from './UserCreateEdit';
import { toast } from 'react-toastify';
import { Tab } from 'bootstrap';
import UserGroupManager from './UserGroupManager';

export class UserManagerTab extends Component {
    constructor(props) {
        super(props);
        this.handleShow = React.createRef();
        this.state = {
            loading: true,
            users: [],
            account: {},
            show: false,
            isEdit: false,
            userToEdit: {},
            userRole: "",
        }
        this.updateUserToEdit = this.updateUserToEdit.bind(this);
        this.getData = this.getData.bind(this);
    }
    componentDidMount() {
        this.getData();
    }
    updateUserToEdit(row) {
        let roles = row.roles.map((e) => { return " " + e + " " });
        this.setState({ userRole: roles })
        row.user.groups = row.groups;
        this.setState({ userToEdit: row.user })
    }
    async getData() {
        let account = await getAccountInfo();
        let result = await getUsers();
        this.setState({ users: result.users, account: account, loading: false });
    }
    getColumns() {
        const columns = [
            {
                name: 'Username',
                selector: row => row.user.userName,
                sortable: true,
                width: "200px",
            },
            {
                name: 'Email',
                selector: row => row.user.email,
                sortable: true,
                width: "200px",
            },
            {
                name: 'Phone Number',
                width: "150px",
                selector: row => (row.user.countryCode == null ? "" : row.user.countryCode) + (row.user.phoneNumber == null ? "No Phone" : "-" + row.user.phoneNumber)
            },
            {
                name: 'Role',
                selector: row => row.roles.map((e) => { return " " + (e == "OPERATOR" ? "VIEWER" : e) + " " }),
                sortable: true,
                width: "120px",
            },
            {
                name: 'Email Confirm',
                selector: (row) => { return (<>{row.user.emailConfirmed ? <span className="badge badge-success">Yes</span> : <span className="badge badge-danger">No</span>}</>) },
                sortable: true,
                width: "150px",
            },
            {
                name: 'Active',
                selector: row => { return (<><span className="badge badge-success">Yes</span></>) },
                sortable: true,
                width: "100px",
            },
            {
                name: "Actions",
                width: "90px",
                cell: (row) => {
                    return (<>
                        {row.isPushed ?
                            <span>Item Already Pushed</span>
                            :
                            <>
                                <button className="btn btn-sm btn-warning" onClick={() => this.enterEdit(row)}>
                                    <FontAwesomeIcon icon={faPen} />
                                </button> <span>&nbsp;</span>
                                <button className="btn btn-sm btn-danger" onClick={() => this.removeUser(row)}>
                                    <FontAwesomeIcon icon={faTrash} />
                                </button>
                            </>
                        }
                    </>
                    );
                }
            }
        ];
        return columns;
    }
    enterEdit(row) {
        this.updateUserToEdit(row);
        this.setState({ isEdit: true })
        this.handleShow.current.handleShow();
        let role = row.roles.map((e) => { return " " + e + " " })[0].trim();
        this.handleShow.current.syncItemsWithParent(row.user, role, true, row.user.groups);
    }
    openNewUser() {
        this.setState({ isEdit: false })
        this.handleShow.current.syncItemsWithParent({}, "", false);
        this.handleShow.current.handleShow();
    }
    removeUser(row) {
        this.removeUserAsync(row);
    }
    async removeUserAsync(user) {
        if (window.confirm("User will be permanently deleted. Are you sure?")) {
            let result = await deleteUser(user);
            if (result.success)
                toast.success("User removed successfully");
            let data = await getUsers();
            this.setState({ users: data.users });
        }
    }
    async resetPassword(row) {
        let res = await resetPassword(row.user.id);
        if (res.success)
            toast.success("Password reset successfull!")
        else
            toast.error("Something went wrong!")
    }

    render() {
        return (<>
            {this.state.loading ?
                <Loader />
                :
                <Fragment>
                    <Container>
                                <Row>
                                    <Col md={12}>
                                        <button className="btn btn-primary col-2" onClick={() => this.openNewUser()}>+ Add User</button> &nbsp;&nbsp;
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col md={12}>
                                        <Card>
                                            <DataTable
                                                columns={this.getColumns()}
                                                data={this.state.users}
                                                pagination
                                            />
                                        </Card>
                                    </Col>
                                </Row>
                    </Container>
                    {this.state.userToEdit &&
                        <UserCreateEdit
                            ref={this.handleShow}
                            isEdit={this.state.isEdit}
                            userToEdit={this.state.userToEdit}
                            userRole={this.state.userRole}
                            getUsersData={this.getData} />

                    }
                </Fragment>
            }</>)
    }
}
export default UserManagerTab