import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { getAllProducts } from "../Services/DeviceServices";
import authService from "./../api-authorization/AuthorizeService";
import { toast } from "react-toastify";
import {
  isCurrentUserAdmin,
  isCurrentUserCustomer,
  isCurrentUserPROJECTADMIN,
  isCurrentUserSuperAdmin,
} from "../../common/roleManager";
import { getLicenseStatus } from "../Services/QlmService";
import { CreateEditOrders } from "./createEditOrders";
import { faBan, faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getDataPlans } from "../Services/DataPlanService";
import { spliceTimeFromCSharpDate } from "../Services/DateService";
import { POST, PUT } from "../Services/ApiServices/ApiInterceptor";
class _SystemsPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showHide: false,
      isEdit: false,
      systemId: props.updateData.systemId,
      systemName: props.updateData.name,
      customerName: props.updateData.customerName,
      orderNumber: props.updateData.orderNumber,
      orderNumbers: props.updateData ? props.updateData.orders : [],
      comments: props.updateData.comments,
      isFormValid: false,
      isSystemNameAvilable: false,
      isOrderNumberValidCheck: false,
      isLicenseActive: true,
      showMessage: "invisible",
      formControl: "form-control",
      nameValid: "",
      orderValid: "",
      licenseValid: "",
      message: [],
      products: [],
      customerProducts: [],
      selectedProducts: [],
      productValid: "",
      isPushed: false,
      isLoadingProducts: true,
      cachedSysName: "",
      diabled: true,
      licenseKey: "",
      computerName: "",
      defaultComputerName: "36LT00911",
      orderToEdit: null,
      dataPlansObj: [],
    };
    this.addOrder = this.addOrder.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    //create ref to open orders modal
    this._ordersViewRef = React.createRef();
  }
  componentDidMount() {
    this.loadProducts();
  }
  checkLicense = async () => {
    //let res = await getLicenseStatus('AEGH1N0Q00GZ64KP8UHN581F5Q', 'UNQR1U0K00G1HJKM8P145P1JMFDAR8HAFD', '36LT00911' )
    let res = await getLicenseStatus(this.state.orderNumber);
    this.setState({
      isLicenseActive: res.valid,
      licenseValid: res.valid ? "is-valid" : "is-invalid",
    });
    if (res.valid) {
      this.setState({
        systemName: res.info.orgName,
        licenseKey: res.info.activationKey,
        isSystemNameAvilable: this.isSystemNameValid(res.info.orgName),
      });
    }
  };
  buildSystemOrders = () => {
    var tempOrders = [];
    for (var order of this.state.orderNumbers) {
      tempOrders.push({
        OrderNumber: order.orderNumber,
        ExpDate: new Date(order.expDate),
        StartDate: new Date(order.startDate),
        Qty: parseInt(order.qty),
        dataPlanId: parseInt(order.dataPlanId),
        orderId: parseInt(order.orderId),
      });
    }
    return tempOrders;
  };
  buildSystemProductsObjects = () => {
    let tempProducts = [];
    for (var product of this.state.products) {
      if (product.isChecked) {
        tempProducts.push({
          ApiProductId: product.slug,
        });
      }
    }
    return tempProducts;
  };
  async loadProducts() {
    let products = await getAllProducts();
    let dataPlans = await getDataPlans();
    if (this.state.isEdit) {
      for (var product of products.products) {
        if (
          this.state.selectedProducts.find(
            (g) => g.apiProductId == product.slug
          )
        ) {
          product.isChecked = true;
        } else {
          product.isChecked = false;
        }
      }
    } else {
      for (var product of products.products) {
        product.isChecked = true;
      }
    }
    this.setState({
      products: products.products,
      isLoadingProducts: false,
      dataPlansObj: dataPlans,
    });
  }
  syncItemsWithProps(system) {
    this.setState({
      isEdit: system.isEdit,
      systemId: system.systemId,
      systemName: system.name,
      customerName: system.customerName,
      cachedSysName: system.name,
      orderNumber: system.orderNumber,
      comments: system.comments,
      selectedProducts: system.systemProducts,
      isPushed: system.isPushed,
      customerProducts: system.systemProducts.map((x) => x.apiProductId),
      orderNumbers: system.orders,
    });
    this.loadProducts();
  }
  handleProductsChange(value, index) {
    this.state.products[index].isChecked =
      !this.state.products[index].isChecked;
    this.setState({});
  }
  async tryEditSystem() {
    const val = {
      SystemId: this.state.systemId,
      Name: this.state.systemName,
      CustomerName: this.state.customerName,
      OrderNumber: this.state.orderNumber,
      Comments: encodeURIComponent(this.state.comments),
      LicenseKey: this.state.licenseKey,
      SystemProducts: this.buildSystemProductsObjects(),
      Orders: this.buildSystemOrders(),
    };
    const data = await PUT(
      "api/Systems/" +
        val.SystemId +
        "?email=" +
        localStorage.getItem("userEmail") +
        "&val=" +
        JSON.stringify(val)
    );
    if (data.success) {
      this.handleModalShowHide();
      this.props.populateSystemsData();
      toast.success(data.message);
    } else {
      toast.error(data.message);
    }
    this.setState({ disabled: false });
  }
  async tryCreateNewSystem() {
    const val = {
      SystemId: "00000000-0000-0000-0000-000000000000",
      Name: this.state.systemName,
      CustomerName: this.state.customerName,
      OrderNumber: this.state.orderNumber,
      Comments: encodeURIComponent(this.state.comments),
      LicenseKey: this.state.licenseKey,
      SystemProducts: this.buildSystemProductsObjects(),
      Orders: this.buildSystemOrders(),
    };
    const data = await POST(
      "api/Systems?email=" +
        localStorage.getItem("userEmail") +
        "&val=" +
        JSON.stringify(val)
    );
    if (data.success) {
      this.handleModalShowHide();
      this.props.populateSystemsData();
      toast.success("New system was created");
    } else {
      toast.error("Something went wrong. " + data.message);
    }
    this.setState({ disabled: false });
  }
  handleSubmit(event) {
    this.setState({ disabled: true });
    event.preventDefault();
    if (this.checkFormValidity()) {
      if (!this.props.updateData.isEdit) {
        if (this.state.isSystemNameAvilable) {
          this.tryCreateNewSystem();
        }
      } else {
        this.tryEditSystem();
      }
    }
  }
  checkFormValidity() {
    let message = [];
    let specialChar = false;
    ["!", "@", "#", "$", "%", "^", "&", "*", "(", ")"].forEach((x) =>
      this.state.systemName.includes(x)
        ? (specialChar = true)
        : (specialChar = specialChar)
    );
    if (specialChar) {
      message.push("System name can NOT contain special characters.");
    }
    if (!this.state.isSystemNameAvilable && !this.props.updateData.isEdit) {
      this.showErrorMessage(
        "Please find a different system name. This name is already in use."
      );
    }
    if (this.state.systemName.length < 3) {
      message.push("System name must contain more than 3 letters.");
    }
    if (this.state.systemName.length > 25) {
      message.push("System name must be limited to 25 characters.");
    }
    if (this.state.orderNumbers.length < 1) {
      message.push("System must have at least one order.");
    }
    if (this.state.products.filter((x) => x.isChecked).length < 1) {
      message.push("Customer must have at least one product.");
      this.checkCustomerProductValidity();
    }
    if (message.length > 0) {
      let msg = "";
      for (var line of message) {
        msg += line + "\n";
      }
      this.showErrorMessage(msg);
      this.setState({ disabled: false });
      return false;
    } else {
      return true;
    }
  }
  handleModalShowHide() {
    this.setState({
      isEdit: false,
      systemId: "",
      systemName: "",
      orderNumber: "",
      comments: "",
      selectedProducts: [],
      licenseKey: "",
      isLicenseActive: true,
      licenseValid: "",
      orderNumbers: [],
      customerName: "",
    });
    this._ordersViewRef.current.clearOrder();
    this.clearValidation();
    this.hideErrorMessage();
    this.state.showHide = !this.state.showHide;
    this.loadProducts();
  }
  clearValidation() {
    this.setState({
      nameValid: "",
      orderValid: "",
    });
    this.setState({ disabled: false });
  }
  checkSystemNameValidity(systemName) {
    if (this.state.isEdit) {
      if (systemName == this.state.cachedSysName) {
        return;
      }
    }
    this.hideErrorMessage();
    this.isSystemNameValid(systemName);
  }
  checkOrderNumberValidity(orderNumber) {
    this.checkLicense();
    if (this.state.isEdit) {
      if (orderNumber == this.state.orderNumber) {
        return;
      }
    }
    this.hideErrorMessage();
    this.isOrderNumberValid(orderNumber);
  }
  addOrder(order) {
    var copyState = [];

    //add new object: This will accomplish edit/add
    copyState.push(order);

    this.setState({
      orderNumbers: copyState,
    });

    return true;
  }
  async isOrderNumberValid(orderNumber) {
    if (orderNumber.length < 3) return;
    const token = await authService.getAccessToken();
    const response = await fetch(
      "api/Systems/CheckOrderNumber?orderNumber=" + orderNumber,
      {
        headers: !token ? {} : { Authorization: `Bearer ${token}` },
      }
    );
    const data = await response.json();
    if (data) {
      this.setState({ isOrderNumberValidCheck: true, loading: false });
    } else {
      this.setState({ isOrderNumberValidCheck: false, loading: false });
    }
    this.refreshOrderNumberValidityField();
  }
  async isSystemNameValid(systemName) {
    if (systemName.length < 3) return;
    const token = await authService.getAccessToken();
    const response = await fetch("api/Systems/CheckName?name=" + systemName, {
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    if (data) {
      this.setState({ isSystemNameAvilable: true, loading: false });
    } else {
      this.setState({ isSystemNameAvilable: false, loading: false });
    }
    this.refreshSystemNameValidityField();
  }
  checkCustomerProductValidity() {
    if (this.state.customerProducts > 0) {
      this.setState({ productValid: "is-valid" });
    } else {
      this.setState({ productValid: "is-invalid" });
      this.showErrorMessage("Please choose at least one product");
    }
  }
  refreshSystemNameValidityField() {
    if (this.state.isSystemNameAvilable) {
      this.setState({ nameValid: "is-valid" });
    } else {
      this.setState({ nameValid: "is-invalid" });
      this.showErrorMessage("System name already exists");
    }
  }
  refreshOrderNumberValidityField() {
    if (this.state.isOrderNumberValidCheck) {
      this.setState({ orderValid: "is-valid" });
    } else {
      this.setState({ orderValid: "is-invalid" });
      this.showErrorMessage("Order number already exists");
    }
  }
  showErrorMessage(msg) {
    this.setState({ message: msg });
    this.setState({ showMessage: "visible" });
  }
  hideErrorMessage() {
    this.setState({ message: "" });
    this.setState({ showMessage: "invisible" });
  }
  eval(value) {
    return value;
  }
  setOrderToEdit(e, order) {
    e.preventDefault();
    this._ordersViewRef.current.showHideOrders(order);
  }
  removeOrderNumberItem(order) {
    var temp = this.state.orderNumbers;
    temp = temp.filter((x) => x.orderNumber != order.orderNumber);
    this.setState({
      orderNumbers: temp,
    });
  }
  render() {
    return (
      <div>
        {isCurrentUserCustomer() ? (
          <></>
        ) : (
          <>
            <button
              className="btn btn-primary btn-lg"
              onClick={() => {
                this.handleModalShowHide();
                this.setState({ isEdit: false });
              }}
            >
              Add System
            </button>
          </>
        )}
        <Modal
          show={this.state.showHide}
          id="popupModal"
          backdrop="static"
          onHide={() => this.handleModalShowHide()}
        >
          {this.state.isLoadingProducts ? (
            <>
              <h6>Loading Products...</h6>
            </>
          ) : (
            <form onSubmit={this.handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>System</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="form-group">
                  <label>System Name</label>
                  <input
                    type="text"
                    className={
                      this.state.formControl + " " + this.state.nameValid
                    }
                    value={this.state.systemName}
                    maxLength={25}
                    onChange={(event) =>
                      this.setState({ systemName: event.target.value })
                    }
                    onBlur={(event) =>
                      this.checkSystemNameValidity(event.target.value)
                    }
                    placeholder="*System Name (must not be duplicated)"
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Customer Name</label>
                  <input
                    type="text"
                    className={this.state.formControl}
                    value={this.state.customerName}
                    onChange={(event) =>
                      this.setState({ customerName: event.target.value })
                    }
                    placeholder="Customer Name"
                  />
                </div>
                {this.state.orderNumbers.length > 0 ? (
                  <div className="alert alert-info">
                    <h4>Data Plans:</h4>
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Plan Name</th>
                          <th>Order Number</th>
                          <th>Qty</th>
                          <th>Start Date</th>
                          <th>End Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.orderNumbers.map((x, key) => (
                          <tr key={key}>
                            <td>
                              {x.dataPlan
                                ? x.dataPlan.name.name
                                : this.state.dataPlansObj.find(
                                    (y) => y.dataPlanId == x.dataPlanId
                                  )?.name}
                            </td>
                            <td>{x.orderNumber}</td>
                            <td>{x.qty}</td>
                            <td>{spliceTimeFromCSharpDate(x.startDate)}</td>
                            <td>{spliceTimeFromCSharpDate(x.expDate)}</td>
                            {isCurrentUserAdmin() ||
                            isCurrentUserSuperAdmin() ||
                            isCurrentUserPROJECTADMIN() ? (
                              <>
                                <td>
                                  <button
                                    className="btn btn-sm btn-warning"
                                    onClick={(e) => this.setOrderToEdit(e, x)}
                                  >
                                    <FontAwesomeIcon icon={faPen} />
                                  </button>
                                </td>
                                {!this.state.isEdit ? (
                                  <td>
                                    <button
                                      className="btn btn-sm btn-danger"
                                      onClick={() =>
                                        this.removeOrderNumberItem(x)
                                      }
                                    >
                                      <FontAwesomeIcon icon={faTrash} />
                                    </button>
                                  </td>
                                ) : (
                                  <td>
                                    <button
                                      className="btn btn-sm btn-danger"
                                      onClick={() =>
                                        this.removeOrderNumberItem(x)
                                      }
                                    >
                                      <FontAwesomeIcon icon={faBan} />
                                    </button>
                                  </td>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <></>
                )}
                <fieldset style={{ border: "1px black" }}>
                  <legend>Products</legend>
                  <div className="row">
                    {this.state.products.map((e, index) => (
                      <div className="col-md-4" key={index}>
                        <div className="form-group">
                          <div className="form-check">
                            <Form.Check
                              type="checkbox"
                              value={e.name}
                              checked={e.isChecked}
                              label={e.name}
                              onChange={() =>
                                this.handleProductsChange(e.slug, index)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                    {/*{this.state.productsView}*/}
                  </div>
                </fieldset>
                <div className="form-group">
                  <label>Notes</label>
                  <textarea
                    type="text"
                    className="form-control"
                    value={this.state.comments}
                    onChange={(event) =>
                      this.setState({ comments: event.target.value })
                    }
                    rows="6"
                    cols="2"
                    placeholder="Enter Notes"
                  />
                </div>
                <div
                  className={"alert alert-danger " + this.state.showMessage}
                  role="alert"
                >
                  <ul>{this.state.message}</ul>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => this.handleModalShowHide()}
                >
                  Close
                </Button>
                <Button
                  variant="secondary"
                  onClick={() =>
                    this._ordersViewRef.current.showHideOrders(null)
                  }
                >
                  Add Order
                </Button>
                {this.state.orderNumbers.length > 0 ? (
                  <input
                    type="submit"
                    className="btn btn-primary"
                    disabled={
                      this.state.disabled || !this.state.isLicenseActive
                    }
                  />
                ) : (
                  <></>
                )}
              </Modal.Footer>
            </form>
          )}
        </Modal>
        <CreateEditOrders
          addOrder={this.addOrder}
          ref={this._ordersViewRef}
          orders={this.props.orderToEdit}
        />
      </div>
    );
  }
}

export default _SystemsPopup;
