import { faCheck, faCheckCircle, faExclamation, faExclamationCircle, faInfo, faWifi } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { Card } from 'react-bootstrap';
import { Col, Row } from 'reactstrap';


export class UsageCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            numberOfDevices: props.item.plannedNumberDevices ?? 0,
            totalDataOperation: props.item.plannedDataOpsUsage ?? 0,
            totalCellularData: props.item.totalCellularData ?? 0,
            numberOfDevicesUse: props.item.currentNumberDevices ?? 0,
            totalDataOperationUse: props.item.currentDataOpsUsage ?? 0,
            totalCellularDataUse: props.item.totalCellularDataUse ?? 0,
            name: props.item.name ?? 'No Customer Found'
        };
    }
    componentDidUpdate(props) {
        this.state = {
            numberOfDevices: props.item.plannedNumberDevices ?? 0,
            totalDataOperation: props.item.plannedDataOpsUsage ?? 0,
            totalCellularData: props.item.totalCellularData ?? 0,
            numberOfDevicesUse: props.item.currentNumberDevices ?? 0,
            totalDataOperationUse: props.item.currentDataOpsUsage ?? 0,
            totalCellularDataUse: props.item.totalCellularDataUse ?? 0,
            name: props.item.name ?? 'No Customer Found'
        };
    }
    render() {
        return <>
            <Card md={8}>
                <Card.Body>
                    <Card.Title><FontAwesomeIcon icon={faWifi} color="gray" /> {this.state.name} Usage</Card.Title>
                        <Row className="mt-3">
                            <Col md="3">
                                <b>In Plan: </b>
                            </Col>
                            <Col>
                                <b className="text-gray"><FontAwesomeIcon icon={faInfo} color="gray" /> Devices</b><br />
                                <b>{this.state.numberOfDevices}</b>
                            </Col>
                            <Col>
                            <b className="text-gray"><FontAwesomeIcon icon={faInfo} color="gray" /> Data Operations</b><br />
                                <b>{this.state.totalDataOperation}</b>
                            </Col>
                            <Col>
                            <b className="text-gray"><FontAwesomeIcon icon={faInfo} color="gray" /> Cellular Data</b><br />
                                <b>{this.state.totalCellularData}</b>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col md="3">
                            <b>In Use: {
                                this.state.totalDataOperationUse > this.state.totalDataOperation || this.state.numberOfDevicesUse > this.state.numberOfDevices ?
                                    <FontAwesomeIcon icon={faExclamation} color="red" /> : <FontAwesomeIcon icon={faCheck} color="green" />}</b>
                            </Col>
                            <Col>
                            <b className="text-gray">
                                {this.state.numberOfDevicesUse > this.state.numberOfDevices  ?
                                    <FontAwesomeIcon icon={faExclamationCircle} color="red" /> :
                                    <FontAwesomeIcon icon={faCheckCircle} color="green"  />
                                }Devices</b><br />
                                <b>{this.state.numberOfDevicesUse}</b>
                            </Col>
                            <Col>
                            <b className="text-gray">
                                {this.state.totalDataOperationUse > this.state.totalDataOperation ?
                                    <FontAwesomeIcon icon={faExclamationCircle} color="red"  /> :
                                    <FontAwesomeIcon icon={faCheckCircle} color="green"  />
                                }
                                 Data Operations</b><br />
                                <b>{this.state.totalDataOperationUse}</b>
                            </Col>
                            <Col>
                            <b className="text-gray">
                                {this.state.totalCellularDataUse > this.state.totalCellularData  ?
                                    <FontAwesomeIcon icon={faExclamationCircle} color="red" /> :
                                    <FontAwesomeIcon icon={faCheckCircle} color="green" />
                                }
                                Cellular Data</b><br />
                                <b>{this.state.totalCellularDataUse}</b>
                            </Col>
                        </Row>
                </Card.Body>
            </Card>
        </>
    }
}