import { faCheck, faCheckCircle, faExclamation, faExclamationCircle, faInfoCircle, faWifi } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { Fragment } from 'react';
import { Card } from 'react-bootstrap';
import { Col, Row } from 'reactstrap';
import { getCurrentPeriodDataUsage } from '../../Services/BillingService';

export class FsTotalUsage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            numberOfDevices: 0,
            totalDataOperation: 0,
            totalCellularData: 0,
            numberOfDevicesUse: 0,
            totalDataOperationUse: 0,
            totalCellularDataUse: 0,
            products: ["Siren RTU", "FSModem-Cellbase"],
        };
    }
    componentDidUpdate(prevProps) {
        if (prevProps.selectedPeriod !== this.props.selectedPeriod) {
            this.getData();
        }
    }
    componentDidMount() {
        this.getData();
    }
    getData() {
            getCurrentPeriodDataUsage(this.props.selectedPeriod ?? 0).then((usage) => {
                this.setState({
                    numberOfDevicesUse: usage.actual.devices,
                    totalDataOperationUse: usage.actual.monthlyDataOps,
                    totalCellularDataUse: usage.actual.monthlyData,
                    numberOfDevices: usage.block.devices,
                    totalDataOperation: usage.block.monthlyDataOps,
                    totalCellularData: usage.block.monthlyData,
                })
            });
    }
    render() {
        return <>
            <Card md={8}>
                <Card.Body>
                    <Card.Title><FontAwesomeIcon icon={faWifi} color="gray" /> Cellular FS Usage</Card.Title>
                        <Row className="mt-3">
                            <Col md="3">
                                <b>In Plan: </b>
                            </Col>
                            <Col>
                            <b className="text-gray">
                                <FontAwesomeIcon icon={faInfoCircle} color="gray" /> Devices
                            </b>
                                <br />
                                <b>{this.state.numberOfDevices}</b>
                            </Col>
                            <Col>
                            <b className="text-gray">
                                <FontAwesomeIcon icon={faInfoCircle} color="gray"  /> Data Operations
                            </b><br />
                                <b>{this.state.totalDataOperation}</b>
                            </Col>
                            <Col>
                            <b className="text-gray"><FontAwesomeIcon icon={faInfoCircle} color="gray"  /> Cellular Data</b><br />
                                <b>{this.state.totalCellularData}</b>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col md="3">
                                <b>In Use: {
                                this.state.totalDataOperationUse > this.state.totalDataOperation || this.state.numberOfDevicesUse > this.state.numberOfDevices ?
                                    <FontAwesomeIcon icon={faExclamation} color="red" /> : <FontAwesomeIcon icon={faCheck} color="green" />}</b>
                            </Col>
                        <Col>
                            <b className="text-gray">
                                {this.state.numberOfDevicesUse > this.state.numberOfDevices  ?
                                    <FontAwesomeIcon icon={faExclamationCircle} color="red"  /> :
                                    <FontAwesomeIcon icon={faCheckCircle} color="green"  />
                                }&nbsp;Devices</b><br />
                            <b>{this.state.numberOfDevicesUse}</b>
                        </Col>
                        <Col>
                            <b className="text-gray">
                                {this.state.totalDataOperationUse > this.state.totalDataOperation  ?
                                    <FontAwesomeIcon icon={faExclamationCircle} color="red"  /> :
                                    <FontAwesomeIcon icon={faCheckCircle} color="green"  />
                                }
                                &nbsp; Data Operations</b><br />
                            <b>{this.state.totalDataOperationUse}</b>
                        </Col>
                        <Col>
                            <b className="text-gray">
                                {this.state.totalCellularDataUse > this.state.totalCellularData  ?
                                    <FontAwesomeIcon icon={faExclamationCircle} color="red"  /> :
                                    <FontAwesomeIcon icon={faCheckCircle} color="green"  />
                                }
                                &nbsp; Cellular Data</b><br />
                            <b>{this.state.totalCellularDataUse}</b>
                        </Col>
                        </Row>
                        <Row className="mt-3">
                        <h6 className="ml-3">Products: </h6><br /><br />
                        <div className="ml-3">
                            {this.state.products.map((x, index) => { return <Fragment key={index}>{x} &nbsp;&nbsp;</Fragment> })}
                        </div>
                    </Row>
                </Card.Body>
            </Card>
        </>
    }
}