import { Tab } from 'bootstrap';
import React, { Component, Fragment } from 'react';
import { Tabs } from 'react-bootstrap';
import { DataPlansTab } from './DataPlansTab';
import { FsDataPlansTab } from './FsDataPlansTab';
import { OrdersTab } from './OrdersTab';
export class Orders extends Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    }
    render() {
        return <>
            <Tabs defaultActiveKey="DataPlans" id="uncontrolled-tab-example" className="mb-3">
                <Tab eventKey="DataPlans" unmountOnExit={true} title="Data Plans">
                    <DataPlansTab />
                </Tab>
                <Tab eventKey="FsPlans" unmountOnExit={true} title="FS Data Plans">
                    <FsDataPlansTab />
                </Tab>
            </Tabs>
        </>
    }
}