import React, { Component } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { PublicAppName } from './api-authorization/ApiAuthorizationConstants';
import authService from './api-authorization/AuthorizeService';
import Loader from './Common/Loader';
import { GET } from './Services/ApiServices/ApiInterceptor';
import { getAllDevicesWithAllData } from './Services/DeviceServices';
import { getLocalSystems } from './Services/SystemService';
import { getUsers } from './Services/userServices';

export class Home extends Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: true }
    }
  static displayName = Home.name;
    componentDidMount() {
        this.loadData()
    }
    async loadData() {
        await GET('api/Systems/ping?');
        this.setState({ isLoading: false });
    }
  render () {
      return (
          <>{this.state.isLoading ? <Loader /> :  
              <div>
                  <Breadcrumb>
                      <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                  </Breadcrumb>
                  <h1>App Description</h1>
                  <p>
                      The {PublicAppName} application is used to create Federal Signal Customer Systems in the {PublicAppName} cloud.  Unique system names must be associated with a valid customer Order Number.  The Configurator application will create a unique System ID and Device Groups that will be associated with the Customer Name.  The user that created the system and the date the system was created is logged.  The user and the date that the System was last changed is logged.</p><p>
                      The Configurator Application will read the {PublicAppName} Cloud configuration and allow users to POST new System IDs and Groups to the {PublicAppName} Cloud.  Once the System has been pushed to the {PublicAppName} Cloud, the Configurator Application will lock the record for editing and deleting.
                      The Configurator Application will show any missing Groups that have been created in the Configurator but don't exist in the {PublicAppName} cloud are indicated by a red indicator.  Groups that are in sync are indicated with a green indicator.  Any Groups that exist in the {PublicAppName} Cloud that don't exist in the Configurator Application will be shown as Orphan Groups.</p><p>

                  </p>
              </div>
              }
              </>
    );
  }
}
