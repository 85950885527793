import { faCheckCircle, faExclamationCircle, faSignal, faSync, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component, Fragment } from 'react';
import { getVitalsObject, refreshDeviceVitals } from '../components/Services/DeviceServices';
import { formatDate } from '../components/Services/SystemService';

export class DeviceVitalsView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentDevice: this.props.device ?? {},
            vitals: {},
            isGettingVitals: false,
            isLoading: true
        }
    }
    componentDidMount() {
        this.loadData();
    }
    async loadData() {
        let vitals = await getVitalsObject(this.state.currentDevice.id)
        this.setState({
            vitals: vitals,
            isLoading: false
        })
    }
    timeout(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    async refreshVitals() {
        this.setState({
            isGettingVitals: true
        })
        let res = await refreshDeviceVitals(this.state.currentDevice.id);
        await this.timeout(4000);
        let vitals = await getVitalsObject(this.state.currentDevice.id)
        this.setState({
            vitals: vitals,
            isGettingVitals: false
        })
    }
    render() {
        return <Fragment>
            {this.state.isLoading ?
                <tr><td>
                    <>Loading vitals... <div className="spinner-border spinner-border-sm"></div></></td></tr>
                :
                <Fragment>
                    <tr><th>
                        <button className="btn btn-xs btn-primary"
                            onClick={(e) => this.refreshVitals()}>
                            {this.state.isGettingVitals ? <div className="spinner-border spinner-border-sm"></div> : <FontAwesomeIcon icon={faSyncAlt} />}
                        </button>&nbsp;&nbsp;Refresh Vitals</th></tr>
                    <tr><th>Signal Quality</th></tr>
                    <tr><td>{this.state.vitals?.quality}</td></tr>
                    <tr><td>
                        {parseInt(this.state.vitals?.qualityV) < -12 ? <><FontAwesomeIcon icon={faSignal} color="red" />&nbsp;&nbsp;</> : <></>}
                        {parseInt(this.state.vitals?.qualityV) > -13 && parseInt(this.state.currentDeviceVitals?.qualityV) < -8 ? <><FontAwesomeIcon icon={faSignal} color="orange" />&nbsp;&nbsp;</> : <></>}
                        {parseInt(this.state.vitals?.qualityV) > -9 ? <><FontAwesomeIcon icon={faSignal} color="green" />&nbsp;&nbsp;</> : <></>}
                        {this.state.vitals?.qualityV}
                        &nbsp;&nbsp;
                        {parseInt(this.state.vitals?.qualityV) < -12 || this.state.vitals?.qualityV == 'Not available' ?
                            <><FontAwesomeIcon icon={faExclamationCircle} color="red" /></> : <FontAwesomeIcon icon={faCheckCircle} color="green" />}
                    </td></tr>

                    <tr><th>Signal Strength</th></tr>
                    <tr><td>{this.state.vitals?.strength}</td></tr>
                    <tr><td>
                        {parseInt(this.state.vitals?.strengthV) < -105 ? <><FontAwesomeIcon icon={faSignal} color="red" />&nbsp;&nbsp;</> : <></>}
                        {parseInt(this.state.vitals?.strengthV) > -106 && parseInt(this.state.currentDeviceVitals?.strengthV) < -89 ? <><FontAwesomeIcon icon={faSignal} color="orange" />&nbsp;&nbsp;</> : <></>}
                        {parseInt(this.state.vitals?.strengthV) > -90 ? <><FontAwesomeIcon icon={faSignal} color="green" />&nbsp;&nbsp;</> : <></>}
                        {this.state.vitals?.strengthV}
                        &nbsp;&nbsp;
                        {parseInt(this.state.vitals?.strengthV) < -105 || this.state.vitals?.strengthV == 'Not available' ?
                            <><FontAwesomeIcon icon={faExclamationCircle} color="red" /></> : <><FontAwesomeIcon icon={faCheckCircle} color="green" /></>}
                    </td></tr>

                    <tr><th>Round Trip Time</th></tr>
                    <tr><td>{this.state.vitals?.roundTrip} ms</td></tr>
                    <tr><th>Last Updated</th></tr>
                    <tr><td>{formatDate(this.state.vitals?.lastUpdate, 'dateTime')}</td></tr>
                </Fragment>}
        </Fragment>
    }
}
