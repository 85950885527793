import { DELETE, GET, POST, PUT } from './ApiServices/ApiInterceptor';

export const deleteUserSystemGroup = async (groupid) => {
        //TODO: USE EMAIL IN METHOD
        const email = localStorage.getItem('userEmail');
        let fetchedData = await DELETE('api/UserGroup/' + groupid);
        return fetchedData;
}
export const createUpdateUserSystemGroup = async (group, method) => {
    let fetchedData;
    let value = JSON.stringify(group);
    if (method.toLocaleLowerCase() === 'post')
        fetchedData = await POST('api/UserGroup?value=' + value + '&email=' + localStorage.getItem('userEmail'));
    if (method.toLocaleLowerCase() === 'put')
        fetchedData = await PUT('api/UserGroup?value=' + value + '&email=' + localStorage.getItem('userEmail'));
    return fetchedData;
}
export const getUserGroups = async () => {
    let fetchedData = await GET('api/UserGroup/' + localStorage.getItem("userEmail"));
    return fetchedData;
}