import { GET, POST, PUT } from './ApiServices/ApiInterceptor';

export const resetPassword = async (userId) => {
    let fetchedData = await GET('api/Users/resetPassword?email=' + localStorage.getItem("userEmail") + '&id=' + userId);
    return fetchedData;
}
export const getUsers = async () => {
    let fetchedData = await GET('api/Users/' + localStorage.getItem("userEmail"));
    return fetchedData;
}
export const getAccountInfo = async () => {
    let fetchedData = await GET('api/Users/GetAccountInfo?email=' + localStorage.getItem("userEmail"));
    return fetchedData;
}
export const getUserInfo = async () => {
    let fetchedData = await GET('api/Users/GetUserInfo?email=' + localStorage.getItem("userEmail"));
    return fetchedData;
}
export const deleteUser = async (user) => {
    let fetchedData = await GET('api/Users/deleteUser?id=' + user.user.id + '&email=' + localStorage.getItem('email'));
    return fetchedData;
}
//this gets ALL roles in the org
export const getRoles = async () => {
    //TODO: MUST ADD TOKEN TO REQUEST
    let fetchedData = await GET('api/Users/GetRoles?email=' + localStorage.getItem('userEmail'));
    return fetchedData;
}
//this gets ALL groups in the org
export const getUserSystemGroups = async () => {
    //TODO: MUST ADD TOKEN TO REQUEST
    const email = localStorage.getItem('userEmail');
    let fetchedData = await GET('api/Users/GetGroups');
    return fetchedData;
}

export const createUpdateUser = async (user, method) => {
    let value = JSON.stringify(user);
    let fetchedData;
    if (method.toLocaleLowerCase() === 'post')
        fetchedData = await POST('api/Users?value=' + value + '&email=' + localStorage.getItem('userEmail'));
    if (method.toLocaleLowerCase() === 'put')
        fetchedData = await PUT('api/Users?value=' + value + '&email=' + localStorage.getItem('userEmail'));
    return fetchedData;
}
